import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import WebSocket from './WebSocket';
import { Dialog, Divider ,Col, Row , Overlay, Radio , RadioGroup , Cell , Field, CellGroup , Loading, Icon , Toast ,NoticeBar , Button , Checkbox} from 'vant';
// 使用WebSocket插件并传入WebSocket服务器的URL

const useVantComponents = (app) => {
    app.use(Dialog)
      .use(Divider)
      .use(Col)
      .use(Row)
      .use(Overlay)
      .use(Radio)
      .use(RadioGroup)
      .use(Cell)
      .use(Field)
      .use(CellGroup)
      .use(Loading)
      .use(Icon)
      .use(Toast)
      .use(NoticeBar)
      .use(Button)
      .use(Checkbox);
  };
const app = createApp(App);
app.use(WebSocket, { url: 'wss://o8o.live/live'});
app.use(router)
useVantComponents(app);
app.mount('#app')