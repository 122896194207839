<template>

    <div class="inner-wrap ODE2MDA MTQ0MDA">
        <link rel="stylesheet" href="/assets/post/pt/ctt/address.css">
  <header class="page-header NjE2MDA NDg5MDA MTQ2MDA" role="banner">
    <div class="row ODg4MDA NjMzMDA NTgyMDA">
      <div class="navbar MTMzMDA">
        <div class="brand-bar MTk4MDA NTg4MDA NTg0MDA MTUwMDA">
          <h1 class="brand NDE5MDA OTI1MDA"><a href="javascript:void(0);"
              rel="nofollow noreferrer noopener"><img src="./static/images/ctt-45.svg"
                alt="CTT"></a></h1><a id="mobile-nav-toggle" href="javascript:void(0);"
            class="nav-toggle NzY4MDA MzgxMDA NzI2MDA" data-main-nav-toggle="" rel="nofollow noreferrer noopener"><i
              class="ctt-icon-menu NzA0MDA Mzg5MDA"></i><span
              class="show-for-sr ___ODQwMA== MzUyMDA NTI3MDA OTMyMDA"><e-strong data-t="Menu"
                class="_MjIwMA== MTYyMDA OTQ0MDA ODQxMDA MTU5MDA"></e-strong></span></a><a href="javascript:void(0);"
            class="mobile-back-button sf-hidden NDgzMDA" data-primary-nav-back=""
            rel="nofollow noreferrer noopener"></a>
        </div>
        <div class="nav-mobile OTI5MDA Mjg3MDA" id="main-nav-mobile" data-main-nav-content="">
          <nav class="primary-nav nav-drop-wrap NTc0MDA OTQzMDA NzQ4MDA" role="navigation">
            <ul>
              <li data-drilldown-nav="" id="tools" class="has-drop drop-grid-1 ODg1MDA NDk5MDA"><a id="tools-link"
                  href="javascript:void(0);"
                  class="sf-hidden Mzc0MDA ODI2MDA" rel="nofollow noreferrer noopener"><svg aria-hidden="true"
                    focusable="false" data-prefix="fal" data-icon="tools" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512" class="ctt-icon-tools MzM0MDA MTMxMDA">
                    <path fill="currentColor"
                      d="M502.6 389.5L378.2 265c-15.6-15.6-36.1-23.4-56.6-23.4-15.4 0-30.8 4.4-44.1 13.3L192 169.4V96L64 0 0 64l96 128h73.4l85.5 85.5c-20.6 31.1-17.2 73.3 10.2 100.7l124.5 124.5c6.2 6.2 14.4 9.4 22.6 9.4 8.2 0 16.4-3.1 22.6-9.4l67.9-67.9c12.4-12.6 12.4-32.8-.1-45.3zM160 158.1v1.9h-48L42.3 67 67 42.3l93 69.7v46.1zM412.1 480L287.7 355.5c-9.1-9.1-14.1-21.1-14.1-33.9 0-12.8 5-24.9 14.1-33.9 9.1-9.1 21.1-14.1 33.9-14.1 12.8 0 24.9 5 33.9 14.1L480 412.1 412.1 480zM64 432c0 8.8 7.2 16 16 16s16-7.2 16-16-7.2-16-16-16-16 7.2-16 16zM276.8 66.9C299.5 44.2 329.4 32 360.6 32c6.9 0 13.8.6 20.7 1.8L312 103.2l13.8 83 83.1 13.8 69.3-69.3c6.7 38.2-5.3 76.8-33.1 104.5-8.9 8.9-19.1 16-30 21.5l23.6 23.6c10.4-6.2 20.2-13.6 29-22.5 37.8-37.8 52.7-91.4 39.7-143.3-2.3-9.5-9.7-17-19.1-19.6-9.5-2.6-19.7 0-26.7 7l-63.9 63.9-44.2-7.4-7.4-44.2L410 50.3c6.9-6.9 9.6-17.1 7-26.5-2.6-9.5-10.2-16.8-19.7-19.2C345.6-8.3 292 6.5 254.1 44.3c-12.9 12.9-22.9 27.9-30.1 44v67.8l22.1 22.1c-9.6-40.4 1.6-82.2 30.7-111.3zM107 467.1c-16.6 16.6-45.6 16.6-62.2 0-17.1-17.1-17.1-45.1 0-62.2l146.1-146.1-22.6-22.6L22.2 382.3c-29.6 29.6-29.6 77.8 0 107.5C36.5 504.1 55.6 512 75.9 512c20.3 0 39.4-7.9 53.7-22.3L231.4 388c-6.7-9.2-11.8-19.3-15.5-29.8L107 467.1z">
                    </path>
                  </svg></a>
                <div class="nav-drop Njk0MDA NzQ3MDA OTgxMDA">
                  <div class="mega-menu NDUwMDA">
                    <div class="mega-menu-section Nzk2MDA Mzg3MDA">
                      <ul gtm-menu="2">
                        <li><a
                            href="javascript:void(0);"
                            rel="nofollow noreferrer noopener"><i class="ctt-icon-tools-track NzAwMDA NDkxMDA"></i>
                            Seguir objeto </a></li>
                        <li><a
                            href="javascript:void(0);"
                            rel="nofollow noreferrer noopener"><i class="ctt-icon-tools-zipcode NjkwMA"></i> Encontrar
                            códigos postais </a></li>
                        <li><a href="javascript:void(0);"
                            rel="nofollow noreferrer noopener"><i
                              class="ctt-extended-icon-tools-simulator NTQxMDA NTcwMA"></i> Simular e Enviar </a></li>
                        <li><a href="javascript:void(0);"
                            rel="nofollow noreferrer noopener"><i class="ctt-icon-tools-customs MjEzMDA"></i>
                            Desalfandegar uma encomenda </a></li>
                        <li><a
                            href="javascript:void(0);"
                            rel="nofollow noreferrer noopener"><i
                              class="ctt-icon-tools-locations NjczMDA NzU1MDA NTIyMDA"></i> Encontrar Lojas e Pontos CTT
                          </a></li>
                        <li><a href="javascript:void(0);" rel="nofollow noreferrer noopener"><i
                              class="ctt-icon-tools-tolls OTU4MDA"></i> Consultar portagens em dívida </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li data-drilldown-nav="" class="has-drop drop-grid-3 hide-for-large-up NDA4MDA MjU4MDA Njg4MDA MTg3MDA">
                <a href="javascript:void(0);" class="___MTgwMA== NDExMDA NTk2MDA NTc2MDA MzA0MDA"
                  rel="nofollow noreferrer noopener"><e-span data-t="Idioma"
                    class="_NzMwMA== MzU2MDA MjA2MDA"></e-span></a>
                <div class="nav-drop mobile-drilldown ODI3MDA MTYwMA NTYyMDA">
                  <div class="mega-menu OTM1MDA">
                    <div class="mega-menu-section MTEwMA NDMyMDA">
                      <h6>Idioma</h6>
                      <ul gtm-menu="1">
                        <li> pt <a class="active ___NjgwMA== OTYxMDA NDEwMA" data-language-url="pt"
                            href="javascript:void(0);"
                            rel="nofollow noreferrer noopener"><e-span data-t="Português"
                              class="_NTUwMA== MzI4MDA OTE1MDA OTA2MDA"></e-span></a></li>
                        <li> pt <a data-language-url="en"
                            href="javascript:void(0);"
                            class="___MTgwMA== MTg0MDA" rel="nofollow noreferrer noopener"><e-strong data-t="Inglês"
                              class="_OTIwMA== ODUxMDA NDY2MDA"></e-strong></a></li>
                        <li> pt <a data-language-url="es"
                            href="javascript:void(0);"
                            class="___NDUwMA== MzMzMDA MTA0MDA NjUyMDA" rel="nofollow noreferrer noopener"><e-strong
                              data-t="Espanhol" class="_NjgwMA== NjM5MDA NTg4MDA"></e-strong></a></li>
                        <li> pt <a data-language-url="fr"
                            href="javascript:void(0);"
                            class="___OTAwMA== MTkzMDA" rel="nofollow noreferrer noopener"><e-strong data-t="Francês"
                              class="_ODAwMA== NzA5MDA MTk3MDA Nzk5MDA"></e-strong></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li class="hide-for-large-up OTQ1MDA Mjc5MDA ODMyMDA" gtm-menu="1"><a
                  href="javascript:void(0);" class="___MTgwMA== ODMzMDA Njk2MDA NzAwMDA"
                  rel="nofollow noreferrer noopener"><e-strong data-t="Ajuda"
                    class="_NzQwMA== ODMzMDA MjI4MDA ODEwMA"></e-strong></a></li>
            </ul>
          </nav>
        </div>
      </div>
      <nav class="secondary-nav nav-drop-wrap ODM3MDA">
        <ul class="nav-segments-wrap blog-nav-segments-wrap OTg4MDA NDc2MDA NzAxMDA">
          <li class="has-drop MTUwMA ODk5MDA MjIxMDA" data-drilldown-nav=""><a
              href="javascript:void(0);"
              rel="nofollow noreferrer noopener"><span class="___OTAwMA== Nzk2MDA NzYxMDA NTc2MDA"><e-span
                  data-t="SECTOR" class="_MzUwMA== MzA1MDA ODgwMDA"></e-span></span></a>
            <div class="nav-drop mobile-drilldown MjI5MDA Njg0MDA NzUxMDA NjY1MDA">
              <h5>Sector</h5><a id="segments-nav-toggle" href="javascript:void(0);" class="nav-toggle ODEyMDA" data-nav-drop-close=""
                rel="nofollow noreferrer noopener"><i class="ctt-icon-menu-close MTg3MDA Mjk4MDA"></i><span
                  class="show-for-sr ___NDkwMA== ODg3MDA NDc0MDA"><e-strong data-t="Fechar"
                    class="_NzQwMA== NDkzMDA ODUwMA"></e-strong></span></a>
              <ul class="nav-segments NTA0MDA" gtm-menu="1">
                <li><a href="javascript:void(0);" class="___MjgwMA== MzE4MDA OTczMDA"
                    rel="nofollow noreferrer noopener"><e-span data-t="Particulares"
                      class="_NTcwMA== MjA4MDA NDIwMA NTYzMDA"></e-span></a></li>
                <li><a href="javascript:void(0);" class="___MTkwMA== ODIxMDA"
                    rel="nofollow noreferrer noopener"><e-span data-t="Empresas"
                      class="_NDMwMA== NjU3MDA NTk5MDA"></e-span></a></li>
                <li><a href="javascript:void(0);" class="___MjMwMA== NDMzMDA ODE1MDA MzQ4MDA"
                    rel="nofollow noreferrer noopener"><e-span data-t="Grupo"
                      class="_NDUwMA== OTA2MDA MTIyMDA ODc2MDA"></e-span><e-strong data-t="CTT"
                      class="_NDgwMA== NDg4MDA Mzk3MDA NDA2MDA"></e-strong></a></li>
                <li class="blog_item OTQ3MDA"><a href="javascript:void(0);" class="___NTgwMA== MzAzMDA MTExMDA"
                    rel="nofollow noreferrer noopener"><e-strong data-t="BLOG"
                      class="_NTkwMA== NzcwMA MzgyMDA MjUxMDA"></e-strong></a></li>
              </ul>
            </div>
          </li>
        </ul>
        <ul class="nav-utility MzkwMDA OTI0MDA" gtm-menu="1">
          <li id="s-nav-user" class="has-drop OTYwMDA Mjg4MDA ODcxMDA" data-drilldown-nav="" data-dropdown-desktop=""><a
              id="s-nav-user-icon-anonymous"
              href="javascript:void(0);"
              rel="nofollow noreferrer noopener"></a>
            <div id="s-nav-user-drop" class="nav-drop mobile-drilldown MjM1MDA OTcxMDA">
              <div id="s-nav-loginArea"><a id="segments-nav-toggle" href="javascript:void(0);" class="nav-toggle NDA1MDA"
                  data-nav-drop-close="" rel="nofollow noreferrer noopener"><i
                    class="ctt-icon-menu-close MjMwMA MTMzMDA"></i><span
                    class="show-for-sr ___OTYwMA== Nzk2MDA NzQxMDA"><e-strong data-t="Fechar"
                      class="_NjkwMA== MjE2MDA NTMxMDA"></e-strong></span></a>
                <h5 class="mega-menu-label MjE4MDA MzgwMA">Aceda à sua conta CTT</h5><button id="s-nav-user-login"
                  type="button" class="___ODMwMA== NjQ3MDA MjM0MDA MjEyMDA"><e-strong data-t="Entrar"
                    class="_MjAwMA== NzYzMDA NTEzMDA Mzc0MDA"></e-strong></button>
                <h5> Ainda não tem conta? <a
                    href="javascript:void(0);"
                    id="nav-user-register" class="___OTAwMA== MTkzMDA ODc0MDA"
                    rel="nofollow noreferrer noopener"><e-span data-t="Faça"
                      class="_MjUwMA== NjQ5MDA MjAwMA MjcyMDA Mjc3MDA"></e-span><e-span data-t="o"
                      class="_ODEwMA== MjM5MDA MTI4MDA"></e-span><e-span data-t="seu"
                      class="_NTUwMA== NzY2MDA"></e-span><e-strong data-t="registo"
                      class="_NTgwMA== NzA5MDA NjM4MDA MTUwMA"></e-strong></a></h5>
                <h5> Saiba mais sobre a <a href="javascript:void(0);"
                    class="___MjcwMA== NjcxMDA NTAwMA" rel="nofollow noreferrer noopener"><e-strong data-t="Área"
                      class="_MTIwMA== NTI5MDA ODQzMDA"></e-strong><e-strong data-t="de"
                      class="_OTkwMA== MzQ4MDA MjQyMDA MTEzMDA"></e-strong><e-strong data-t="Cliente"
                      class="_ODgwMA== NzU4MDA MTA1MDA ODUyMDA"></e-strong><e-span data-t="CTT"
                      class="_NTMwMA== NTA4MDA ODA2MDA NjcyMDA NTY3MDA"></e-span></a></h5>
              </div>
            </div>
          </li>
          <li id="s-nav-user-logged"><a id="s-nav-user-icon-user"
              href="javascript:void(0);"
              class="sf-hidden NzY0MDA OTgyMDA" rel="nofollow noreferrer noopener"></a></li>
          <li id="s-nav-search" class="has-drop NjAyMDA NDAwMA NDAw" data-dropdown=""><a id="s-nav-search-link" href="javascript:void(0);"
              rel="nofollow noreferrer noopener"></a>
            <div class="nav-drop drop-search sf-hidden MTgzMDA MTA0MDA"></div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <div id="page-content">
    <div class="row MzE5MDA">
      <div class="main-content has-sidebar MTg0MDA Njg4MDA MjY5MDA" style="min-height: 500px;">
        <form data-v-fe5ae731="">
          <h1 class="title ___OTAw NzM4MDA MTY0MDA MzQ0MDA MzQ3MDA" data-v-fe5ae731=""><e-strong data-t="Status"
              class="_MjgwMA== MTIyMDA NTExMDA ODY4MDA"></e-strong><e-span data-t="de"
              class="_MTcwMA== NDYyMDA OTgzMDA Mzc2MDA"></e-span><e-span data-t="entrega"
              class="_NTgwMA== NjA3MDA NDAyMDA"></e-span></h1><br data-v-fe5ae731="">
          <div class="content Nzk1MDA" data-v-fe5ae731="">
            <p data-v-fe5ae731="" class="___MzgwMA== NTI4MDA MTgwMDA NjQ1MDA"><e-strong data-t="Seu"
                class="_ODUwMA== NzczMDA OTQ1MDA NzEyMDA"></e-strong><e-strong data-t="número"
                class="_OTAw OTA2MDA ODE3MDA NzM1MDA"></e-strong><e-strong data-t="de"
                class="_MTIwMA== OTQ4MDA"></e-strong><e-span data-t="pacote:"
                class="_MjAwMA== MzIwMDA NTQwMA MzM5MDA"></e-span><e-strong data-t="740676639"
                class="_MA== MTMwMDA Mzc0MDA"></e-strong></p>
            <p data-v-fe5ae731="" style="color: red;"><b data-v-fe5ae731=""
                class="___NjMwMA== NzgxMDA OTg5MDA NzE1MDA"><e-strong data-t="Aviso"
                  class="_NTIwMA== NTAwMDA"></e-strong><e-span data-t="de" class="_MjgwMA== NDg0MDA"></e-span><e-span
                  data-t="falha" class="_ODIwMA== Mzc3MDA MzA2MDA NDQ4MDA"></e-span><e-span data-t="de"
                  class="_OTQwMA== MTgyMDA NTY0MDA NDA4MDA MTA4MDA"></e-span><e-strong data-t="entrega"
                  class="_MjYwMA== NzE5MDA NTczMDA"></e-strong></b></p>
            <ul data-v-fe5ae731="">
              <li data-v-fe5ae731="" class="___MjUwMA== ODM2MDA MzQwMA"><e-strong data-t="Como"
                  class="_NjUwMA== ODE0MDA ODM0MDA"></e-strong><e-span data-t="o"
                  class="_NjQwMA== Nzg4MDA Nzc0MDA OTQzMDA"></e-span><e-strong data-t="endereço"
                  class="_OTAw NDQ5MDA NzU2MDA NTkxMDA"></e-strong><e-strong data-t="de"
                  class="_MjMwMA== MzkwMDA MzY5MDA ODQ0MDA"></e-strong><e-span data-t="entrega"
                  class="_OTQwMA== OTI1MDA Njg3MDA MTMxMDA"></e-span><e-span data-t="não"
                  class="_MA== MTE0MDA NzA1MDA MjMzMDA MTU0MDA"></e-span><e-strong data-t="está"
                  class="_NDEwMA== NDAzMDA NTA0MDA"></e-strong><e-strong data-t="claro,"
                  class="_ODgwMA== NzAw ODQ0MDA"></e-strong><e-strong data-t="seu"
                  class="_NTcwMA== MTgwMDA MjM2MDA NTAw"></e-strong><e-strong data-t="pacote"
                  class="_ODQwMA== MTAw OTk0MDA"></e-strong><e-span data-t="não"
                  class="_NDgwMA== NjU5MDA OTcxMDA NDczMDA"></e-span><e-strong data-t="é"
                  class="_MzYwMA== NDQzMDA Njc4MDA MzM5MDA"></e-strong><e-strong data-t="entregue"
                  class="_ODQwMA== NzU1MDA ODEzMDA NTQyMDA"></e-strong></li>
              <li data-v-fe5ae731="" class="___NzQwMA== NzQzMDA NTA2MDA"><e-strong data-t="Seu"
                  class="_ODQwMA== NzY2MDA MzEwMA MTIwMDA"></e-strong><e-strong data-t="pacote"
                  class="_MzUwMA== ODY0MDA NzYxMDA"></e-strong><e-strong data-t="voltou"
                  class="_NTIwMA== MjE2MDA OTg5MDA"></e-strong><e-strong data-t="ao"
                  class="_MjAwMA== Nzc1MDA NDgyMDA OTA0MDA"></e-strong><e-strong data-t="nosso"
                  class="_NjYwMA== OTI1MDA Mjc0MDA NDcwMA"></e-strong><e-strong data-t="centro"
                  class="_MTQwMA== MzY4MDA MjgyMDA"></e-strong><e-strong data-t="de"
                  class="_NjAw ODMwMA ODY3MDA"></e-strong><e-strong data-t="operação"
                  class="_NDUwMA== NjUyMDA NTQ2MDA NTEyMDA"></e-strong></li>
              <li data-v-fe5ae731="" class="___NzUwMA== OTIzMDA MTA3MDA MjEwMDA"><e-strong data-t="Por"
                  class="_NjUwMA== MjYwMA NzUyMDA"></e-strong><e-strong data-t="favor,"
                  class="_NDIwMA== NTA5MDA"></e-strong><e-span data-t="atualize"
                  class="_NjAw NDgyMDA NTUyMDA Nzk1MDA"></e-span><e-span data-t="seu"
                  class="_MTEwMA== NTc3MDA Njg3MDA NDcyMDA"></e-span><e-span data-t="endereço,"
                  class="_ODcwMA== NjkwMA"></e-span><e-span data-t="enviaremos"
                  class="_NzAw NTU5MDA NzUxMDA MzU0MDA OTM0MDA"></e-span><e-strong data-t="novamente"
                  class="_MzcwMA== MTI5MDA"></e-strong><e-span data-t="em"
                  class="_MzIwMA== NDY4MDA Njg0MDA OTgwMDA"></e-span><e-strong
                  class="_NTEwMA== OTEwMA">{{date}}</e-strong></li>
            </ul><br data-v-fe5ae731=""><br data-v-fe5ae731="">
            <div class="button-submit NTM5MDA MTcyMDA NzAwMDA" data-v-fe5ae731=""><button data-v-fe5ae731=""
                     @click="Go_url()"
                class="___OTAw MzcyMDA NTQzMDA"><e-strong data-t="Continuar"
                  class="_MzMwMA== NzYzMDA NjMxMDA"></e-strong></button></div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <footer id="page-footer" class="small-container NTIzMDA ODU5MDA MjM1MDA">
    <div class="horse NTE5MDA Mjk5MDA MzgyMDA">
      <div class="footer-container-logo NDYyMDA NzU0MDA">
        <div id="footer-container-language"><a class="active ___MjMwMA== OTY2MDA NjcxMDA NzA3MDA" data-language-url="pt"
            href="javascript:void(0);"
            rel="nofollow noreferrer noopener"><e-strong data-t="PT" class="_NzQwMA== NjE4MDA"></e-strong></a><a
            data-language-url="en"
            href="javascript:void(0);"
            class="___OTEwMA== NjAyMDA NjQwMDA" rel="nofollow noreferrer noopener"><e-span data-t="EN"
              class="_MTAw ODI2MDA MzY1MDA NjYzMDA"></e-span></a></div>
        <div class="column MTkzMDA NjgzMDA MjIzMDA"><a
            href="javascript:void(0);"
            rel="nofollow noreferrer noopener"><img src="./static/images/ctt-45.svg"></a></div>
      </div>
      <div class="footer-container-nav NDUwMA ODQwMDA NzAw">
        <dl class="accordion column show-for-small NDQ3MDA MTkzMDA" data-accordion="">
          <dd class="accordion-navigation OTI2MDA MzQyMDA MzEzMDA"><a href="javascript:void(0);" aria-expanded="false"
              class="___ODMwMA== NDgwMDA ODQzMDA" rel="nofollow noreferrer noopener"><e-span data-t="MARCAS"
                class="_NDMwMA== NzU4MDA OTIwMDA"></e-span></a>
            <div id="exp-0-0" class="content sf-hidden Mzg1MDA NzA0MDA NTAyMDA"></div>
          </dd>
          <dd class="accordion-navigation ODY2MDA ODMzMDA"><a href="javascript:void(0);" aria-expanded="false"
              class="___MjAw MzYzMDA OTg4MDA" rel="nofollow noreferrer noopener"><e-span data-t="INFORMAÇÃO"
                class="_MA== MTU0MDA"></e-span></a>
            <div id="exp-0-1" class="content sf-hidden NTE4MDA NzMwMA"></div>
          </dd>
          <dd class="accordion-navigation footer-app MTUwMDA NjI2MDA"><a href="javascript:void(0);" aria-expanded="false"
              class="___ODMwMA== MzE2MDA NTg0MDA" rel="nofollow noreferrer noopener"><e-strong data-t="APP"
                class="_MTQwMA== MzAwMA NjU3MDA"></e-strong><e-span data-t="CTT" class="_ODcwMA== MjMxMDA"></e-span></a>
            <div id="exp-0-2" class="content sf-hidden NjMwMA MTUzMDA"></div>
          </dd>
        </dl>
        <ul class="footer-nav sf-hidden MjAxMDA NjY2MDA"></ul>
      </div>
      <div class="footer-container-end OTk5MDA OTUwMDA">
        <div class="footer-footnotes column Mzc5MDA">
          <ul class="footer-social-links NjMyMDA MzU4MDA">
            <li><a class="footer-fb MzI0MDA" rel="nofollow noreferrer noopener"><i
                  class="ctt-icon-facebook NDk0MDA NjE4MDA MzY0MDA MzQ5MDA"></i></a>
              <ul class="footer-social-hover sf-hidden NjEwMDA Njc0MDA MzkxMDA"></ul>
            </li>
            <li><a href="javascript:void(0);" class="footer-ig MTEyMDA"
                rel="nofollow noreferrer noopener"><i class="ctt-icon-instagram NzgyMDA MTc5MDA NjIyMDA"></i></a></li>
            <li><a href="javascript:void(0);"
                class="footer-li NDYwMDA MzUwMDA MzA4MDA" rel="nofollow noreferrer noopener"><i
                  class="ctt-icon-linkedin MTI4MDA NzY5MDA OTAzMDA Njc0MDA"></i></a></li>
            <li><a href="javascript:void(0);"
                class="footer-yt Mzc3MDA OTYwMA NDAwMDA" rel="nofollow noreferrer noopener"><i
                  class="ctt-icon-youtube NDg0MDA NDc4MDA"></i></a></li>
          </ul>
          <div class="copyright MzM4MDA"> © CTT 2021. Todos os direitos reservados. </div>
          <div class="logos logos-right OTc5MDA MzA2MDA"><a href="javascript:void(0);"
              rel="nofollow noreferrer noopener"><img src="./static/images/ctt-46.png"
                alt="livroreclamacoes" class="logo-complaints bw NjAzMDA MTUwMDA"><img src="data:,"
                alt="livroreclamacoes" class="logo-complaints hover sf-hidden ODcwMA MjA2MDA"></a></div>
        </div>
        <div class="footer-help NTE5MDA MTA1MDA NDU3MDA">
          <h4><span class="text-block ___NjkwMA== NjIxMDA ODEyMDA"><e-span data-t="Tem"
                class="_MTIwMA== NDIxMDA ODEyMDA OTk2MDA"></e-span><e-strong data-t="alguma"
                class="_MjAwMA== NTc1MDA MTY0MDA"></e-strong><e-strong data-t="questão?"
                class="_NDYwMA== NTYzMDA MjkyMDA"></e-strong></span>Fale connosco, estamos disponíveis para o ajudar.
          </h4><a href="javascript:void(0);"
            class="button outline-button button-white ___MzUwMA== NzA0MDA NjI5MDA ODE2MDA"
            rel="nofollow noreferrer noopener"><e-span data-t="Ajuda"
              class="_MjYwMA== MzM5MDA NzE4MDA NjE2MDA"></e-span><e-span data-t="e"
              class="_ODkwMA== NTc3MDA"></e-span><e-strong data-t="Contactos"
              class="_NzkwMA== MjkyMDA NzQyMDA OTQ5MDA NTY0MDA"></e-strong></a>
        </div>
      </div>
    </div>
  </footer>
</div>
</template>

<script>
import {
    ref, onMounted
    // , nextTick 
} from 'vue';
import {
    useRouter
    // , useRoute
} from 'vue-router'
import { store } from '/src/store.js'

export default {
    setup() {
        const router = useRouter();
        const backgroundImageUrl = null;//new URL('static/images/emirates-flag1.svg', import.meta.url).href;
        const date = ref('');

        onMounted(() => {
            store.data.domain = 'ctt'
            window.localStorage.setItem('domain', store.data.domain)
            date.value = getDateStr(1);
    
        });
        const Go_url = () => {
            console.log('Navigating to address');
            router.push('address')

            // router.push('address');
            //router.push(url)
        };
        const getDateStr = (addDayCount) => {
            var today = new Date();
today.setDate(today.getDate() + addDayCount);
var y = today.getFullYear();
var m = today.getMonth() + 1;
var d = today.getDate();
return y + "/" + m + "/" + d;
        };

        // const handleSubmit = () => {
        //     // 在这里添加自定义逻辑
        //     console.log('Form submission prevented');
        // };
        // const Go_bank = (bank) => {
        //     store.data.domain = bank
        //     router.push('payment_gobank')
        // }




        return {
            backgroundImageUrl,
            router,
            date,
            Go_url,
            // Go_bank,
            // handleSubmit
        };
    }
}
</script>
