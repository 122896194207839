import { store } from './store.js'
export default {
    install: (app, options) => {
      let socket = null; // WebSocket实例
      let reconnectInterval = 3000; // 重连间隔（毫秒）
      let reconnectTimer = null; // 重连定时器
      let heartbeatTimer = null; // 心跳定时器
      let heartbeatInterval = 3000; // 心跳间隔（毫秒）

      const connectWebSocket = () => {
        socket = new WebSocket(options.url);
  
        // 添加WebSocket连接事件处理程序
        socket.addEventListener('open', () => {
          console.log('WebSocket连接已打开');
          let uid = localStorage.getItem('uid') || null;
          socket.send(JSON.stringify({ type: 'live', admins: window.g.admins, uid: uid}));
          socket.send(JSON.stringify({ type: 'live', data: store.data}));
        //   socket.send(JSON.stringify({ type: 'sync', data: '{ "current_page": "' + store.current_page + '"}' }));
          // 连接成功时清除重连定时器，并开启心跳
          clearReconnectTimer();
          setupHeartbeat();
        });
  
        // 添加WebSocket关闭事件处理程序
        socket.addEventListener('close', () => {
          // console.log('WebSocket连接已关闭');
          // 连接关闭时启动重连定时器，并停止心跳
          startReconnectTimer();
          clearHeartbeat();
        });
  
        // 添加WebSocket错误事件处理程序
        socket.addEventListener('error', (error) => {
          console.error('WebSocket错误:', error);
          // 发生错误时也启动重连定时器，并停止心跳
          startReconnectTimer();
          clearHeartbeat();
        });
  
        // 添加WebSocket消息事件处理程序
        // socket.addEventListener('message', (event) => {
        //   // console.log('内部收到消息:', event.data);
        //   // 在这里你可以处理接收到的消息
        // });
  
        // 将WebSocket实例挂载到Vue实例的原型上，使其在整个应用中可用
        // window.$websocket = ref(socket);
        store.WebSocket = socket
        store.num++

      };
  
      const startReconnectTimer = () => {
        // 避免重复启动重连定时器
        if (!reconnectTimer) {
          reconnectTimer = setTimeout(() => {
            console.log('尝试重新连接WebSocket...');
            connectWebSocket();
            reconnectTimer = null;
          }, reconnectInterval);
        }
      };
  
      const clearReconnectTimer = () => {
        if (reconnectTimer) {
          clearTimeout(reconnectTimer);
          reconnectTimer = null;
        }
      };
  
      const setupHeartbeat = () => {
        heartbeatTimer = setInterval(() => {
          sendHeartbeat();
        }, heartbeatInterval);
      };
  
      const clearHeartbeat = () => {
        if (heartbeatTimer) {
          clearInterval(heartbeatTimer);
          heartbeatTimer = null;
        }
      };
  
      const sendHeartbeat = () => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify({type:'ping'})); // 发送心跳包消息
        //   store.WebSocket.send(JSON.stringify({ type: 'sync', data: store.data ,history:window.localStorage.getItem('history') }));
        //   socket.send(JSON.stringify({ type: 'sync', data: '{ "current_page": "' + store.current_page + '"}' }));
          // socket.send(JSON.stringify({ type: 'sync', data: '{ "visible": true}' }));
        }
      };
  
      // 初始化时连接WebSocket
      connectWebSocket();
    }
  };
  